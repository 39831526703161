import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MobileHeader from "../components/MobileHeader";

export default function Duyuru() {
  let navigate = useNavigate();
  const [okul, setOkul] = useState(null)
  useEffect(() => {
    axios.get(`/hesap/${localStorage.okul_id}`).then(res=>{
      console.log(res)
      setOkul(res.data)
    })
  }, []);

  function duyuru(e) {
    e.preventDefault();
    if(window.confirm("Duyuruyu kaydedip yayınlamak istediğinize emin misiniz")){
      e.target[2].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>"
      axios.post(`/duyuru`,{
        type:"sistem",
        duyuru: e.target[0].value,
        okul: localStorage.okul_id
    }).then(res=>{
      e.target[2].innerHTML  = "<i class='fa-solid fa-bullhorn'></i> Kaydet"
      alert("Duyuru yayınlandı. Veliler sisteme giriş yaptığında duyurunuzu görecektir.")
    })
    }
  }

  function mailDuyuru(e){
    e.preventDefault();
    if(window.confirm("Tüm velilere duyuru göndermek istediğinize emin misiniz")){
      e.target[1].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>"
      axios.post(`/duyuru`,{
        type:"mail",
        duyuru: e.target[0].value,
        okul: localStorage.okul_id
    }).then(res=>{
      alert("Velilere mail gönderildi.")
      window.location.reload()
    })
    }
  }

  if (localStorage.admin == "true") {
    return (
      <div className="screen">
        <div className="page position-relative">
          <MobileHeader />
          <div className="mb-2"></div>

          <div className="row g-2">
            <div className="col-12 ">
              <div className="row g-2 map-urunler">
                <div className="col-lg-6 col-12">
                  <div className="bg-white rounded h-full p-2 shadow-sm">
                    <div className="font-medium">
                      <i className="fa-solid fa-bullhorn"></i> Sistem Duyurusu
                    </div>
                    <div className="leading-4  font-medium text-slate-400">
                      Velinin sisteme giriş yaptığı zaman göreceği duyuru
                      yazısı.
                    </div>

                    <form onSubmit={(e) => duyuru(e)} className="mt-3">
                      <textarea
                        className="input-focus bg-slate-100 p-2 rounded w-full d-block"
                        placeholder="Duyuru yazısı"
                        defaultValue={okul ? okul.data[0].duyuru : ""}
                      ></textarea>

                      <div className="flex">
                        <button
                          type="button"
                          className="bg-slate-200 text-slate-600 hover:bg-slate-300 font-medium p-2  rounded d-block mt-2 ms-auto me-2"
                        >
                          <i className="fa-solid fa-xmark"></i> İptal
                        </button>
                        <button
                          type="submit"
                          className="bg-green-200 text-green-600 hover:bg-green-300 font-medium py-2 px-4 me-auto rounded d-block mt-2 "
                        >
                          <i className="fa-solid fa-bullhorn"></i> Kaydet
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="bg-white rounded h-full p-2 shadow-sm">
                    <div className="font-medium">
                      <i className="fa-solid fa-envelope"></i> Mail Duyurusu
                    </div>
                    <div className="leading-4  font-medium text-slate-400">
                     Tüm velilere e-posta gönder
                    </div>

                    <form onSubmit={(e) => mailDuyuru(e)} className="mt-3">
                      <textarea
                        className="input-focus bg-slate-100 p-2 rounded w-full d-block"
                        placeholder="Duyuru yazısı"
                      ></textarea>

                      <div className="flex">
                      
                        <button
                          type="submit"
                          className="bg-sky-200 text-sky-600 mx-auto hover:bg-sky-300 font-medium py-2 px-5 rounded d-block mt-2 "
                        >
                          <i className="fa-solid fa-envelope"></i> Tüm Velilere Gönder
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return navigate("/");
  }
}
