import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import MobileHeader from "../components/MobileHeader";

function UrunDetay() {
  let params = useParams();
  let navigate = useNavigate();
  const [urun, setUrun] = useState(null);


  useEffect(() => {
    axios.get(`/urun/${params.id}`).then((res) => {
      console.log(res);
      setUrun(res.data);
    });

 
  }, []);

  function update(e) {
    e.preventDefault();
    console.log(e);
    e.target[9].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>"
    const data = {
      urun_isim : e.target[0].value,
      barkod: e.target[1].value,
      urun_fiyat: e.target[2].value,
      fiyat2: e.target[3].value,
      fiyat3: e.target[4].value,
      kalori: e.target[5].value,
      gluten: e.target[6].value,
      stok: e.target[7].value,
    }

    axios.post(`/updatePost/urunler/urun_id/${params.id}/?urun_duzenle=${localStorage.okul_id}|${localStorage.user_id}|${urun ? urun[0].urun_isim : ""}`,data).then(res=>{
      console.log(res);
      e.target[9].innerHTML = "Kaydedildi"

    })
  }

  function sil(e, id) {
    if (window.confirm("Bu ürünü silmek istediğiniz emin misiniz")) {
      e.target.innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";
      axios.post(`/delete/urunler/urun_id/${id}/?urun_sil=${localStorage.okul_id}|${localStorage.user_id}|${urun ? urun[0].urun_isim : ""}`).then((res) => {
        console.log(res);
        if (res.data == "ok") {
          navigate("/urunler");
        }
      });
    }
  }

  return (
    <div className="screen">
      <div className="page">
      <MobileHeader/>
        {urun ? (
          <div className="row g-2">
            <div className="col-5">
              <div className="bg-white shadow rounded p-2 kutu">
                <div className="text-center text-lg font-bold mb-2">
                  {urun[0].urun_isim}
                </div>

                <img
                  className="w-full"
                  src={
                    urun[0].urun_resim != ""
                      ? urun[0].urun_resim
                      : axios.defaults.base + "assets/img/yemek.jpg"
                  }
                />

                <a
                  className="w-100 d-none mt-1 text-center bg-green-500 py-2 font-medium rounded"
                  href={
                    axios.defaults.baseURL +
                    "resim/" +
                    params.id +
                    "/?type=urun"
                  }
                >
                  <i className="fa-solid fa-image"></i> Resim Yükle
                </a>

                <iframe
                  id="ifr"
                  src={`${
                    axios.defaults.baseURL +
                    "resim/" +
                    params.id +
                    "/?type=urun"
                  }`}
                  height="50"
                ></iframe>
                <div className="font-bold text-lg">
                  Kalan Stok:{" "}
                  <span className="text-primary">{urun[0].stok}</span>
                </div>
              </div>
            </div>

            <div className="col-7">
              <div className="bg-white shadow rounded p-2 pb-3 kutu">
                <form onSubmit={(e) => update(e)}>
                  <div className="row g-3">
                    <div className="col-12">
                      <div>
                        <div className="row g-2">
                          <div className="col-12">
                            <div>
                              <div className="font-medium lh-1">Ürün Adı*</div>
                              <input
                                id="urun_adi"
                                type="text"
                                defaultValue={urun[0].urun_isim}
                                placeholder="Ürün Adı*"
                                required
                                className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2"
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div>
                              <div className="font-medium lh-1">Barkod</div>
                              <input
                                onKeyDown={(e) => {
                                  if (e.key == "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                                type="text"
                                defaultValue={urun[0].barkod}
                                placeholder="Barkod"
                                className="rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none w-full mt-2 cursor-pointer p-2"
                              />
                            </div>
                          </div>


                          <div className="col-4">
                            <div>
                              <div className="font-medium lh-1">Fiyat*</div>
                              <input
                                min={0}
                                type="number"
                                required
                                step=".01"
                                defaultValue={urun[0].urun_fiyat}
                                placeholder="Fiyat*"
                                className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2"
                              />
                            </div>
                          </div>

                          <div className="col-4">
                            <div>
                              <div className="font-medium lh-1">2. Fiyat</div>
                              <input
                                min={0}
                                type="number"
                                step=".01"
                                defaultValue={urun[0].fiyat2}
                                placeholder="2. Fiyat"
                                className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2"
                              />
                            </div>
                          </div>

                          <div className="col-4">
                            <div>
                              <div className="font-medium lh-1">3. Fiyat</div>
                              <input
                                min={0}
                                type="number"
                                placeholder="3. Fiyat"
                                step=".01"

                                defaultValue={urun[0].fiyat3}
                                className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2"
                              />
                            </div>
                          </div>

                          <div className="col-6">
                            <div>
                              <div className="font-medium lh-1">Kalori</div>
                              <input
                                type="number"
                                min={0}
                                placeholder="Kalori"
                                defaultValue={urun[0].kalori}
                                className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2"
                              />
                            </div>
                          </div>

                          <div className="col-6">
                            <div>
                              <div className="font-medium lh-1">Gluten</div>
                              <select defaultValue={urun[0].gluten} className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2">
                                <option value="true">Glutenli</option>
                                <option value="false">Glutensiz</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-12">
                            <div>
                              <hr className="mb-2" />
                              <div className="font-medium lh-1">Stok Adeti</div>
                              <input
                                type="number"
                                min={0}
                                defaultValue={urun[0].stok}
                                placeholder="Stok Adeti"
                                className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2"
                              />
                            </div>
                          </div>

                          <div className="col-5"></div>
                          <div className="col-3">
                            <button
                              onClick={(e) => sil(e, params.id)}
                              type="button"
                              className="w-100 text-white bg-red-500 py-2 font-medium rounded"
                            >
                              <i className="fa-solid fa-trash"></i> Sil
                            </button>
                          </div>

                          <div className="col-4">
                            <button
                              type="submit"
                              className="w-100  mb-2 bg-green-500 py-2 font-medium rounded"
                            >
                              <i className="fa-solid fa-check"></i> Düzenle
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row g-2"></div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default UrunDetay;
