import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import MobileHeader from "../components/MobileHeader";
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

function Kategoriler() {
  let navigate = useNavigate()
  const [urunler, setUrunler] = useState(null);
  const [ekleOk, setEkleOk] = useState(false);


    const [data, setData] = useState(null)

  



  useEffect(() => {
    axios
      .get(`/kategoriler/${localStorage.okul_id}/`)
      .then((res) => {
        setData(res.data);
      });

   
  }, []);
  function ekle(e) {
    e.preventDefault();
  

    e.target[1].innerHTML = "<i class='fa-spin fa-spinner fa-solid'></i>";
    axios
      .post("/kategoriEkle/", {
        to_okul: localStorage.okul_id,
        isim: e.target[0].value
      })
      .then((res) => {
        axios
      .get(`/kategoriler/${localStorage.okul_id}/`)
      .then((res2) => {
        e.target[0].value = ""
        e.target[1].innerHTML = "Ekle";
        setData(res2.data);
      });
      });
  }


  return (
    <div className="screen">
      <div className="page-min in position-relative">
      <MobileHeader/>
      
      <div className="shadow-sm  flex items-center bg-white rounded-2 px-2">
              <div
                onClick={(e) => {
                    navigate("/urunler")
                }}
                className={` cursor-pointer  hover:text-black  hover:bg-slate-100 py-2 flex-fill text-center font-medium`}
              >
                Ürünler
              </div>
              <div
                onClick={(e) => {
                  navigate("/kategoriler")
                }}
                className={`cursor-pointer text-primary  border-l-2 hover:bg-slate-100 py-2 flex-fill text-center font-medium`}
              >
                Kategoriler
              </div>
            </div>

        <div className="mt-2 row g-2">
            <div className="col-12 col-lg-6">
                <div className="shadow bg-white rounded p-2">
                    <div className="font-medium">Kategori Ekle</div>
                    <form onSubmit={e=>ekle(e)}>
                      <div className="row g-2">
                        <div className="col-8">
                          <input type="text" required className="bg-slate-200 rounded p-2 w-full d-block font-medium"  placeholder="Kategori İsmi"/>
                        </div>
                        <div className="col-4">
                          <button type="submit" className="bg-green-400 hover:bg-green-500 font-medium p-2 rounded w-full d-block">Ekle</button>
                        </div>
                      </div>
                    </form>
                </div>
            </div>
  
            <div className="col-12 col-lg-6">
                <div className="shadow bg-white rounded p-2">

                    {data && data.length != 0 ? 
                        <div className="max-scroll">
                            <table className="table w-full">
                            <thead>
                                <tr>
                                    <th>Kategori</th>
                                    <th className=" text-center">İşlem</th>
                                </tr>
                            </thead>    
                            <tbody>
                               {
                                data ? data.map((val)=>{
                                    return(
                                        <tr key={val.kategori_id}>
                                        <td className="border-0 font-medium">
                                            
                                            <EditText
                                            onSave={e=>{
                                              axios.post(`/editKategori/${val.kategori_id}`,e).then(res=>{
                                                console.log(res)
                                              })
                                            }}
                                            defaultValue={val.isim}  showEditButton />

                                        </td>
                                        <td className="border-0 text-center">
                                            <button
                                              onClick={e=>{
                                                e.stopPropagation()
                                                if(window.confirm("Kategoriyi silmek istediğinize emin misiniz? Bu kategoriye bağlı olan ürünlerin kategori bilgisi silinecektir.")){
                                                    axios.post(`/silKategori/${val.kategori_id}`,{
                                                      name: val.isim
                                                    }).then(res=>{
                                                      console.log(res)
                                                      console.log(e)
                                                      console.log(e.target.nodeName)
                                                      axios
                                                      .get(`/kategoriler/${localStorage.okul_id}/`)
                                                      .then((res2) => {
                                                   
                                                        setData(res2.data);
                                                      });

                                                    })
                                                }
                                              }}
                                            ><i onClick={e=>{
                                             
                                            }} className="fa-solid fa-trash text-red-400"></i></button>
                                        </td>
                                    </tr>
                                    )
                                }) : <tr></tr>
                               }
                            </tbody>
                            </table>        
                        </div> : <div className="bg-amber-300 rounded p-2 text-center border-2 border-amber-400">Kategori listesi boş.</div>}
                    
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Kategoriler;
