export const COUNT =50;

export function iskonto(toplam, iskonto, tur = "") {
  if (tur == "") {
    return (
      parseFloat(toplam) - (Number(iskonto) * (toplam / 100)).toFixed(2)
    ).toFixed(2);
  } else {
    return parseFloat(toplam) + Number(iskonto) * (toplam / 100);
  }
}

export const aylar = [
  "Ocak",
  "Şubat",
  "Mart",
  "Nisan",
  "Mayıs",
  "Haziran",
  "Temmuz",
  "Ağustos",
  "Eylül",
  "Ekim",
  "Kasım",
  "Aralık",
];

export function tarih(stamp, saat = true) {
  return new Date().toDateString() == new Date(stamp).toDateString()
    ? `Bugün ${
        saat
          ? ", " +
            (new Date(stamp).getHours().toString().length == 1
              ? `0${new Date(stamp).getHours()}`
              : new Date(stamp).getHours()) +
            ":" +
            (new Date(stamp).getMinutes().toString().length == 1
              ? "0" + new Date(stamp).getMinutes().toString()
              : new Date(stamp).getMinutes())
          : ""
      }`
    : `${new Date(stamp).getDate()} ${aylar[new Date(stamp).getMonth()]} ${
        saat
          ? ", " +
            (new Date(stamp).getHours().toString().length == 1
              ? `0${new Date(stamp).getHours()}`
              : new Date(stamp).getHours()) +
            ":" +
            (new Date(stamp).getMinutes().toString().length == 1
              ? "0" + new Date(stamp).getMinutes().toString()
              : new Date(stamp).getMinutes().toString())
          : ""
      }`;
}

export const sehirler = [
  "Adana",
  "Adıyaman",
  "Afyon",
  "Ağrı",
  "Amasya",
  "Ankara",
  "Antalya",
  "Artvin",
  "Aydın",
  "Balıkesir",
  "Bilecik",
  "Bingöl",
  "Bitlis",
  "Bolu",
  "Burdur",
  "Bursa",
  "Çanakkale",
  "Çankırı",
  "Çorum",
  "Denizli",
  "Diyarbakır",
  "Edirne",
  "Elazığ",
  "Erzincan",
  "Erzurum",
  "Eskişehir",
  "Gaziantep",
  "Giresun",
  "Gümüşhane",
  "Hakkari",
  "Hatay",
  "Isparta",
  "İçel (Mersin)",
  "İstanbul",
  "İzmir",
  "Kars",
  "Kastamonu",
  "Kayseri",
  "Kırklareli",
  "Kırşehir",
  "Kocaeli",
  "Konya",
  "Kütahya",
  "Malatya",
  "Manisa",
  "Kahramanmaraş",
  "Mardin",
  "Muğla",
  "Muş",
  "Nevşehir",
  "Niğde",
  "Ordu",
  "Rize",
  "Sakarya",
  "Samsun",
  "Siirt",
  "Sinop",
  "Sivas",
  "Tekirdağ",
  "Tokat",
  "Trabzon",
  "Tunceli",
  "Şanlıurfa",
  "Uşak",
  "Van",
  "Yozgat",
  "Zonguldak",
  "Aksaray",
  "Bayburt",
  "Karaman",
  "Kırıkkale",
  "Batman",
  "Şırnak",
  "Bartın",
  "Ardahan",
  "Iğdır",
  "Yalova",
  "Karabük",
  "Kilis",
  "Osmaniye",
  "Düzce",
];
