import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import { tarih } from "../../iskonto";
import axios from "axios";
import { useRef } from "react";
import MobileHeader from "../../components/MobileHeader";


function OgrenciRapor() {
  let params = useParams();
  let navigate = useNavigate();

  const [filtered, setFiltered] = useState(false);
  const [data, setData] = useState(null);
  const start = useRef();
  const bitis = useRef();

  useEffect(() => {
    axios.get(`/ogrenciRapor/${params.id}/${localStorage.admin == "true" ? localStorage.okul_id : localStorage.user_id}/?admin=${localStorage.admin}`).then((res) => {
      setData(res.data);
      console.log(res);
    });
  }, []);

  function tarihSearch(e) {
    e.preventDefault();

    if (start.current.value == "") {
      alert("Başlangıç tarihi giriniz");
      return;
    }

    if (bitis.current.value == "") {
      alert("Bitiş tarihi giriniz");
      return;
    }

    setData(null);
    setFiltered(true);
    axios
      .get(
        `/ogrenciRapor/${params.id}/${localStorage.admin == "true" ? localStorage.okul_id : localStorage.user_id}/?start=${start.current.value}&bitis=${bitis.current.value}&admin=${localStorage.admin}`
      )
      .then((res) => {
        setData(res.data);
        console.log(res);
      });
  }

  return (
    <div className="screen">
      <div className="page">
      <MobileHeader/>
        <div className="mt-2 ">
          <div className="row g-2">
            <div className="col-12">
              <div className="bg-white rounded p-2 shadow kutu">
                <div className="font-medium text-lg text-center ">
                  Öğrenci Raporu
                </div>
              </div>
            </div>
            <div className="col-5">
              <div>
                <div className="bg-white rounded p-2 flex justify-center items-center flex-column shadow kutu">
                  {data ? (
                    <>
                      <div className="flex items-center justify-center flex-column">
                        <img
                          loading="lazy"
                          src={
                            data.ogrenci.resim != ""
                              ? data.ogrenci.resim
                              : axios.defaults.base + "assets/img/profil.jpg"
                          }
                          className="w-36 h-36 object-cover rounded-full"
                          alt=""
                        />

                        <div className="font-medium text-lg mt-1 lh-1">
                          {data.ogrenci.isim}
                        </div>
                        <div className="font-medium text-slate-400">
                          {data.ogrenci.tc}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                    <div className="flex items-center justify-center flex-column">
                    <div className="h-36-important w-36 skeleton rounded-full mb-2"></div>

                      <div className="font-medium text-lg mt-1 lh-1">
                       <div className="skeleton px-5"></div>
                      </div>
                      <div className="font-medium text-slate-400">
                      <div className="skeleton px-5"></div>

                      </div>
                    </div>
                  </>
                  )}
                </div>

                <button
                  className="p-2 hover:bg-green-400 mt-2 rounded bg-green-500 border-2 border-zinc-50 font-medium text-center w-full d-block"
                  onClick={(e) => navigate("/ogrenciler?sec")}
                >
                  Başka bir öğrenci seç
                </button>
              </div>
            </div>

            <div className="col-7">
              <div className="mb-2">
                <div className="flex items-center px-2 justify-between rounded bg-white shadow-sm">
                  <div className="font-medium">Tarih Aralığı</div>
                  <form onSubmit={(e) => tarihSearch(e)}>
                    <input
                      ref={start}
                      type="date"
                      className="bg-slate-100 p-2 border-l-5"
                      max={new Date().toLocaleDateString("en-CA")}
                    />
                    <input
                      max={new Date().toLocaleDateString("en-CA")}
                      defaultValue={new Date().toLocaleDateString("en-CA")}
                      ref={bitis}
                      type="date"
                      className="bg-slate-100 p-2 ms-2"
                    />
                    <button
                      type="submit"
                      className="bg-primary ms-2 text-xs p-2 rounded-full "
                    >
                      <i className="fa-solid fa-magnifying-glass "></i>{" "}
                    </button>
                  </form>
                </div>
                {filtered ? (
                  <div className=" p-2 text-center border-2 border-red-400 rounded bg-red-50 shadow-sm mt-2 flex items-center font-medium justify-center">
                    <span className="me-2 inline-flex items-center rounded-full bg-red-400 hover:bg-green-500 font-medium lh-1 p-2"></span>
                    Tarihe göre filtrelenmiş veriler gösteriliyor
                  </div>
                ) : (
                  ""
                )}{" "}
              </div>

              {data ? (
                <>
                  <div className="row mb-2 g-2">
                    <div className="col-4">
                      <div className="items-center flex-column justify-center flex bg-white h-full rounded px-2 py-3 shadow kutu">
                        <div className="font-medium text-lg lh-1 text-green-500 text-center">
                          {data.toplam ? data.toplam : "0"}
                        </div>
                        <div className="font-medium text-sm text-center lh-1">
                          Toplam Harcama
                        </div>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="items-center flex-column justify-center flex bg-white h-full rounded px-2 py-3 shadow kutu">
                        <div className="font-medium text-lg lh-1 text-green-500 text-center lh-1">
                          {data.encok.urun ? data.encok.urun : "-"}
                        </div>
                        <div className="font-medium text-sm text-center lh-1">
                          En Çok Aln. Ürün
                        </div>
                        <div className="font-medium text-sm text-center">
                          {data.encok.toplam
                            ? `(${data.encok.toplam} kez)`
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="items-center flex-column justify-center flex bg-white h-full rounded px-2 py-3 shadow kutu">
                        <div className="font-medium text-lg lh-1 text-green-500 text-center lh-1">
                          {data.ensontarih ? tarih(data.ensontarih) : "-"}
                        </div>
                        <div className="font-medium text-sm text-center lh-1">
                          Son Sipariş
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded p-2 shadow kutu">
                    <div className="font-medium text-center mb-1">
                      Sipariş Geçmişi ({data.data.length})
                    </div>

                    <div className="max-scroll">
                      <table className="table">
                        <thead>
                          <tr>
                            <td className="font-bold">Ürün</td>
                            <td className="font-bold">Fiyat</td>
                            <td className="font-bold">Adet</td>
                            <td className="font-bold">Ödeme</td>
                            <td className="font-bold">Tarih</td>
                          </tr>
                        </thead>
                        <tbody>
                          {data.data.map((val, i) => {
                            return (
                              <tr key={i} className="hover:bg-slate-100">
                                <td className="font-medium border-0">
                                  {val.urun}
                                </td>
                                <td className="fiyat-text text-green-500 font-medium border-0">
                                  {val.fiyat} ₺
                                </td>
                                <td className="border-0 font-medium">
                                  {val.adet}
                                </td>
                                <td className="border-0 text-sm">
                                  {val.odeme}
                                </td>

                                <td className="border-0 text-sm">
                                  {tarih(val.tarih)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      {data.data.length == 0 ? (
                        <div className="p-2 bg-amber-500/50 font-medium rounded ">
                          Veri bulunamadı
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                <div className="row mb-2 g-2">
                  <div className="col-4">
                    <div className="items-center flex-column justify-center flex bg-white h-full rounded px-2 py-3 shadow kutu">
                      <div className="font-medium text-lg lh-1 text-green-500 text-center">
                      <div className="skeleton px-5 h-1-important"></div>
                      </div>
                      <div className="font-medium text-sm text-center lh-1">
                      <div className="skeleton px-5 h-1-important"></div>

                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="items-center flex-column justify-center flex bg-white h-full rounded px-2 py-3 shadow kutu">
                      <div className="font-medium text-lg lh-1 text-green-500 text-center lh-1">
                      <div className="skeleton px-5 h-1-important"></div>

                      </div>
                      <div className="font-medium text-sm text-center lh-1">
                      <div className="skeleton px-5 h-1-important"></div>

                      </div>
                     
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="items-center flex-column justify-center flex bg-white h-full rounded px-2 py-3 shadow kutu">
                      <div className="font-medium text-lg lh-1 text-green-500 text-center lh-1">
                      <div className="skeleton px-5 h-1-important"></div>

                      </div>
                      <div className="font-medium text-sm text-center lh-1">
                      <div className="skeleton px-5 h-1-important"></div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded p-2 shadow kutu">
                  <div className="font-medium text-center mb-1">
                  <div className="skeleton"></div>
                  </div>

                  <div className="max-scroll">
                    <table className="table">
                      <thead>
                        <tr>
                          <td className="font-bold"> <div className="skeleton mx-auto d-block px-5 h-1-important"></div></td>
                          <td className="font-bold"> <div className="skeleton mx-auto d-block px-3 h-1-important"></div></td>
                          <td className="font-bold"> <div className="skeleton mx-auto d-block px-3 h-1-important"></div></td>
                          <td className="font-bold"> <div className="skeleton mx-auto d-block px-3 h-1-important"></div></td>
                          <td className="font-bold"> <div className="skeleton mx-auto d-block px-3 h-1-important"></div></td>
                        </tr>
                      </thead>
                      <tbody>
                       
                            <tr className="hover:bg-slate-100">
                              <td className="font-medium border-0">
                              <div className="skeleton mx-auto d-block px-5 h-1-important"></div>

                              </td>
                              <td className="fiyat-text text-green-500 font-medium border-0">
                              <div className="skeleton mx-auto d-block px-2 h-1-important"></div>

                              </td>
                              <td className="border-0 font-medium">
                              <div className="skeleton mx-auto d-block px-2 h-1-important"></div>

                              </td>
                              <td className="border-0 text-sm">
                              <div className="skeleton px-2 mx-auto d-block h-1-important"></div>

                              </td>

                              <td className="border-0 text-sm">
                              <div className="skeleton px-3 mx-auto d-block h-1-important"></div>

                              </td>
                              </tr>

                              <tr className="hover:bg-slate-100">
                              <td className="font-medium border-0">
                              <div className="skeleton mx-auto d-block px-5 h-1-important"></div>

                              </td>
                              <td className="fiyat-text text-green-500 font-medium border-0">
                              <div className="skeleton mx-auto d-block px-2 h-1-important"></div>

                              </td>
                              <td className="border-0 font-medium">
                              <div className="skeleton mx-auto d-block px-2 h-1-important"></div>

                              </td>
                              <td className="border-0 text-sm">
                              <div className="skeleton px-2 mx-auto d-block h-1-important"></div>

                              </td>

                              <td className="border-0 text-sm">
                              <div className="skeleton px-3 mx-auto d-block h-1-important"></div>

                              </td>
                              </tr>

                              <tr className="hover:bg-slate-100">
                              <td className="font-medium border-0">
                              <div className="skeleton mx-auto d-block px-5 h-1-important"></div>

                              </td>
                              <td className="fiyat-text text-green-500 font-medium border-0">
                              <div className="skeleton mx-auto d-block px-2 h-1-important"></div>

                              </td>
                              <td className="border-0 font-medium">
                              <div className="skeleton mx-auto d-block px-2 h-1-important"></div>

                              </td>
                              <td className="border-0 text-sm">
                              <div className="skeleton px-2 mx-auto d-block h-1-important"></div>

                              </td>

                              <td className="border-0 text-sm">
                              <div className="skeleton px-3 mx-auto d-block h-1-important"></div>

                              </td>
                              </tr>
                     
                      </tbody>
                    </table>

                
                  </div>
                </div>
              </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OgrenciRapor;
