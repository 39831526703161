import React from 'react'
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { aylar, tarih } from "../iskonto";
import axios from 'axios';
import MobileHeader from "../components/MobileHeader";

function Siparisler() {

    console.log(window.location);
  
    const start = useRef();
    const bitis = useRef();
    const aralikTitle = useRef();
  
  
    const [siparisler, setSiparisler] = useState(null);
  
    useEffect(() => {
      axios.get(`/okulSiparisler/${localStorage.admin == "true" ? localStorage.okul_id : localStorage.user_id}/?admin=${localStorage.admin}`).then((res) => {
        setSiparisler(res.data);
      });
  
    }, []);
  
    function tarihSearch(e) {
      e.preventDefault();
      console.log({
        start: start.current.value,
        bitis: bitis.current.value,
      });
  
      if (start.current.value == "") {
        alert("Başlangıç tarihi giriniz");
        return;
      }
  
      if (bitis.current.value == "") {
        alert("Bitiş tarihi giriniz");
        return;
      }
  
      aralikTitle.current.innerText = "Belirtilen Tarih";
      setSiparisler(null);
      axios
        .get(
          `/okulSiparisler/${localStorage.admin == "true" ? localStorage.okul_id : localStorage.user_id}?start=${start.current.value}&bitis=${bitis.current.value}&admin=${localStorage.admin}`
        )
        .then((res) => {
          console.log(res);
  
          setSiparisler(res.data);
        });
    }
  return (
    <div className='screen'>
        <div className='page'>
        <MobileHeader/>
    <div className="mb-2 shadow-sm  flex items-center justify-between bg-white rounded-2 px-2">
      <div className="font-medium" ref={aralikTitle}>
        Tüm zamanlar
      </div>
      <form onSubmit={(e) => tarihSearch(e)}>
        <input
          ref={start}
          type="date"
          max={new Date().toLocaleDateString("en-CA")}
          className="bg-slate-100 p-2 border-l-5"
        />
        <input ref={bitis} type="date"
           max={new Date().toLocaleDateString("en-CA")}
        className="bg-slate-100 p-2 " />
        <button
          type="submit"
          className="bg-primary text-xs p-2 rounded-full "
        >
          <i className="fa-solid fa-magnifying-glass "></i>{" "}
        </button>
      </form>
    </div>

    <div className="mt-2 bg-white rounded p-2 shadow kutu">
      <div className="text-lg font-medium">Satılan Ürünler</div>

      <div className="max-scroll">
        {siparisler ? (
          <table className="table">
            <thead>
              <tr>
                <td className="font-bold">Ürün</td>
                <td className="font-bold">Fiyat</td>
                <td className="font-bold">Adet</td>
                <td className="font-bold">Kişi</td>
                <td className="font-bold">Ödeme</td>
                <td className="font-bold">Tarih</td>
              </tr>
            </thead>
            <tbody id="tablo">
              {siparisler.map((val, i) => {
                return (
                  <tr
                    key={i}
                    className="cursor-pointer hover:bg-slate-100"
                  >
                    <td className="font-medium border-0">{val.urun}</td>
                    <td className="fiyat-text text-green-500 font-medium border-0">
                      {val.fiyat} ₺
                    </td>
                    <td className="border-0 font-medium">{val.adet}</td>
                    <td className="border-0 text-sm">{val.isim}</td>
                    <td className="border-0 text-sm">{val.odeme}</td>

                    <td className="border-0 text-sm">
                     {tarih(val.tarih)}
                    </td>

                  
                  </tr>
                );
              })}
            </tbody>

            
          </table>
        ) : (
          ""
        )}

        {siparisler && siparisler.length == 0 ? (
          <div className="p-2 text-center bg-amber-500/50 font-medium rounded">
            Veri bulunamadı
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
    </div>
  </div>
  )
}

export default Siparisler

