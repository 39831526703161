import axios from "axios";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { sehirler } from "../iskonto";
import MobileHeader from "../components/MobileHeader";

function Tedarikciler() {
  const [tedarikciler, setTedarikciler] = useState(null);
  const ekleForm = useRef();
  let navigate = useNavigate();
  useEffect(() => {
    console.log(window.location);

    axios.get(`/getTedarikciler/${localStorage.okul_id}`).then((res) => {
      setTedarikciler(res.data);
    });
  }, []);

  function add(e) {
    e.preventDefault();
    e.target[9].innerHTML = "<i class='fa-spin fa-spinner fa-solid'></i>";
    e.target[9].setAttribute("disabled", "true");
    setTedarikciler(null);
    axios
      .post(`/tedarikciEkle`, {
        to_okul: localStorage.okul_id,
        isim: e.target[0].value,
        yetkili_kisi: e.target[1].value,
        telefon: e.target[2].value,
        adres: e.target[3].value,
        il: e.target[4].value,
        aciklama: e.target[5].value,
        vergi_dairesi: e.target[6].value,
        vergi_no: e.target[7].value,
        user_id: localStorage.user_id
      })
      .then((res) => {
        e.target[9].innerHTML = "Kaydet";
        axios.get(`/getTedarikciler/${localStorage.okul_id}`).then((res) => {
          setTedarikciler(res.data);
        });
        e.target[9].removeAttribute("disabled");
        ekleForm.current.classList.remove("flex");
        ekleForm.current.style.display = "none ";
        e.target.reset();
      });
  }

  return (
    <div className="screen">
      <div className="page position-relative">
      <MobileHeader/>
        <div
          ref={ekleForm}
          className="position-absolute min-h-full d-none justify-center items-center w-full backdrop-blur-sm bg-slate-50/1 rounded"
        >
          <div className="bg-white rounded mt-5 p-2 border border-zinc-200 shadow-sm w-2/3">
            <div className="font-medium text-lg">Tedarikçi Ekle</div>
            <form onSubmit={(e) => add(e)}>
              <div className="row g-3">
                <div className="col-6">
                  <div className="row g-2">
                    <div className="col-12">
                      <div>
                        <div className="font-medium text-sm">İsim</div>
                        <input
                          required
                          type="text"
                          placeholder="İsim"
                          className="bg-slate-100 font-medium  w-full d-block p-1 rounded border"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div>
                        <div className="font-medium text-sm">Yetkili Kişi</div>
                        <input
                          type="text"
                          placeholder="Yetkili Kişi"
                          className="bg-slate-100 font-medium  w-full d-block p-1 rounded border"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div>
                        <div className="font-medium text-sm">Telefon</div>
                        <input
                          type="text"
                          placeholder="Telefon"
                          className="bg-slate-100 font-medium  w-full d-block p-1 rounded border"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div>
                        <div className="font-medium text-sm">Adres</div>
                        <input
                          type="text"
                          placeholder="Adres"
                          className="bg-slate-100 font-medium  w-full d-block p-1 rounded border"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row g-2">
                    <div className="col-12">
                      <div>
                        <div className="font-medium text-sm">İl</div>
                        <select className="bg-slate-100 font-medium  w-full d-block p-1 rounded border">
                          <option value="">İl Seçiniz</option>
                          {sehirler.map((val, key) => {
                            return (
                              <option value={val} key={key}>
                                {val}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-12">
                      <div>
                        <div className="font-medium text-sm">Açıklama</div>
                        <input
                          type="text"
                          placeholder="Açıklama"
                          className="bg-slate-100 font-medium  w-full d-block p-1 rounded border"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div>
                        <div className="font-medium text-sm">Vergi Dairesi</div>
                        <input
                          type="text"
                          placeholder="Vergi Dairesi"
                          className="bg-slate-100 font-medium  w-full d-block p-1 rounded border"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div>
                        <div className="font-medium text-sm">Vergi No</div>
                        <input
                          type="text"
                          placeholder="Vergi No"
                          className="bg-slate-100 font-medium  w-full d-block p-1 rounded border"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="flex">
                        <button
                          onClick={(e) => {
                            ekleForm.current.classList.remove("flex");
                            ekleForm.current.style.display = "none ";
                          }}
                          type="button"
                          className="mr-2 flex-fill bg-slate-100 hover:bg-slate-200 rounded p-2 font-medium"
                        >
                          İptal
                        </button>
                        <button
                          type="submit"
                          className="mr-2 btn-disabled flex-fill bg-green-500 hover:bg-green-600 rounded p-2 font-medium"
                        >
                          Kaydet
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {window.location.search == "?stok" ? (
          <div className="bg-white rounded shadow p-2 mb-2 ">
            <div className="font-medium text-lg">Stok Girişi</div>
            <div className="font-medium">
              Stok girmek istediğiniz tedarikçiyi seçiniz.
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="bg-white rounded shadow p-2">
          <div className="flex items-center justify-between">
            <div className="font-medium text-lg">
              {window.location.search == "?stok"
                ? "Tedarikçi Seçiniz"
                : "Tedarikçiler"}
            </div>

            <div className="flex">
              <div className="bg-slate-200 flex items-center rounded p-1 mr-2">
                <i className="fa-solid fa-magnifying-glass text-sm "></i>
                <input
                  type="text"
                  onChange={(e) => {
                    var input, filter, table, tr, td, i, txtValue;
                    input = e.target;
                    filter = input.value.toUpperCase();
                    table = document.querySelector(".table tbody");
                    tr = table.querySelectorAll("tr");

                    console.log(tr);

                    for (i = 0; i < tr.length; i++) {
                      td = tr[i];
                      if (td) {
                        txtValue = td.textContent || td.innerText;
                        if (txtValue.toUpperCase().indexOf(filter) > -1) {
                          tr[i].style.display = "";
                        } else {
                          tr[i].style.display = "none";
                        }
                      }
                    }
                  }}
                  placeholder="Ara"
                  className="pl-3 font-medium bg-transparent text-sm outline-none"
                />
              </div>
              <button
                onClick={(e) => {
                  ekleForm.current.classList.remove("d-none");
                  ekleForm.current.classList.add("flex");
                  ekleForm.current.style.display = "flex";
                }}
                className="font-medium bg-green-500 hover:bg-green-600 p-2 rounded"
              >
                
                <i className="fa-solid fa-plus"></i> Tedarikçi Ekle
              </button>
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <td className="font-bold">İsim</td>
                <td className="font-bold">Yetkili</td>
                <td className="font-bold">Telefon</td>
                <td className="font-bold">İl</td>
                <td className="font-bold">Adres</td>
                <td className="font-bold">Vergi D.</td>
                <td className="font-bold">Vergi No</td>
                <td className="font-bold text-end">Açıklama</td>
                {window.location.search == "?stok" ? (
                  <td className="font-bold">Seç</td>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {tedarikciler &&
                tedarikciler.map((val, i) => {
                  return (
                    <tr key={i}>
                      <td className="border-0 font-medium lh-1">{val.isim}</td>
                      <td className="border-0 lh-1 font-medium text-green-400">
                        {val.yetkili_kisi}
                      </td>
                      <td className="border-0 lh-1 font-medium">
                        {val.telefon}
                      </td>
                      <td className="border-0 lh-1">{val.il}</td>
                      <td className="border-0 lh-1 text-sm text-slate-400">
                        {val.adres}
                      </td>
                      <td className="border-0 lh-1  text-sm text-slate-400">
                        {val.vergi_dairesi}
                      </td>
                      <td className="border-0 lh-1  text-sm text-slate-400">
                        {val.vergi_no}
                      </td>
                      <td className="border-0 lh-1  text-sm text-slate-400 text-end">
                        {val.aciklama ? val.aciklama : "-"}
                      </td>
                      {window.location.search == "?stok" ? (
                        <td className="">
                          <button
                            onClick={(e) =>
                              navigate("/stok-gir/" + val.tedarikci_id)
                            }
                            className="flex items-center p-1 rounded bg-green-400 hover:bg-green-500 font-medium lh-1 p-2"
                          >
                            <i className="mr-1 fa-regular fa-circle-dot"></i>{" "}
                            Seç
                          </button>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Tedarikciler;
