import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import {  tarih } from "../iskonto";
import MobileHeader from "../components/MobileHeader";

export default function StokDetay() {

  let params = useParams()

  const [stok, setStok] = useState(null);

  useEffect(() => {

    axios.get(`/get_stok_belge_urunler/${params.id}`).then((res) => {
      setStok(res.data);
    });
    
  }, []);


  return (
    <div className="screen">
      <div className="page">
      <MobileHeader/>

    


    

        <div className="mt-2 bg-white rounded p-2 shadow kutu">
            {stok ? 
            <div className=" mb-3">
                <div className="font-medium text-lg text-blue-400 text-center mb-3">Stok Giriş Belgesi</div>

                    <div className="flex">
                <div className="w-50">
                    <div className="font-medium">Belge No: <span className="text-slate-400">{stok.belge[0].belge_no}</span></div>
                    <div className="font-medium">Belge Tarihi: <span className="text-slate-400">{tarih(stok.belge[0].tarih,false)}</span></div>
                    <div className="font-medium">Giriş Tarihi: <span className="text-slate-400">{tarih(stok.belge[0].giris_tarihi,false)}</span></div>
                    {stok.belge[0].aciklama ?
                    <div className="font-medium  lh-1 ">Açıklama: <span className="text-slate-400">{stok.belge[0].aciklama}</span></div> : ""  }
                </div>

                <div className="w-50">
                    <div className="font-medium text-end">Tedarikçi</div>
                    <div className="font-medium text-end">İsim: <span className="text-slate-400">{stok.tedarikci[0].isim}</span></div>
                    <div className="font-medium text-end">Yetkili Kişi: <span className="text-slate-400">{stok.tedarikci[0].yetkili_kisi}</span></div>
                    <div className="font-medium text-end">Telefon: <span className="text-slate-400">{stok.tedarikci[0].telefon}</span></div>
                    <div className="font-medium text-end">Adres: <span className="text-slate-400">{stok.tedarikci[0].adres}</span></div>
                </div>

                </div>
            </div>

            : "" }
          <div className="max-scroll">
            {stok ? (
               <table className="table">
                <thead>
                  <tr>
                    <td className="font-bold">Ürün</td>
                    <td className="font-bold">Birim Fiyat</td>
                    <td className="font-bold">Miktar</td>
                    <td className="font-bold">KDV</td>

                    <td className="font-bold">İskonto</td>
                    <td className="font-bold">KDV B.F</td>
                    <td className="font-bold">Tutar</td>
                  </tr>
                </thead>
                <tbody>
                  {stok
                    ? stok.urunler.map((val, i) => {
                        return (
                          <tr key={i} className="hover:bg-slate-100" >
                            <td className="border-0 font-medium">  {val.urun_isim}</td>
                            <td className="border-0 italic text-slate-400 text-green-500 font-medium">
                              {val.birim_fiyat}
                            </td>
                            <td className="border-0 font-medium ">
                              {val.miktar}
                            </td>
                            <td className="border-0  font-medium text-slate-400">
                              %{val.kdv}
                            </td>
                            <td className="border-0 font-medium ">
                              {val.iskonto}
                            </td>

                            <td className="border-0 font-medium  " >
                              {val.kdv_birim_fiyat}
                            </td>

                            <td className="border-0 font-medium  text-green-500">
                              {val.tutar}
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            ) : (
              ""
            )}
          </div>

          {
            stok ? <div className="flex">

                    <div className="ms-auto w-56 border-t-2 border-gray-700">
                        <div className="flex items-center justify-between">
                            <div className="font-medium">Genel İskonto:</div>
                            <div className="font-medium text-gray-700">{stok.belge[0].genel_iskonto}</div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="font-medium">Toplam Tutar:</div>
                            <div className="font-medium text-green-700">{stok.belge[0].toplam}</div>
                        </div>
                    </div>

            </div> : <div></div>
          }
        </div>
       

   
      </div>
    </div>
  );
}

