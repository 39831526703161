import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

axios.defaults.baseURL =
  window.location.hostname == "localhost"
    ? "http://192.168.1.115/api/api/"
    : "https://okul-backend.orkestrasefi.com/api/";
axios.defaults.base =
  window.location.hostname == "localhost"
    ? "http://192.168.1.115/api/"
    : "https://okul-backend.orkestrasefi.com/";
console.log(window.location.hostname == "localhost");

window.addEventListener('online', () => { console.log('online!'); });
window.addEventListener('offline', () => { console.log('offline!'); });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

serviceWorkerRegistration.register();

/*
const s = document.querySelectorAll("#UyeTaban")
var html = ""

s.forEach(function(val){
  console.log(val.querySelector("div:nth-child(4) > a").innerHTML)
  html+= `<tr>
    <td>${val.querySelector("h3").innerText}</td>
    <td>${val.querySelector("div:nth-child(4) > a").innerHTML}</td>
    <td>${val.querySelector("div:nth-child(6) > a").innerHTML}</td>
    <td>${val.querySelector("div:nth-child(8) > a").innerHTML}</td>
    
  </tr>`
})

document.write(`<table>${html}</table>`)
*/