import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import MobileHeader from "../components/MobileHeader";

function Urunler() {
  let navigate = useNavigate();
  const [urunler, setUrunler] = useState(null);
  const [ekleOk, setEkleOk] = useState(false);
  const [viewType, setViewType] = useState(
    localStorage.kantin_view_type ? localStorage.kantin_view_type : "box"
  );

  const [ids, setIds] = useState([]);

  const [tab, setTab] = useState("urunler");
  const [barcode, setBarcode] = useState("");
  const [kategoriler, setKategoriler] = useState(null);
  const [kategori, setKategori] = useState("");
  const [showImages, setShowImages] = useState(true);
  const [uyari, setUyari] = useState(false);

  const [order, setOrder] = useState("urun_isim ASC");

  useEffect(() => {
    document.getElementById("ara").value = "";
    localStorage.setItem("kantin_view_type", viewType);
  }, [viewType]);

  function getUrunler(_order) {
    setUrunler(null);
    axios
      .get(`/urunler/${localStorage.okul_id}/?order=${_order}`)
      .then((res) => {
        setUrunler(res.data);
      });
  }

  useEffect(() => {
    axios
      .get(`/urunler/${localStorage.okul_id}/?order=${order}`)
      .then((res) => {
        console.log(urunler);
        setUrunler(res.data);
      });

    axios.get(`/kategoriler/${localStorage.okul_id}`).then((res) => {
      const _a = [];
      res.data.forEach((val) => {
        _a.push({
          value: val.isim,
          label: val.isim,
        });
      });
      setKategoriler(_a);
    });
  }, []);
  function ekle(e) {
    e.preventDefault();

    e.target[1].innerHTML = "<i class='fa-spin fa-spinner fa-solid'></i>";
    axios
      .post("/urunEkle/", {
        to_okul: localStorage.okul_id,
        barkod: e.target[0].value,
        urun_isim: e.target[3].value,
        kategori: kategori,
        urun_fiyat: e.target[5].value,
        fiyat2: e.target[6].value,
        fiyat3: e.target[7].value,
        kalori: e.target[8].value,
        gluten: e.target[9].value,
        stok: e.target[10].value,
        per: localStorage.user_id,
      })
      .then((res) => {
        console.log(res);
        e.target[1].innerHTML = "Ekle";
        e.target[10].value = "";
        e.target[9].value = "";
        e.target[8].value = "";
        e.target[7].value = "";
        e.target[6].value = "";
        e.target[5].value = "";
        e.target[3].value = "";
        e.target[0].value = "";
        setBarcode("");
        setKategori("");
        setEkleOk(true);

        axios
          .get(`/urunler/${localStorage.okul_id}/?order=${order}`)
          .then((res) => {
            setUrunler(res.data);
          });

        axios.get(`/getKategoriler/${localStorage.okul_id}`).then((res) => {
          const _a = [];
          res.data.forEach((val) => {
            _a.push({
              value: val.kategori,
              label: val.kategori,
            });
          });
          setKategoriler(_a);
        });
      });
  }

  function sil(e, id, isim) {
    if (window.confirm("Bu ürünü silmek istediğiniz emin misiniz")) {
      e.target.innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";
      axios
        .post(
          `/delete/urunler/urun_id/${id}/?urun_sil=${localStorage.okul_id}|${localStorage.user_id}|${isim}`
        )
        .then((res) => {
          console.log(res);
          if (res.data == "ok") {
            e.target.parentElement.parentElement.parentElement.remove();
          }
        });
    }
  }

  function topluSil(e) {}
  return (
    <div className="screen">
      <div className="page position-relative">
        <MobileHeader />
        {tab == "ekle" ? (
          <div className="in position-absolute z-50 flex pt-5 justify-center bg-zinc-300/25 top-0 left-0 w-full h-full">
            <div className="w-full self-start max-w-[550px] bg-white rounded  ekle-pencere">
              <div className="flex items-center p-2 bg-slate-100 rounded-top mb-2">
                <i
                  onClick={(e) => {
                    if (
                      !window.confirm(
                        "Değişikleri iptal etmek istediğinize emin misiniz?"
                      )
                    ) {
                      return;
                    }
                    setEkleOk(false);
                    setTab("urunler");
                  }}
                  className="fa-arrow-left fa-solid text-xl cursor-pointer hover:text-blue-400"
                ></i>
                <div className="font-medium mx-auto">Ürün Ekle </div>
              </div>
              <div className="p-3">
                {!ekleOk ? (
                  <form className="in" onSubmit={(e) => ekle(e)}>
                    <div className="row g-3">
                      <div className="col-lg-5 col-12">
                        <div className="">
                          <div className="font-medium">Barkod</div>
                          <img src="/barkod.svg" className="mx-auto" alt="" />
                          <div className="tracking-[.25em]	 font-medium text-center	">
                            {barcode}
                          </div>

                          <div className="row g-1 mt-2">
                            <div className="col-10">
                              <input
                                value={barcode}
                                onChange={(e) => {
                                  if (
                                    urunler.filter(
                                      (n) => n.barkod == e.target.value
                                    ).length != 0 && e.target.value != ""
                                  ) {
                                    setUyari(true);
                                  } else {
                                    setUyari(false);
                                  }
                                  setBarcode(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key == "Enter") {
                                    e.preventDefault();
                                    if (
                                      document.getElementById("urun_adi")
                                        .value == ""
                                    ) {
                                      document
                                        .getElementById("urun_adi")
                                        .focus();
                                    }
                                  }
                                }}
                                type="text"
                                placeholder="Barkodu okutunuz"
                                className="rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none w-full  cursor-pointer p-2"
                              />
                            </div>
                            <div className="col-2">
                              <button
                                type="button"
                                onClick={(e) => {
                                  setUyari(false)
                                  const rand =   Math.floor(Math.random() * 899) + 100
                                  setBarcode(
                                  rand
                                  );

                                 
                                }}
                                className="p-2 rounded bg-slate-200 text-slate-600"
                              >
                                <i className="fa-solid fa-barcode"></i>
                              </button>
                            </div>
                          </div>
                          {
                            uyari ? <div className="font-medium text-amber-500 text-xs">Bu barkod kullanılıyor</div> : ""
                          }
                          

                          <button
                            type="submit"
                            className="w-full mt-2 rounded bg-blue-400 hover:bg-blue-300 font-medium p-2"
                          >
                            Ekle
                          </button>
                          <button
                            onClick={(e) => {
                              if (
                                !window.confirm(
                                  "Değişikleri iptal etmek istediğinize emin misiniz?"
                                )
                              ) {
                                return;
                              }
                              setEkleOk(false);
                              setTab("urunler");
                            }}
                            type="button"
                            className="w-full mt-2 rounded bg-slate-200 hover:bg-slate-300 font-medium p-2"
                          >
                            İptal
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-7 col-12">
                        <div>
                          <div className="row g-2">
                            <div className="col-12">
                              <div>
                                <div className="font-medium lh-1">
                                  Ürün Adı*
                                </div>
                                <input
                                  id="urun_adi"
                                  type="text"
                                  placeholder="Ürün Adı*"
                                  required
                                  className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2"
                                />
                              </div>
                            </div>

                            <div className="col-12">
                              <div>
                                <div className="font-medium lh-1 mb-1">
                                  Kategori
                                </div>
                                {kategoriler != null ? (
                                  <CreatableSelect
                                    onCreateOption={(e) => {
                                      axios.post("/kategoriEkle/", {
                                        to_okul: localStorage.okul_id,
                                        isim: e.target[0].value,
                                      });
                                      console.log(e);
                                    }}
                                    formatCreateLabel={(e) =>
                                      "'" +
                                      e +
                                      "' adında yeni kategori oluştur: "
                                    }
                                    onChange={(val) => {
                                      console.log(val);
                                      setKategori(val.value);
                                    }}
                                    isClearable={true}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: "#f1f5f9",
                                        borderColor: "transparent",
                                        fontWeight: "500",
                                      }),
                                    }}
                                    options={kategoriler}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="col-4">
                              <div>
                                <div className="font-medium lh-1">Fiyat*</div>
                                <input
                                  min={0}
                                  step=".01"
                                  type="number"
                                  required
                                  placeholder="Fiyat*"
                                  className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2"
                                />
                              </div>
                            </div>

                            <div className="col-4">
                              <div>
                                <div className="font-medium lh-1">2. Fiyat</div>
                                <input
                                  min={0}
                                  step=".01"
                                  type="number"
                                  placeholder="2. Fiyat"
                                  className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2"
                                />
                              </div>
                            </div>

                            <div className="col-4">
                              <div>
                                <div className="font-medium lh-1">3. Fiyat</div>
                                <input
                                  min={0}
                                  step=".01"
                                  type="number"
                                  placeholder="3. Fiyat"
                                  className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2"
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div>
                                <div className="font-medium lh-1">Kalori</div>
                                <input
                                  type="number"
                                  min={0}
                                  placeholder="Kalori"
                                  className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2"
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div>
                                <div className="font-medium lh-1">Gluten</div>
                                <select className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2">
                                  <option value="true">Glutenli</option>
                                  <option value="false">Glutensiz</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-12">
                              <div>
                                <hr className="mb-2" />
                                <div className="font-medium lh-1">
                                  Stok Adeti
                                </div>
                                <input
                                  type="number"
                                  min={0}
                                  placeholder="Stok Adeti"
                                  className="w-full rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className="in flex flex-column items-center justify-center">
                    <i className="fa-solid fa-check text-green-500 text-5xl"></i>
                    <div className="font-medium text-green-500 text-xl">
                      Ürün Eklendi
                    </div>
                    <p className="text-slate-500 text-center px-3 font-medium">
                      Eklemiş olduğunuz bu ürüne şu anda hiç bir öğrencinin
                      satın alma yetkisi yoktur. Velilere ürünü eklediğinize
                      dair bildirim gönderilmiştir. Veli onayından sonra satışa
                      başlayabilirsiniz.
                    </p>

                    <div className="flex mt-3">
                      <button
                        onClick={(e) => setTab("urunler")}
                        className="bg-slate-200 hover:bg-slate-200 font-medium px-4 py-2 rounded "
                      >
                        Kapat
                      </button>
                      <button
                        onClick={(e) => setEkleOk(false)}
                        className="bg-blue-400 hover:bg-blue-300 font-medium p-2 rounded ms-2 "
                      >
                        Bir tane daha ekle
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="shadow-sm  flex items-center bg-white rounded-2 px-2">
          <div
            onClick={(e) => {
              navigate("/urunler");
            }}
            className={`cursor-pointer text-primary  hover:text-black  hover:bg-slate-100 py-2 flex-fill text-center font-medium`}
          >
            Ürünler
          </div>
          <div
            onClick={(e) => {
              navigate("/kategoriler");
            }}
            className={`cursor-pointer ${
              tab == "gruplar" ? "text-primary" : ""
            } border-l-2 hover:bg-slate-100 py-2 flex-fill text-center font-medium`}
          >
            Kategoriler
          </div>
        </div>

        <div className="mb-2">
          <div className="row g-2 mt-2">
            <div className={ids.length == 0 ? "col-6" : "col-4"}>
              <div className="flex px-2 	 bg-slate-100 rounded items-center">
                <i className="fa-solid fa-magnifying-glass"></i>

                {viewType == "box" ? (
                  <input
                    onChange={(e) => {
                      var input, filter, table, tr, td, i, txtValue;
                      input = e.target;
                      filter = input.value.toUpperCase();
                      tr = document.querySelectorAll(".u-list-name");

                      console.log(tr);

                      for (i = 0; i < tr.length; i++) {
                        td = tr[i];
                        if (td) {
                          txtValue = td.textContent || td.innerText;
                          if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            tr[
                              i
                            ].parentElement.parentElement.parentElement.style.display =
                              "";
                          } else {
                            tr[
                              i
                            ].parentElement.parentElement.parentElement.style.display =
                              "none";
                          }
                        }
                      }
                    }}
                    id="ara"
                    type="text"
                    className="noout bg-transparent font-medium p-2 w-full"
                    placeholder="Ürün, Kategori, Barkod Ara"
                  />
                ) : (
                  <input
                    id="ara"
                    onChange={(e) => {
                      var input, filter, table, tr, td, i, txtValue;
                      input = e.target;
                      filter = input.value.toUpperCase();
                      table = document.querySelector(".table tbody");
                      tr = table.querySelectorAll("tr");

                      console.log(tr);

                      for (i = 0; i < tr.length; i++) {
                        td = tr[i];
                        if (td) {
                          txtValue = td.textContent || td.innerText;
                          if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            tr[i].style.display = "";
                          } else {
                            tr[i].style.display = "none";
                          }
                        }
                      }
                    }}
                    type="text"
                    className="noout bg-transparent font-medium p-2 w-full"
                    placeholder="Ürün, Kategori, Barkod Ara"
                  />
                )}
              </div>
            </div>

            <div className="col">
              <select
                onChange={(e) => {
                  getUrunler(e.target.value);
                }}
                className="p-2 w-full rounded bg-slate-50 border-2"
              >
                <option value="urun_isim ASC">A'dan Z'ye</option>
                <option value="kategori ASC">Kategori: A-Z</option>
                <option value="urun_fiyat ASC">Ucuzdan Pahalıya</option>
                <option value="urun_fiyat DESC">Pahalıdan Ucuza</option>
                <option value="stok DESC">Stok</option>
              </select>
            </div>

            <div className="col-lg-4 col-12">
              <div className="flex">
                {viewType == "box" ? (
                  <button
                    onClick={(e) => {
                      setViewType("box");
                      setShowImages(!showImages);
                    }}
                    className={`${
                      showImages ? "filter-active" : ""
                    } bg-zinc-50 hover:bg-slate-200 p-2 border-2 rounded me-2`}
                  >
                    <i className="fa-regular fa-image"></i>
                  </button>
                ) : (
                  ""
                )}
                <button
                  onClick={(e) => {
                    setViewType("box");
                    setIds([]);
                  }}
                  className={`${
                    viewType == "box" ? "filter-active" : ""
                  } bg-white hover:bg-slate-200 p-2 rounded me-2 border-2 `}
                >
                  <i className="fa-solid fa-table-cells-large"></i>
                </button>
                <button
                  onClick={(e) => {
                    setViewType("list");
                    setIds([]);
                  }}
                  className={`${
                    viewType == "list" ? "filter-active" : ""
                  } bg-white hover:bg-slate-200 p-2 border-2 rounded me-2`}
                >
                  <i className="fa-solid fa-list"></i>
                </button>

                <button
                  onClick={(e) => {
                    setTab("ekle");
                    setEkleOk(false);
                  }}
                  className="py-2 px-3 bg-green-400 font-medium w-full rounded lh-1 text-center"
                >
                  Ürün Ekle
                </button>
              </div>
            </div>
            {ids.length != 0 ? (
              <div className="col-lg-2 col-12">
                <div className="h-full">
                  <button
                    onClick={(e) => {
                      if (!window.confirm("Emin misiniz")) {
                        return;
                      }
                      e.target.innerHTML =
                        "<i class='fa-solid fa-spin fa-spinner'></i>";
                      axios
                        .post(`/topluUrunSil/`, {
                          ids: ids.join(","),
                        })
                        .then((res) => {
                          console.log(res);
                          getUrunler(order);
                          e.target.innerHTML =
                            '<i class="fa-solid fa-trash"></i> Sil';
                        });
                    }}
                    className="py-2 px-3 text-white h-full bg-red-400 hover:bg-red-500 font-medium w-full rounded lh-1 text-center"
                  >
                    <i className="fa-solid fa-trash"></i> Sil
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row g-2">
          <div className="col-12 mt-4">
            <div className="row g-2 map-urunler">
              {urunler &&
                viewType == "box" &&
                urunler.map((val, i) => {
                  return (
                    <div key={i} className="col-12 col-sm-4 col-md-3">
                      <div className=" h-full kantin-urun bg-white rounded shadow  position-relative">
                        <div className="show-urun-edit position-absolute w-full h-full bg-zinc-700/50 rounded flex items-center justify-center flex-column">
                          <Link
                            to={`/urun/${val.urun_id}`}
                            className="bg-green-400 hover:bg-green-500 px-3 w-28  py-1 rounded font-medium"
                          >
                            <i className="fa-solid fa-pen"></i> Düzenle
                          </Link>
                          <button
                            onClick={(e) => sil(e, val.urun_id, val.urun_isim)}
                            className="bg-red-400 hover:bg-red-500 px-3 w-28 text-white mt-2  py-1 rounded font-medium"
                          >
                            <i className="fa-solid fa-trash"></i> Sil
                          </button>
                        </div>

                        {showImages ? (
                          <img
                            loading="lazy"
                            className="w-full object-contain rounded h-36"
                            src={
                              val.urun_resim != ""
                                ? val.urun_resim
                                : axios.defaults.base + "assets/img/yemek.jpg"
                            }
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                        <div className="p-2">
                          <div className="text-xs font-bold text-slate-400 lh-1">
                            {val.barkod}
                          </div>
                          <div className="u-list-name font-medium text-lg lh-1">
                            {val.urun_isim}
                            <span className="hidden">
                              {val.barkod} {val.urun_fiyat} {val.kategori}{" "}
                            </span>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="fiyat-text font-medium text-end text-green-600 lh-1 text-lg">
                              {val.urun_fiyat} ₺
                            </div>
                            <div className="font-medium text-sm text-italic">
                              {val.kalori} kcal
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {urunler && viewType == "list" ? (
                <div className="col-12">
                  <div className="bg-white rounded p-2 shadow-sm">
                    <table className="table">
                      <thead>
                        <tr>
                          <td></td>
                          <td className="font-bold">Ürün</td>
                          <td className="font-bold">Fiyat</td>
                          <td className="font-bold">Kategori</td>
                          <td className="font-bold">Barkod</td>
                          <td className="font-bold text-right">Stok</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {urunler.map((val, i) => {
                          return (
                            <tr
                              key={i}
                              className={` hover:bg-slate-100 ${
                                ids.indexOf(val.urun_id) != -1
                                  ? "bg-blue-400/25 hover:bg-blue-400/25"
                                  : ""
                              } cursor-pointer`}
                            >
                              <td className="border-0">
                                <input
                                  onChange={(e) => {
                                    const _a = [];
                                    document
                                      .querySelectorAll(".sec:checked")
                                      .forEach((el) => {
                                        _a.push(el.value);
                                      });
                                    console.log(_a);
                                    setIds(_a);
                                  }}
                                  id={`check-${i}`}
                                  onClick={(e) => e.stopPropagation()}
                                  className="sec"
                                  type="checkbox"
                                  value={val.urun_id}
                                />
                              </td>
                              <td className="p-0 font-medium border-0">
                                <label
                                  className="cursor-pointer w-full p-2"
                                  htmlFor={`check-${i}`}
                                >
                                  {val.urun_isim}
                                </label>
                              </td>
                              <td className="fiyat-text  font-medium border-0">
                                <span
                                  className="text-green-500"
                                  title="Varsayılan fiyat"
                                >
                                  {" "}
                                  {val.urun_fiyat}
                                </span>{" "}
                                / <span title="2. Fiyat"> {val.fiyat2}</span> /{" "}
                                <span title="3. Fiyat">{val.fiyat3} </span>
                              </td>
                              <td className="border-0 font-medium text-slate-500">
                                {val.kategori}
                              </td>
                              <td className="border-0 text-xs font-medium">
                                {val.barkod}
                              </td>
                              <td className="text-right border-0 text-sm font-medium">
                                {val.stok}
                              </td>

                              <td className="border-0">
                                <button
                                  className="text-sm bg-green-400 text-white hover:bg-green-500 rounded py-1 px-2 font-medium"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/urun/${val.urun_id}`);
                                  }}
                                >
                                  <i className="fa-solid fa-pen"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    {urunler && urunler.length == 0 ? (
                      <div className="p-2 text-center bg-amber-500/50 font-medium rounded">
                        Eklediğiniz bir ürün bulunmamaktadır.
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              {urunler == null && viewType == "box" ? (
                <>
                  <div className="col-12 col-sm-4 col-md-3">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                      <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                        <div className="skeleton mt-1 w-25 d-block rounded"></div>
                        <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-4 col-md-3">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                      <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                        <div className="skeleton mt-1 w-25 d-block rounded"></div>
                        <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-4 col-md-3">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                      <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                        <div className="skeleton mt-1 w-25 d-block rounded"></div>
                        <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-4 col-md-3">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                      <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                        <div className="skeleton mt-1 w-25 d-block rounded"></div>
                        <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-4 col-md-3">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                      <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                        <div className="skeleton mt-1 w-25 d-block rounded"></div>
                        <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-4 col-md-3">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                      <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                        <div className="skeleton mt-1 w-25 d-block rounded"></div>
                        <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-4 col-md-3">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                      <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                        <div className="skeleton mt-1 w-25 d-block rounded"></div>
                        <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-4 col-md-3">
                    <div className="h-full kantin-urun bg-white rounded shadow ">
                      <div className="w-full h-24-important skeleton rounded-top"></div>

                      <div className="p-2">
                        <div className="skeleton mt-1 w-25 d-block rounded"></div>
                        <div className="skeleton mt-1 w-50 d-block rounded"></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {urunler == null && viewType == "list" ? (
                <>
                  <div className="col-12">
                    <div className="bg-white rounded p-2 shadow-sm">
                      <table className="table">
                        <thead>
                          <tr>
                            <td>
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-bold">
                              {" "}
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-bold">
                              {" "}
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-bold">
                              {" "}
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-bold">
                              {" "}
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-bold">
                              {" "}
                              <div className="skeleton d-block"></div>
                            </td>

                            <td className="font-bold">
                              {" "}
                              <div className="skeleton d-block"></div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="cursor-pointer hover:bg-slate-100">
                            <td className="border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-medium border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="fiyat-text  font-medium border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 font-medium text-slate-500">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 text-xs font-medium">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 text-sm font-medium">
                              <div className="skeleton d-block"></div>
                            </td>
                          </tr>

                          <tr className="cursor-pointer hover:bg-slate-100">
                            <td className="border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-medium border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="fiyat-text  font-medium border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 font-medium text-slate-500">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 text-xs font-medium">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 text-sm font-medium">
                              <div className="skeleton d-block"></div>
                            </td>{" "}
                            <td className="border-0 text-sm font-medium">
                              <div className="skeleton d-block"></div>
                            </td>
                          </tr>

                          <tr className="cursor-pointer hover:bg-slate-100">
                            <td className="border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-medium border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="fiyat-text  font-medium border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 font-medium text-slate-500">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 text-xs font-medium">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 text-sm font-medium">
                              <div className="skeleton d-block"></div>
                            </td>{" "}
                            <td className="border-0 text-sm font-medium">
                              <div className="skeleton d-block"></div>
                            </td>
                          </tr>

                          <tr className="cursor-pointer hover:bg-slate-100">
                            <td className="border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-medium border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="fiyat-text  font-medium border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 font-medium text-slate-500">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 text-xs font-medium">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 text-sm font-medium">
                              <div className="skeleton d-block"></div>
                            </td>{" "}
                            <td className="border-0 text-sm font-medium">
                              <div className="skeleton d-block"></div>
                            </td>
                          </tr>

                          <tr className="cursor-pointer hover:bg-slate-100">
                            <td className="border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-medium border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="fiyat-text  font-medium border-0">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 font-medium text-slate-500">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 text-xs font-medium">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="border-0 text-sm font-medium">
                              <div className="skeleton d-block"></div>
                            </td>{" "}
                            <td className="border-0 text-sm font-medium">
                              <div className="skeleton d-block"></div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            {urunler && urunler.length == 0 && viewType == "box" ? (
              <div>
                <div className="p-2 text-center bg-amber-300 font-medium rounded">
                  Eklediğiniz bir ürün bulunmamaktadır.
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Urunler;
