import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MobileHeader from "../components/MobileHeader";
import InputMask from "react-input-mask";

export default function Kullanicilar() {
  const [users, setUsers] = useState(null);
  const [edit, setEdit] = useState(null);
  const [type, setType] = useState("true");
  const [ekleMode, setEkleMode] = useState(false);
  const [editData, setEditData] = useState(null);

  let navigate = useNavigate();

  function getUsers() {
    axios.get(`/getUsers/${localStorage.okul_id}/`).then((res) => {
      setUsers(res.data);
    });
  }

  useEffect(() => {
    getUsers();
  }, []);

  function editUser(e) {
    e.preventDefault();
    console.log(editData);
    if (e.target[4].value.length != 12 && editData.admin == "true") {
      alert("Telefon numarası 10 haneli olmalıdır.");
      return;
    }
    e.target[5].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";

    axios
      .post(`/editUser`, {
        user_id: editData.user_id || editData[0].user_id,
        admin: e.target[1].value,
        isim: e.target[0].value,
        nick: e.target[2].value,
        pass: e.target[3].value,
        tel:
          e.target[1].value == "true"
            ? e.target[4].value.split(" ").join("")
            : "",
      })
      .then((res) => {
        window.location.reload();
      });
  }

  function addUser(e) {
    e.preventDefault();
    console.log(e.target[4].value);
    if (e.target[4].value.length != 12 && type == "true") {
      alert("Telefon numarası 10 haneli olmalıdır.");
      return;
    }
    e.target[5].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";

    axios
      .post(`/addUser`, {
        to_okul: localStorage.okul_id,
        admin: e.target[1].value,
        isim: e.target[0].value,
        nick: e.target[2].value,
        pass: e.target[3].value,
        tel: e.target[4].value.split(" ").join(""),
        aktif: 1,
      })
      .then((res) => {
        e.target[5].innerHTML = "Ekle";
        if ("hata" in res.data) {
          alert("Başka bir kullanıcı adı seçiniz");
        } else {
          setUsers(res.data);
          e.target[0].value = "";
          e.target[2].value = "";
          e.target[3].value = "";
          e.target[4].value = "";
        }
      });
  }

  if (localStorage.admin == "true") {
    return (
      <div className="screen">
        <div className="page position-relative">
          <MobileHeader />
          <div className="mb-2"></div>

          <div className="row g-2">
            <div className="col-12 ">
              <div className="row g-2 map-urunler">
                {users ? (
                  <>
                    <div className="col-lg-7 col-12">
                      {editData ? (
                        <div className="mb-2  in bg-white rounded p-2 shadow-sm">
                          <div className="flex items-center justify-between">
                            <div className="font-medium mb-2">
                              Kullanıcı Düzenle
                            </div>

                            <div className="flex">
                              {editData.aktif == "1" ? (
                                <button
                                onClick={e=>{
                                  if (
                                    window.confirm(
                                      "Bu kullanıcıyı pasif yapmak istediğinize emin misiniz"
                                    )
                                  ) {
                                    e.target.innerHTML =
                                      "<i class='fa-solid fa-spin fa-spinner'></i>";
                                    axios
                                      .post(
                                        `/update/users/user_id/${editData.user_id}/?str=aktif = 0`
                                      )
                                      .then((res) => {
                                        window.location.reload();
                                      });
                                  }
                                }}
                                className="p-2 font-medium bg-amber-500 me-2 rounded hover:bg-amber-400 text-white text-xs">
                                  Pasif Yap
                                </button>
                              ) : (
                                <button
                                onClick={e=>{
                                  if (
                                    window.confirm(
                                      "Bu kullanıcıyı aktif yapmak istediğinize emin misiniz"
                                    )
                                  ) {
                                    e.target.innerHTML =
                                      "<i class='fa-solid fa-spin fa-spinner'></i>";
                                    axios
                                      .post(
                                        `/update/users/user_id/${editData.user_id}/?str=aktif = 1`
                                      )
                                      .then((res) => {
                                        window.location.reload();
                                      });
                                  }
                                }}
                                className="p-2 font-medium bg-green-500 me-2 rounded hover:bg-green-400 text-white text-xs">
                                  Aktifleştir
                                </button>
                              )}

                              <button
                                onClick={(e) => {
                                  if (
                                    window.confirm(
                                      "Bu kullanıcıyı silmek istediğinize emin misiniz"
                                    )
                                  ) {
                                    e.target.innerHTML =
                                      "<i class='fa-solid fa-spin fa-spinner'></i>";
                                    axios
                                      .post(
                                        `/update/users/user_id/${editData.user_id}/?str=deleted = 1`
                                      )
                                      .then((res) => {
                                        window.location.reload();
                                      });
                                  }
                                }}
                                className="p-2 bg-red-500 rounded hover:bg-red-400 text-white text-xs"
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </div>
                          </div>

                          <form onSubmit={(e) => editUser(e)}>
                            <div className="row g-2">
                              <div className="col-6">
                                <div>
                                  <div className="font-medium text-sm">
                                    İsim
                                  </div>
                                  <input
                                    required
                                    type="text"
                                    defaultValue={editData.isim}
                                    placeholder="İsim"
                                    className="px-2 py-1 font-medium rounded bg-slate-100 w-full"
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div>
                                  <div className="font-medium text-sm">Rol</div>
                                  <select
                                    onChange={(e) => {
                                      console.log(editData);
                                      const a = [editData];
                                      a.admin = e.target.value;
                                      console.log(a);
                                      setEditData(a);
                                    }}
                                    defaultValue={editData.admin}
                                    className="px-2 py-1 font-medium rounded bg-slate-100 w-full"
                                  >
                                    <option value="true">Admin</option>
                                    <option value="false">Personel</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-6">
                                <div>
                                  <div className="font-medium text-sm">
                                    Kullanıcı Adı
                                  </div>
                                  <input
                                    readOnly
                                    disabled
                                    type="text"
                                    minLength={6}
                                    required
                                    defaultValue={editData.nick}
                                    placeholder="Kullanıcı Adı"
                                    className="px-2 py-1 font-medium rounded bg-slate-100 w-full"
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div>
                                  <div className="font-medium text-sm">
                                    Şifre
                                  </div>
                                  <input
                                    type="text"
                                    minLength={6}
                                    required
                                    placeholder="Şifre"
                                    defaultValue={editData.pass}
                                    className="px-2 py-1 font-medium rounded bg-slate-100 w-full"
                                  />
                                </div>
                              </div>

                              <div className="col-6">
                                <div
                                  className="in"
                                  style={{
                                    display:
                                      editData.admin == "true"
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  <div className="font-medium text-sm">
                                    Telefon No
                                  </div>
                                  <InputMask
                                    placeholder="Telefon"
                                    required={
                                      editData.admin == "true" ? true : false
                                    }
                                    mask="599 999 9999"
                                    maskChar=""
                                    defaultValue={editData.tel}
                                    className="px-2 py-1 font-medium rounded bg-slate-100 w-full"
                                  />
                                  <div className="font-medium text-slate-400 text-sm">
                                    Giriş yaparken bu numaraya sms gidecektir.
                                  </div>
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="font-medium text-sm">
                                  &nbsp;
                                </div>

                                <button
                                  type="submit"
                                  className="font-medium py-1  rounded bg-green-500 w-full text-white"
                                >
                                  <i className="fa-solid fa-file"></i> Kaydet
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      ) : (
                        ""
                      )}

                      {ekleMode ? (
                        <div className="mb-2  in bg-white rounded p-2 shadow-sm">
                          <div className="font-medium mb-2">Kullanıcı Ekle</div>

                          <form onSubmit={(e) => addUser(e)}>
                            <div className="row g-2">
                              <div className="col-6">
                                <div>
                                  <div className="font-medium text-sm">
                                    İsim
                                  </div>
                                  <input
                                    required
                                    type="text"
                                    placeholder="İsim"
                                    className="px-2 py-1 font-medium rounded bg-slate-100 w-full"
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div>
                                  <div className="font-medium text-sm">Rol</div>
                                  <select
                                    onChange={(e) => setType(e.target.value)}
                                    required
                                    className="px-2 py-1 font-medium rounded bg-slate-100 w-full"
                                  >
                                    <option value="true">Admin</option>
                                    <option value="false">Personel</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-6">
                                <div>
                                  <div className="font-medium text-sm">
                                    Kullanıcı Adı
                                  </div>
                                  <input
                                    type="text"
                                    minLength={6}
                                    required
                                    placeholder="Kullanıcı Adı"
                                    className="px-2 py-1 font-medium rounded bg-slate-100 w-full"
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div>
                                  <div className="font-medium text-sm">
                                    Şifre
                                  </div>
                                  <input
                                    type="text"
                                    minLength={6}
                                    required
                                    placeholder="Şifre"
                                    className="px-2 py-1 font-medium rounded bg-slate-100 w-full"
                                  />
                                </div>
                              </div>

                              <div className="col-6">
                                <div
                                  className="in"
                                  style={{
                                    display: type == "true" ? "block" : "none",
                                  }}
                                >
                                  <div className="font-medium text-sm">
                                    Telefon No
                                  </div>
                                  <InputMask
                                    placeholder="Telefon"
                                    required={type == "true" ? true : false}
                                    mask="599 999 9999"
                                    maskChar=""
                                    className="px-2 py-1 font-medium rounded bg-slate-100 w-full"
                                  />
                                  <div className="font-medium text-slate-400 text-sm">
                                    Giriş yaparken bu numaraya sms gidecektir.
                                  </div>
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="font-medium text-sm">
                                  &nbsp;
                                </div>

                                <button
                                  type="submit"
                                  className="font-medium py-1  rounded bg-blue-400 w-full text-white"
                                >
                                  Ekle
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="bg-white rounded p-2 shadow-sm">
                        <div className="mb-2 flex items-center justify-between">
                          <div className="font-medium">Kulllanıcılar</div>
                          <button
                            onClick={(e) => {
                              setEkleMode(!ekleMode);
                              setEditData(null);
                            }}
                            className="hover:bg-blue-300 text-blue-800 bg-blue-200 rounded font-medium py-1 px-3 text-blue-600"
                          >
                            <i className="fa-plus fa-sol"></i> Ekle
                          </button>
                        </div>
                        <hr className="border-slate-600" />
                        <table className="table">
                          <thead>
                            <tr>
                              <td className="font-bold text-left">İsim</td>

                              <td className="font-bold">Rol</td>
                              <td className="font-bold">Telefon</td>
                              <td className="font-bold text-end"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {users.users.map((val, i) => {
                              return (
                                <tr key={i} className={`hover:bg-slate-100 `}>
                                  <td className="border-0 font-medium ">
                                    {val.isim}
                                  </td>
                                  <td className="border-0 font-medium">
                                    {val.admin === "true" ? (
                                      <span className="text-red-400">
                                        Admin
                                      </span>
                                    ) : (
                                      <span className="text-green-400">
                                        Personel
                                      </span>
                                    )}
                                  </td>
                                  <td className="border-0 text-sm ">
                                    {val.tel ? val.tel : "Yok"}
                                  </td>
                                  <td className=" border-0 text-end text-sm font-medium">
                                    <button
                                      onClick={(e) => {
                                        const _self = e.currentTarget;
                                        _self.innerHTML =
                                          "<i class='fa-spin fa-spinner fa-solid'></i>";
                                        setEditData(null);
                                        axios
                                          .get(`/getOkulUser/${val.user_id}/`)
                                          .then((res) => {
                                            _self.innerHTML =
                                              '<i class="fa-solid fa-user-edit"></i> Düzenle';
                                            setEditData(res.data[0]);
                                            setEkleMode(false);
                                          });
                                      }}
                                      className="py-1 px-2 w-full d-block hover:text-zinc-700 hover:bg-green-300 rounded bg-green-200 text-green-600"
                                    >
                                      <i className="fa-solid fa-user-edit"></i>{" "}
                                      Düzenle
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-lg-5 col-12">
                      <div className="bg-white p-2 rounded shadow-sm">
                        <div className="font-medium text-center mb-2">
                          Bölgeler
                        </div>
                        <div className="row g-2">
                          {users.bolgeler.map((val, i) => {
                            return (
                              <div key={i} className="col-6">
                                <div
                                  className="p-2 rounded bg-slate-100 flex items-center"
                                  key={val.bolge_id}
                                >
                                  <i className="text-gray-600 fa-solid fa-location-dot"></i>
                                  <div className="font-medium ms-2 text-sm">
                                    {val.bolge_isim}
                                  </div>
                                  {val.bolge_isim != "Genel" ? (
                                    <div className="flex ms-auto items-center">
                                      <i
                                        onClick={(e) => {
                                          if (
                                            window.confirm(
                                              "Bu bölgeyi silmek istediğinize emin misiniz"
                                            )
                                          ) {
                                            e.target.parentElement.parentElement.parentElement.style.opacity =
                                              "0.5";
                                            axios
                                              .post(
                                                `/bolgeDelete/${localStorage.okul_id}/${val.bolge_id}`
                                              )
                                              .then((res) => {
                                                e.target.parentElement.parentElement.parentElement.remove();
                                              });
                                          }
                                        }}
                                        className="me-2 fa-solid fa-trash text-red-400 cursor-pointer hover:text-red-500"
                                      ></i>
                                      <i
                                        onClick={() =>
                                          setEdit({
                                            id: val.bolge_id,
                                            text: val.bolge_isim,
                                          })
                                        }
                                        className="fa-solid fa-pen text-green-400 cursor-pointer hover:text-green-500"
                                      ></i>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        {edit ? (
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              e.target[1].innerHTML =
                                "<i class='fa-solid fa-spin fa-spinner'></i>";
                              axios
                                .post(
                                  `/update/bolgeler/bolge_id/${edit.id}/?str=bolge_isim = '${e.target[0].value}' `
                                )
                                .then((res) => {
                                  window.location.reload();
                                });
                            }}
                          >
                            <div className="row g-2 mt-2">
                              <div className="col-6">
                                <input
                                  required
                                  type="text"
                                  placeholder="Bölge İsmi"
                                  defaultValue={edit.text}
                                  className="rounded w-full font-medium bg-slate-100 p-2"
                                />
                              </div>
                              <div className="col-3">
                                <button
                                  type="submit"
                                  className="cursor-pointer w-full text-white hover:bg-green-500 bg-green-400 font-medium p-2 rounded flex items-center justify-center"
                                >
                                  Düzenle
                                </button>
                              </div>

                              <div className="col-3">
                                <button
                                  onClick={() => setEdit(null)}
                                  type="submit"
                                  className="cursor-pointer w-full  hover:bg-slate-100 bg-slate-200 font-medium p-2 rounded flex items-center justify-center"
                                >
                                  İptal
                                </button>
                              </div>
                            </div>
                          </form>
                        ) : (
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              e.target[1].innerHTML =
                                "<i class='fa-solid fa-spin fa-spinner'></i>";
                              axios
                                .post(`/addBolge`, {
                                  okul: localStorage.okul_id,
                                  isim: e.target[0].value,
                                })
                                .then((res) => {
                                  setUsers(res.data);

                                  e.target[0].value = "";
                                  e.target[1].innerHTML = `<i className="fa-solid fa-plus me-2"></i> Ekle`;
                                });
                            }}
                          >
                            <div className="row g-2 mt-2">
                              <div className="col-8">
                                <input
                                  required
                                  type="text"
                                  placeholder="Bölge İsmi"
                                  className="rounded w-full font-medium bg-slate-100 p-2"
                                />
                              </div>
                              <div className="col-4">
                                <button
                                  type="submit"
                                  className="cursor-pointer w-full text-white hover:bg-blue-500 bg-blue-400 font-medium p-2 rounded flex items-center justify-center"
                                >
                                  <i className="fa-solid fa-plus me-2"></i> Ekle
                                </button>
                              </div>
                            </div>

                            <div></div>
                          </form>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return navigate("/");
  }
}
