import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import IframeResizer from "iframe-resizer-react";
import { tarih } from "../iskonto";
import { Link, useNavigate } from "react-router-dom";
function VeliAyarlar() {
  const [user, setUser] = useState(null);

  let navigate = useNavigate();
  useEffect(() => {
    axios.get(`/getUser/${localStorage.veli_id}`).then((res) => {
      console.log(res.data);
      setUser(res.data);
    });
  }, []);

  function save(e, id) {
    e.preventDefault();
    e.target[6].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";
    axios
      .post(`/updatePost/ogrenci/ogrenci_id/${id}`, {
        first: e.target[0].value,
        mail: e.target[1].value,
        isim: e.target[2].value,
        numara: e.target[3].value,
        sifre: e.target[4].value,
      })
      .then((res) => {
        navigate("/");
      });
  }

  return (
    <div className="screen ps-0">
      <div className="page-min">
        <div className="row g-2">
          <div className="col-12">
            <div className="kutu shadow-sm rounded p-3 bg-white">
              <div className="row g-2">
                <div className="col-12 col-sm-6">
                  <div className="p-3 h-full flex items-center justify-center flex-column">
                    {user && user[0].first == "1" ? (
                      <>
                        <div className="font-medium text-xl text-center">
                          Hoşgeldiniz
                        </div>
                        <div className="font-medium text-slate-400 text-center">
                          Buradan öğrencinze bakiye yükleyebilir, yemek
                          izinlerini ayarlayabilir, öğrencinizin aldığı ürünleri
                          görebilir veya günlük harcama limiti koyabilirsiniz.{" "}
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {user && user[0].first == "0" ? (
                      <>
                        <div className="font-medium text-xl text-center">
                          Ayarlar
                        </div>
                        <div className="font-medium text-slate-400 text-center">
                          Bilgilerinizi doğru bir şekilde doldurunuz
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {user ? (
                  <div className="col-12 col-sm-6 in">
                    <div className="font-medium text-gray-500 text-center sm:text-left">
                      Bilgileri düzenle
                    </div>
                    <form onSubmit={(e) => save(e, user[0].ogrenci_id)}>
                      <div className="row g-2">
                        <input type="hidden" value="0" />
                        <div className="col-12">
                          <div className="font-medium">
                            Sizin mail adresiniz
                          </div>
                          <input
                            defaultValue={user[0].mail}
                            placeholder="Sizin mail adresiniz"
                            className="w-full rounded px-2 py-1 bg-slate-100"
                            type="mail"
                          />
                          <div className="text-xs italic text-gray-500 font-medium">
                            Bakiye yükleme onayları ve öğrencinizin ürün satın
                            alma bildirimleri maile gönderilecektir.
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="font-medium">Öğrencinizin ismi</div>
                          <input
                            defaultValue={user[0].isim}
                            placeholder="Öğrencinizin ismi"
                            className="w-full rounded px-2 py-1 bg-slate-100"
                            type="text"
                            required
                          />
                        </div>

                        <div className="col-12">
                          <div className="font-medium">
                            Öğrencinizin okul numarası
                          </div>
                          <input
                            defaultValue={user[0].numara}
                            placeholder="Öğrencinizin okul numarası"
                            className="w-full rounded px-2 py-1 bg-slate-100"
                            type="number"
                          />
                        </div>

                        <div className="col-12">
                          <div className="font-medium">Giriş Şifreniz</div>
                          <input
                            defaultValue={user[0].sifre}
                            placeholder="Giriş Şifreniz"
                            className="w-full rounded px-2 py-1 bg-slate-100"
                            required
                            minLength={6}
                            type="text"
                          />
                        </div>

                        <div
                          className={user[0].first == "1" ? "col-6" : "col-4"}
                        >
                          <button
                            type="button"
                            onClick={(e) => navigate("/")}
                            className={
                              user[0].first == "1"
                                ? "hidden"
                                : "p-2 rounded bg-slate-100 font-medium w-full"
                            }
                          >
                            Geri
                          </button>
                        </div>
                        {
                          user[0].first == "1" ?
                        
                        <div className="col-12">
                          <div class="form-check cursor-pointer">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              required
                              id="flexCheckDefault"
                            />

                            <label
                              class="cursor-pointer form-check-label text-zinc-800"
                              htmlFor="flexCheckDefault"
                            >
                              <Link
                                target={"_blank"}
                                to="/veli-sozlesmesi"
                                className="underline hover:text-zinc-500"
                              >
                                Kullanıcı Sözleşmesi
                              </Link>{" "}
                              kabul ediyorum.
                            </label>
                          </div>
                        </div> : "" }
                        <div
                          className={user[0].first == "1" ? "col-6" : "col-8"}
                        >
                          <button
                            type="submit"
                            className="p-2 rounded bg-green-400 font-medium w-full"
                          >
                            <i className="fa-solid fa-check"></i> Devam Et
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VeliAyarlar;
