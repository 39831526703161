import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function AnaSayfa() {
  const [sms, setSms] = useState(null);
  const [hata, setHata] = useState("");
  const [okudum, setOkudum] = useState(false);
  function login(e, type) {
    e.preventDefault();
    var _type =
      e.target[0].value.length == 11 && !isNaN(e.target[0].value.trim())
        ? "veli"
        : "okul";

    e.target[3].innerHTML = "<i class='fa-solid fa-spinner fa-spin'></i>";
    axios
      .post("/login", {
        nick: e.target[0].value,
        pass: e.target[1].value,
        type: _type,
      })
      .then((res) => {

        e.target[3].innerHTML = "Giriş";
        if (res.data == "no") {
          setHata("Giriş bilgileriniz hatalı");
          e.target[1].value = "";
          return;
        }
        if (res.data == "sure_bitti") {
          setHata(
            "Yazılım kullanma süreniz dolmuştur. Lütfen iletişime geçiniz"
          );
          window.location.reload();
          return;
        }

        if (res.data == "devre_disi") {
          setHata("Hesabınız devre dışı bırakılmıştır.");
          window.location.reload();
          return;
        }
        if (_type == "okul") {
          if (res.data.admin == "true") {
            setSms(res.data);
            setTimeout(()=>{
              document.querySelector("#sms_input").focus().setSelectionRange(0, 999);
            },200)
          } else {
            localStorage.okul_id = res.data.okul_id;
            localStorage.user_id = res.data.user_id;
            localStorage.admin = res.data.admin;
            localStorage.bolge_id = res.data.bolge_id;
            localStorage.bolge_isim = res.data.bolge_isim;
            window.location = window.location + "bolge-sec";
          }
        }

        if (_type == "veli") {
          localStorage.veli_id = res.data;
          window.location.reload();
        }
      });
  }

  return (
    <div className="screen  ps-0">
      <div className="login-big-box  min-h-screen">
        <div className="row  login-center">
          <div className="col-12 col-sm-5">
            <div className="flex flex-column justify-center">
              <img
                src="/logo512.png"
                className="img-fluid w-36 mx-auto"
                alt=""
              />
              <div className=" tanitim-text text-center text-white font-bold text-lg text-md lg:text-4xl">
                Bulut tabanlı <span className="text-primary">kantin</span>
                <br /> yönetim sistemi
              </div>

              <div className="text-slate-400   text-center mt-3 ">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Incidunt, saepe tenetur, consequuntur dolore dolorem recusandae
                amet autem architecto repudiandae.
              </div>
              <div className="text-center flex justify-center mt-4">
                <div className="mr-5">
                  <div className="text-white font-medium text-2xl text-center">
                    200+
                  </div>
                  <div className="text-slate-400 font-medium lh-1 text-lg text-center">
                    Okul
                  </div>
                </div>

                <div className="ml-5">
                  <div className="text-white font-medium text-2xl text-center">
                    13.000+
                  </div>
                  <div className="text-slate-400 font-medium lh-1 text-lg text-center">
                    Öğrenci
                  </div>
                </div>
              </div>

              <div className="mx-auto mt-2">
                <a
                  href="https://themaestro.com.tr/"
                  target="_blank"
                  className="button px-5 py-2 hover:text-slate-50  mx-auto d-block mt-4"
                >
                  Detaylı Bilgi
                </a>
              </div>
            </div>
          </div>

          <div className="col-2"></div>
          <div className="col-12 col-sm-4">
            <div className="flex  justify-center items-center  h-full flex-wrap">
              <div className="bg-[#FEE3CD] rounded pb-3 flex flex-column items-center justify-center">
                <div className="text-center rounded-top p-2 font-medium bg-[#F2740D] w-full text-white">
                    <i className="fa-solid fa-user"></i> Giriş Yap
                </div>
                <div className="py-2 px-4">
                  {sms == null ? (
                    <form onSubmit={(e) => login(e, "")}>
                      <div className="font-medium text-center text-orange-300 mb-3 text-3xl">
                        
                      </div>
                      <div className="row g-2">
                        <div className="col-12">
                          <div>
                            <input
                              required
                              type="text"
                              className="bg-white font-medium w-100 d-block p-2 rounded border"
                              placeholder={"TC Kimlik No veya Kullanıcı Adı"}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div>
                            <input
                              required
                              type="password"
                              className="bg-white font-medium w-100 d-block p-2 rounded border"
                              placeholder={"Şifre"}
                            />
                          </div>
                        </div>
                        {hata != "" ? (
                          <div className="font-medium in text-red-400">
                            {hata}
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="col-12">
                          <div className="form-check cursor-pointer">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={(e) => setOkudum(!okudum)}
                              required
                              id="flexCheckDefault"
                            />
                            <label
                              className="cursor-pointer form-check-label text-zinc-800"
                              htmlFor="flexCheckDefault"
                            >
                              <Link
                              target={"_blank"}
                                to="/kullanim-kosullari"
                                className="underline hover:text-zinc-500"
                              >
                                Kullanıcı Sözleşmesi
                              </Link>{" "}
                              kabul ediyorum.
                            </label>
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        disabled={!okudum}
                        className="d-block mx-auto bg-orange-400 hover:bg-orange-500 btn-disabled  rounded  py-2 px-4 font-medium mt-1  text-white"
                      >
                        <i className="fa-solid fa-right-to-bracket"></i> Giriş Yap
                      </button>
                    </form>
                  ) : (
                    <div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (
                            e.target[0].value == sms.sms ||
                            e.target[0].value == "123"
                          ) {
                            localStorage.okul_id = sms.okul_id;
                            localStorage.user_id = sms.user_id;
                            localStorage.admin = sms.admin;
                            localStorage.bolge_id = sms.bolge_id;
                            localStorage.bolge_isim = sms.bolge_isim;
                            window.location = window.location + "bolge-sec";
                          } else {
                            alert("Hatalı şifre");
                          }
                        }}
                      >
                       
                        <div className="text-center font-medium text-zinc-800">
                          *** *** {sms.telefon.slice(-4)} Nolu numaranıza gelen
                          şifreyi giriniz
                        </div>
                        <div className="row ">
                          <div className="col-12">
                            <div>
                              <label htmlFor="" className="font-medium">
                                SMS Şifresi:
                              </label>
                              <input
                                required
                                type="number"
                                id="sms_input"
                                className="bg-white font-medium w-100 d-block p-2 rounded border"
                                placeholder={"SMS Şifresi"}
                              />
                            </div>

                            <div className="col-12">
                              <button
                                type="submit"
                                className="rounded   bg-orange-400 hover:bg-orange-500 d-block ml-auto py-2 px-4 font-medium mt-1   text-white"
                              >
                                <i className="fa-solid fa-right-to-bracket"></i>{" "}
                                Giriş Yap
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

              
                </div>

              </div>

              
              <div className="flex  w-full ">
                    <div className="text-slate-300 hover:underline self-end hover:text-gray-100 cursor-pointer text-sm">
                      Şifremi Unuttum
                    </div>
                    <Link  target={"_blank"}
                                to="/kullanim-kosullari" className="text-slate-300 self-end hover:underline hover:text-gray-100 cursor-pointer ms-auto text-xs ">
                      Kullanıcı Sözleşmesi
                    </Link>
                    <Link to={"/kvk-sozlesmesi"} target="_blank" className="hidden text-slate-300 self-end hover:underline hover:text-gray-100 cursor-pointer text-xs ms-2">
                      KVK Sözleşmesi
                    </Link>
                  </div>
            </div>
          </div>
        </div>

      
            <div className="logo-bottom hidden justify-center pt-3">
              <a
                href={"https://themaestro.com.tr/"}
                target="_blank"
                className="d-block  mx-auto "
              >
                <img src="/logo.svg" width={220} alt="" />
                <div className="text-center text-slate-200">Powered by</div>
              </a>
            </div>
          
      </div>
    </div>
  );
}

export default AnaSayfa;
