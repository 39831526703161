import axios from "axios";
import React from "react";
import { forwardRef } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Hareket from "../components/Hareket";
import { tarih } from "../iskonto";
import MobileHeader from "../components/MobileHeader";

function Hareketler() {
  const [data, setData] = useState(null);
  const [order, setOrder] = useState("DESC")

  useEffect(() => {
    getData()
  }, []);

  useEffect(()=>{
    getData()
  },[order])

  function getData(){
    setData(null)
    axios.get(`/hareketler/${localStorage.okul_id}?order=${order}`).then((res) => {
        console.log(res.data);
        setData(res.data);
      });
  }
  return (
    <div className="screen">
      <div className="page-min">
      <MobileHeader/>
        <div className="row g-2">
          <div className="col-12">
            <div className="bg-white p-2 rounded  items-center flex justify-between shadow-sm mb-2">
                <div className="font-medium "><i class="fa-solid fa-clock-rotate-left"></i> Hareketler</div>

                <div>
                     <select  className="p-2 rounded bg-slate-100" onChange={e=>setOrder(e.target.value)}>
                        <option value="DESC">Önce En Yeni</option>
                        <option value="ASC">Önce En Eski</option>
                     </select>
                </div>
            </div>

            <div className="mb-2">
              <button
              onClick={e=>{
                if(window.confirm("Emin misiniz")){
                  axios.post(`/delete/activites/okul/${localStorage.okul_id}`).then(res=>{
                    console.log(res)
                    window.location.reload()
                  })
                }
                  
              }}
              className="bg-red-500 font-medium text-white p-2 rounded w-full d-block">Veriyi Sıfırla</button>
            </div>
            <div className="bg-white p-2 rounded shadow-sm">

              {data &&
                data.map((val) => {
                  return (
                    <Hareket
                      key={val.activity_id}
                      date={val.tarih}
                      durum={val.durum}
                      data={val.data}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hareketler;
