import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {aylar} from "../../iskonto"
import MobileHeader from "../../components/MobileHeader";

function AylikRapor() {

  const start = useRef();
  const bitis = useRef();

  const [urunler, setUrunler] = useState(null);

  useEffect(() => {
    axios.get(`/aylikRapor/${localStorage.admin == "true" ? localStorage.okul_id : localStorage.user_id}/?admin=${localStorage.admin}`).then((res) => {
      setUrunler(res.data);
    });
  }, []);

  function tarihSearch(e) {
    e.preventDefault();

    if (start.current.value == "") {
      alert("Başlangıç tarihi giriniz");
      return;
    }

    if (bitis.current.value == "") {
      alert("Bitiş tarihi giriniz");
      return;
    }

    e.target[2].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";
    setUrunler(null);

    axios
      .get(
        `/aylikRapor/${localStorage.admin == "true" ? localStorage.okul_id : localStorage.user_id}/?start=${start.current.value}&bitis=${bitis.current.value}&admin=${localStorage.admin}`
      )
      .then((res) => {
        setUrunler(res.data);
        e.target[2].innerHTML = '<i class="fa-solid fa-magnifying-glass"></i>';
      });
  }

  return (
    <div className="screen">
      <div className="page">
      <MobileHeader/>
        <>
          <div className="row g-2 mt-2">

            <div className="col-12">
              <div className="flex items-center justify-between bg-white rounded p-2 shadow kutu">
                <div className="font-medium">Aylık Rapor</div>
                <select className="rounded bg-slate-50 hover:bg-slate-50 focus:outline-2 focus:outline-blue-400 outline-none mt-1 cursor-pointer py-1 px-2">
                     {
                      urunler && urunler.yillar.map((val,i)=>{
                        return(
                          <option key={i} value={val.yil}>{val.yil}</option>
                        )
                      })
                     }
               </select>
              </div>
            </div>
          
              <div className="col-12">
                <div className=" bg-white rounded p-2 shadow kutu">
                 
                  <div className="max-scroll">
                    {urunler ? 
                    <table className="table w-full">
                      <thead>
                        <tr>
                          <td className="font-bold">Ay</td>
                          <td className="font-bold">Bakiye</td>
                          <td className="font-bold">Nakit</td>
                          <td className="font-bold ">K. Kartı</td>
                        </tr>
                      </thead>
                      <tbody>
                        {urunler.aylar.map((val, i) => {
                          return (
                            <tr key={i} className={` hover:bg-slate-100  `}>
                              <td className=" font-medium border-0">
                                {aylar[Number(val.ay)-1 ]}
                              </td>
                              <td className="border-0 font-medium text-green-500">{
                               urunler.sip.filter(a => a.ay == val.ay && a.odeme == "Bakiye" ).length != 0 ? urunler.sip.filter(a => a.ay == val.ay && a.odeme == "Bakiye" )[0].fiyat : "0"
                                }</td>
                              <td className="font-medium border-0 text-green-600">{
                                urunler.sip.filter(a => a.ay == val.ay && a.odeme == "Nakit" ).length != 0 ? urunler.sip.filter(a => a.ay == val.ay && a.odeme == "Nakit" )[0].fiyat : "0"
                                }</td>
                              <td className="font-medium border-0 text-green-700">{
                               urunler.sip.filter(a => a.ay == val.ay && a.odeme == "K. Kartı" ).length != 0 ? urunler.sip.filter(a => a.ay == val.ay && a.odeme == "K. Kartı" )[0].fiyat : "0"
                                }</td>
                            </tr>
                          );
                        })}
                      </tbody>
                   
                    </table> : 
                      <table className="table w-full">
                      <thead>
                        <tr>
                          <td className="font-bold"> <div className="skeleton d-block"></div></td>
                          <td className="font-bold"> <div className="skeleton d-block"></div></td>
                          <td className="font-bold"> <div className="skeleton d-block"></div></td>
                          <td className="font-bold "> <div className="skeleton d-block"></div></td>
                        </tr>
                      </thead>
                      <tbody>
                     
                     {[...Array(7).keys()].map((val,i)=>{
                        return(
<tr key={i} className={` hover:bg-slate-100  `}>
                              <td className=" font-medium border-0">
                                <div className="skeleton d-block"></div>
                              </td>
                              <td className={`text-sm font-medium border-0`}>
                              <div className="skeleton d-block"></div>

                              </td>
                              <td className="border-0 font-medium text-slate-500">
                              <div className="skeleton d-block"></div>

                              </td>
                              <td className="text-end border-0 text-green-500 font-medium">
                              <div className="skeleton d-block"></div>

                              </td>
                            </tr>
                         
                        )
                     })}
                            
                      </tbody>
                    </table> 
                    }
                  </div>
                </div>
              </div>
          
          </div>
        </>
      </div>
    </div>
  );
}

export default AylikRapor;
