import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import axios from "axios";
import { tarih } from "../../iskonto";
import MobileHeader from "../../components/MobileHeader";


function BakiyeRapor() {
  const start = useRef();
  const bitis = useRef();
  const aralikTitle = useRef();

  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`/bakiyeRapor/${localStorage.okul_id}`).then((res) => {
      console.log(res);
      setData(res.data);
    });
  }, []);

  function tarihSearch(e) {
    e.preventDefault();
    console.log({
      start: start.current.value,
      bitis: bitis.current.value,
    });

    if (start.current.value == "") {
      alert("Başlangıç tarihi giriniz");
      return;
    }

    if (bitis.current.value == "") {
      alert("Bitiş tarihi giriniz");
      return;
    }

    aralikTitle.current.innerText = "Belirtilen Tarih";
    setData(null);
    axios
      .get(
        `/bakiyeRapor/${localStorage.okul_id}?start=${start.current.value}&bitis=${bitis.current.value}&admin=${
          localStorage.admin
        }`
      )
      .then((res) => {
        console.log(res);

        setData(res.data);
      });
  }
  return (
    <div className="screen">
      <div className="page-big">
      <MobileHeader/>
      <div className="mb-2 shadow-sm  flex items-center justify-between bg-white rounded-2 px-2">
              <div className="font-medium" ref={aralikTitle}>
                Tüm zamanlar
              </div>
              <form onSubmit={(e) => tarihSearch(e)}>
                <input
                  ref={start}
                  type="date"
                  max={new Date().toLocaleDateString("en-CA")}
                  className="bg-slate-100 p-2 border-l-5"
                />
                <input
                  ref={bitis}
                  type="date"
                  max={new Date().toLocaleDateString("en-CA")}
                  className="bg-slate-100 p-2 "
                />
                <button
                  type="submit"
                  className="bg-primary text-xs p-2 rounded-full "
                >
                  <i className="fa-solid fa-magnifying-glass "></i>{" "}
                </button>
              </form>
            </div>
        {data ? (
          <>
            

            <div className="row g-2">
              <div className="col-lg-4 col-12">
                <div className="mt-2 bg-white rounded p-2 shadow kutu">
                  <div className="flex items-center justify-center flex-column p-3">
                    <div className="text-2xl font-medium text-green-500">
                      {data.nakit.toplam} <span className="fiyat-text">₺</span>
                    </div>
                    <div className="font-medium">Nakit</div>
                  </div>

                  {data.nakit.tablo.length != 0 ? <> 
                  <div className="flex px-2 	 bg-slate-100 rounded items-center">
                    <i className="fa-solid  text-sm fa-magnifying-glass"></i>

                    <input
                      onChange={(e) => {
                        var input, filter, table, tr, td, i, txtValue;
                        input = e.target;
                        filter = input.value.toUpperCase();
                        table = document.querySelector(".nakit-table tbody");
                        tr = table.querySelectorAll("tr");

                        console.log(tr);

                        for (i = 0; i < tr.length; i++) {
                          td = tr[i];
                          if (td) {
                            txtValue = td.textContent || td.innerText;
                            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                              tr[i].style.display = "";
                            } else {
                              tr[i].style.display = "none";
                            }
                          }
                        }
                      }}
                      id="ara"
                      type="text"
                      className="noout text-sm bg-transparent font-medium p-1 w-full"
                      placeholder="Ara"
                    />
                  </div>
                  <div className="max-scroll">
                    <table className="table nakit-table mb-0">
                      <thead>
                        <tr>
                          <th>Miktar</th>
                          <th>Personel</th>
                          <th>Öğrenci</th>
                          <th className="text-end">Tarih</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.nakit.tablo.map((val) => {
                          return (
                            <>
                              <tr key={val.bakiye_id}>
                                <td className="border-0 text-green-400 font-medium">
                                  {val.miktar}
                                </td>
                                <td className="border-0 font-medium text-sm">
                                  {val.user_isim}
                                </td>
                                <td className="text-center border-0 text-xs font-medium lh-1 text-slate-400">
                                  {val.ogrenci_isim}
                                </td>
                                <td className="text-end border-0 text-xs font-medium lh-1 text-slate-400">
                                  {tarih(val.tarih)}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>


                  </div> </>: "" } 

                 {data.nakit.tablo.length == 0 ? <div className="bg-amber-200 font-medium p-2 text-center border-2 border-amber-400 rounded">Herhangi bir yükleme yok.</div> : ""}

                </div>
              </div>

              <div className="col-lg-4 col-12">
                <div className="mt-2 bg-white rounded p-2 shadow kutu">
                  <div className="flex items-center justify-center flex-column p-3">
                    <div className="text-2xl font-medium text-green-500">
                      {data.kart.toplam} <span className="fiyat-text">₺</span>
                    </div>
                    <div className="font-medium">K.Kartı</div>
                  </div>

                  {data.kart.tablo.length != 0 ? <> 
                  <div className="flex px-2 	 bg-slate-100 rounded items-center">
                    <i className="fa-solid  text-sm fa-magnifying-glass"></i>

                    <input
                      onChange={(e) => {
                        var input, filter, table, tr, td, i, txtValue;
                        input = e.target;
                        filter = input.value.toUpperCase();
                        table = document.querySelector(".kart-table tbody");
                        tr = table.querySelectorAll("tr");

                        console.log(tr);

                        for (i = 0; i < tr.length; i++) {
                          td = tr[i];
                          if (td) {
                            txtValue = td.textContent || td.innerText;
                            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                              tr[i].style.display = "";
                            } else {
                              tr[i].style.display = "none";
                            }
                          }
                        }
                      }}
                      id="ara"
                      type="text"
                      className="noout text-sm bg-transparent font-medium p-1 w-full"
                      placeholder="Ara"
                    />
                  </div>
                  <div className="max-scroll">
                    <table className="table kart-table mb-0">
                      <thead>
                        <tr>
                          <th>Miktar</th>
                          <th>Personel</th>
                          <th>Öğrenci</th>
                          <th className="text-end">Tarih</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.kart.tablo.map((val) => {
                          return (
                            <>
                              <tr key={val.bakiye_id}>
                                <td className="border-0 text-green-400 font-medium">
                                  {val.miktar}
                                </td>
                                <td className="border-0 font-medium text-sm">
                                  {val.user_isim}
                                </td>
                                <td className="text-center border-0 text-xs font-medium lh-1 text-slate-400">
                                  {val.ogrenci_isim}
                                </td>
                                <td className="text-end border-0 text-xs font-medium lh-1 text-slate-400">
                                  {tarih(val.tarih)}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>


                  </div>
                  </> : ""}
                                      {data.kart.tablo.length == 0 ? <div className="bg-amber-200 font-medium p-2 text-center border-2 border-amber-400 rounded">Herhangi bir yükleme yok.</div> : ""}

                </div>
              </div>

              <div className="col-lg-4 col-12">
                <div className="mt-2 bg-white rounded p-2 shadow kutu">
                  <div className="flex items-center justify-center flex-column p-3">
                    <div className="text-2xl font-medium text-green-500">
                      {data.online.toplam} <span className="fiyat-text">₺</span>
                    </div>
                    <div className="font-medium">Online</div>
                  </div>


 {data.online.tablo.length != 0 ? <>
                  <div className="flex px-2 	 bg-slate-100 rounded items-center">
                    <i className="fa-solid  text-sm fa-magnifying-glass"></i>

                    <input
                      onChange={(e) => {
                        var input, filter, table, tr, td, i, txtValue;
                        input = e.target;
                        filter = input.value.toUpperCase();
                        table = document.querySelector(".online-table tbody");
                        tr = table.querySelectorAll("tr");
                        

                        console.log(tr);

                        for (i = 0; i < tr.length; i++) {
                          td = tr[i];
                          if (td) {
                            txtValue = td.textContent || td.innerText;
                            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                              tr[i].style.display = "";
                            } else {
                              tr[i].style.display = "none";
                            }
                          }
                        }
                      }}
                      id="ara"
                      type="text"
                      className="noout text-sm bg-transparent font-medium p-1 w-full"
                      placeholder="Ara"
                    />
                  </div>
                  <div className="max-scroll">
                    <table className="table online-table mb-0">
                      <thead>
                        <tr>
                          <th>Miktar</th>
                          <th>Öğrenci</th>
                          <th className="text-end">Tarih</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.online.tablo.map((val) => {
                          return (
                            <>
                              <tr key={val.bakiye_id}>
                                <td className="border-0 text-green-400 font-medium">
                                  {val.miktar}
                                </td>
                            
                                <td className=" border-0 text-xs font-medium lh-1 text-slate-400">
                                  {val.ogrenci_isim}
                                </td>
                                <td className="text-end border-0 text-xs font-medium lh-1 text-slate-400">
                                  {tarih(val.tarih)}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>

                  </div>
                  </> : "" }
                  {data.online.tablo.length == 0 ? <div className="bg-amber-200 font-medium p-2 text-center border-2 border-amber-400 rounded">Herhangi bir yükleme yok.</div> : ""}

                </div>
              </div>
            </div>
          </>
        ) : (
            <div className="row g-2">
            <div className="col-4">
              <div className="mt-2 bg-white rounded p-2 shadow kutu">
                <div className="flex items-center justify-center flex-column p-3">
                  <div className="text-2xl font-medium text-green-500">
                  <div className="skeleton d-block px-5"></div>
                  </div>
                  <div className="font-medium"><div className="skeleton d-block px-5 mt-2"></div></div>
                </div>

                <div className="skeleton d-block"></div>
                <div className="max-scroll">
                  <table className="table nakit-table mb-0">
                    <thead>
                      <tr>
                        <th><div className="skeleton d-block"></div></th>
                        <th><div className="skeleton d-block"></div></th>
                        <th><div className="skeleton d-block"></div></th>
                        <th className="text-end"><div className="skeleton d-block"></div></th>
                      </tr>
                    </thead>
                    <tbody>
                    
                            <tr >
                              <td className="border-0 text-green-400 font-medium">
                              <div className="skeleton d-block"></div>
                              </td>
                              <td className="border-0 font-medium text-sm">
                              <div className="skeleton d-block"></div>
                              </td>
                              <td className="text-center border-0 text-xs font-medium lh-1 text-slate-400">
                              <div className="skeleton d-block"></div>
                              </td>
                              <td className="text-end border-0 text-xs font-medium lh-1 text-slate-400">
                              <div className="skeleton d-block"></div>
                              </td>
                            </tr>
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="mt-2 bg-white rounded p-2 shadow kutu">
                <div className="flex items-center justify-center flex-column p-3">
                  <div className="text-2xl font-medium text-green-500">
                  <div className="skeleton d-block w-full px-5"></div>
                  </div>
                  <div className="font-medium"><div className="skeleton d-block px-5 mt-2"></div></div>
                </div>

                <div className="skeleton d-block"></div>
                <div className="max-scroll">
                  <table className="table kart-table mb-0">
                    <thead>
                      <tr>
                        <th><div className="skeleton d-block"></div></th>
                        <th><div className="skeleton d-block"></div></th>
                        <th><div className="skeleton d-block"></div></th>
                        <th className="text-end"><div className="skeleton d-block"></div></th>
                      </tr>
                    </thead>
                    <tbody>
                     
                            <tr>
                              <td className="border-0 text-green-400 font-medium">
                              <div className="skeleton d-block"></div>
                              </td>
                              <td className="border-0 font-medium text-sm">
                              <div className="skeleton d-block"></div>
                              </td>
                              <td className="text-center border-0 text-xs font-medium lh-1 text-slate-400">
                              <div className="skeleton d-block"></div>
                              </td>
                              <td className="text-end border-0 text-xs font-medium lh-1 text-slate-400">
                              <div className="skeleton d-block"></div>
                              </td>
                            </tr>
                        
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="mt-2 bg-white rounded p-2 shadow kutu">
                <div className="flex items-center justify-center flex-column p-3">
                  <div className="text-2xl font-medium text-green-500">
                  <div className="skeleton d-block px-5"></div>
                  </div>
                  <div className="font-medium"><div className="skeleton d-block px-5 mt-2"></div></div>
                </div>
                <div className="skeleton d-block"></div>
                <div className="max-scroll">
                  <table className="table online-table mb-0">
                    <thead>
                      <tr>
                        <th><div className="skeleton d-block"></div></th>
                        <th><div className="skeleton d-block"></div></th>
                        <th className="text-end"><div className="skeleton d-block"></div></th>
                      </tr>
                    </thead>
                    <tbody>
                     
                            <tr >
                              <td className="border-0 text-green-400 font-medium">
                              <div className="skeleton d-block"></div>
                              </td>
                          
                              <td className=" border-0 text-xs font-medium lh-1 text-slate-400">
                              <div className="skeleton d-block"></div>
                              </td>
                              <td className="text-end border-0 text-xs font-medium lh-1 text-slate-400">
                               <div className="skeleton d-block"></div>
                              </td>
                            </tr>
                          
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BakiyeRapor;
