import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { tarih } from "../iskonto";

function BolgeSec() {
  let navigate = useNavigate();
  const [bolgeler, setBolgeler] = useState(null);
  const [selected, setSelected] = useState({
    bolge_id: localStorage.bolge_id,
    bolge_isim: localStorage.bolge_isim,
  });

  useEffect(() => {
    axios.get(`/getBolgeler/${localStorage.okul_id}`).then((res) => {
      setBolgeler(res.data);
    });
  }, []);

  return (
    <div className="screen">
      <div className="page-min ">
        <div className="bg-white in p-5 rounded shadow-sm">
          <div className="row g-2">
            <div className="col-md-6 col-12">
              <div className="font-medium lh-1 text-lg mt-3">Bölge Seç</div>
              <p className="text-slate-400 font-medium">
                Satış yapmak istediğiniz bölgeyi seçerek sistemi kullanmaya
                başlayabilirsiniz.
              </p>
            </div>

            <div className="col-md-6 col-12">
              <div className="font-medium text-sm text-center">Bölgeler</div>

              {bolgeler &&
                bolgeler.map((val) => {
                  return (
                    <div
                      onClick={(e) =>
                        setSelected({
                          bolge_id: val.bolge_id,
                          bolge_isim: val.bolge_isim,
                        })
                      }
                      key={val.bolge_id}
                      className={`flex  in rounded items-center border-2  mb-2 p-2 ${
                        selected.bolge_id == val.bolge_id
                          ? "bg-green-100 border-green-400"
                          : "bg-slate-100 hover:bg-slate-300 cursor-pointer border-slate-100 "
                      }`}
                    >
                      <div
                        className={`${
                          selected.bolge_id == val.bolge_id
                            ? "bg-green-400"
                            : "bg-slate-200"
                        }  w-6 h-6 rounded-full`}
                      ></div>
                      <div className="font-medium ms-2 user-select-none">
                        {val.bolge_isim}
                      </div>
                    </div>
                  );
                })}

              {bolgeler == null ? (
                <>
                  <div className={`p-3 rounded mb-2 skeleton w-full d-block`}></div>
                  <div className={`p-3 rounded mb-2 skeleton w-full d-block`}></div>
                  <div className={`p-3 rounded mb-2 skeleton w-full d-block`}></div>
                </>
              ) : (
                ""
              )}

              <div className="flex  justify-between">
              {localStorage.admin == "true" ? 
                <Link
                  to={"/kullanicilar"}
                  className="self-end underline italic text-sm text-gray-500"
                >
                  Bölgeleri düzenle
                </Link> : "" }

                <button
                  onClick={(e) => {
                    localStorage.bolge_id = selected.bolge_id;
                    localStorage.bolge_isim = selected.bolge_isim;
                    navigate("/");
                  }}
                  className="bg-green-400 ms-auto font-medium p-2 rounded"
                >
                  <i className="fa-solid fa-check"></i> Devam Et
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BolgeSec;