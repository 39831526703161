import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { COUNT } from "../iskonto";
import Paginate from "../components/Paginate";
import MobileHeader from "../components/MobileHeader";

export default function Ogrenciler() {
  const [ogrenciler2, setOgrenciler] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [mode, setMode] = useState(0);
  const [gruplar, setGruplar] = useState(null);
  const [tab, setTab] = useState("ogrenciler");
  const [viewType, setViewType] = useState("list");
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    getOgrenci();
    getGrup();
  }, []);

  function getGrup() {
    setGruplar(null);
    axios.get(`/getGruplist/${localStorage.okul_id}`).then((res) => {
      console.log(res);
      setGruplar(res.data);
    });
  }

  function getOgrenci(search = "", p = 0) {
    // setOgrenciler(null);
    setLoading(true);
    axios
      .get(`/getOgrenciler/${localStorage.okul_id}/?p=${p}&search=${search}`)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setOgrenciler(res.data);
      });
  }

  function addOgrenci(e) {
    e.preventDefault();

    if (isNaN(e.target[1].value)) {
      alert("TC sadece rakamlardan oluşmalıdır");
      return;
    }

    if (e.target[8].value.split(" ").join("").length != 10) {
      alert("Telefon numarası başında 5 olarak 10 haneli girilmelidir");
      return;
    }

    e.target[6].innerHTML = "<i class='fa-spinner fa-spin fa-solid'></i>";

    console.log({
      isim: e.target[0].value,
      tc: e.target[1].value,
      numara: e.target[2].value,
      rol: e.target[3].value,
      rfid: e.target[4].value,
      veli_isim: e.target[7].value,
      telefon: e.target[8].value.split(" ").join(""),

      veli2_isim: e.target[9].value,
      veli2_telefon: e.target[10].value.split(" ").join(""),
      mail: e.target[11].value,
    });

    const _e = e.target;

    axios
      .post(`/ogrenciEkle/${localStorage.okul_id}`, {
        isim: e.target[0].value,
        tc: e.target[1].value,
        numara: e.target[2].value,
        rol: e.target[3].value,
        rfid: e.target[4].value,
        veli_isim: e.target[7].value,
        telefon: e.target[8].value.split(" ").join(""),

        veli2_isim: e.target[9].value,
        veli2_telefon: e.target[10].value.split(" ").join(""),
        mail: e.target[11].value,
      })
      .then((res) => {
        if (res.data == "ok") {
          getOgrenci();
          e.target[6].innerHTML = "Ekle";
          _e.reset();
        }
      });
  }

  function addGrup(e) {
    e.preventDefault();

    e.target[3].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";

    axios
      .post("/addGrup", {
        to_okul: localStorage.okul_id,
        name: e.target[0].value,
        iskonto: e.target[1].value,
        tur: e.target[2].value,
      })
      .then((res) => {
        getGrup();
        e.target[3].innerHTML = "Ekle";
        e.target[0].value = "";
        e.target[1].value = "";
      });
  }

  function sira(e, anahtar, sayi = false) {
    e.currentTarget.querySelector("i").classList.remove("fa-sort");
    e.currentTarget.dataset.sort =
      e.currentTarget.dataset.sort == "asc" ? "desc" : "asc";
    console.log(e.currentTarget.dataset.sort);
    const c = [...ogrenciler2.ogrenciler];
    c.sort((a, b) => {
      if (sayi) {
        if (parseFloat(a[anahtar]) < parseFloat(b[anahtar])) {
          return e.currentTarget.dataset.sort == "asc" ? -1 : 1;
        }
        if (parseFloat(a[anahtar]) > parseFloat(b[anahtar])) {
          return e.currentTarget.dataset.sort == "asc" ? 1 : -1;
        }
      } else {
        if (a[anahtar] < b[anahtar]) {
          return e.currentTarget.dataset.sort == "asc" ? -1 : 1;
        }
        if (a[anahtar] > b[anahtar]) {
          return e.currentTarget.dataset.sort == "asc" ? 1 : -1;
        }
      }

      return 0;
    });

    if (e.currentTarget.dataset.sort == "desc") {
      e.currentTarget.querySelector("i").classList.remove("fa-sort-up");
      e.currentTarget.querySelector("i").classList.add("fa-sort-down");
    } else {
      e.currentTarget.querySelector("i").classList.add("fa-sort-up");
      e.currentTarget.querySelector("i").classList.remove("fa-sort-down");
    }

    setOgrenciler({
      total: ogrenciler2.total,
      ogrenciler: c,
    });
  }

  return (
    <div className="screen">
      <div className="page">
        <MobileHeader />
        {window.location.search != "?sec" ? (
          <div className="mb-2">
            <div className="shadow-sm  flex items-center bg-white rounded-2 px-2">
              <div
                onClick={(e) => {
                  setTab("ogrenciler");
                  setMode(0);
                }}
                className={`cursor-pointer ${
                  tab == "ogrenciler" ? "text-primary" : ""
                } hover:text-black  hover:bg-slate-100 py-2 flex-fill text-center font-medium`}
              >
                Öğrenciler
              </div>
              <div
                onClick={(e) => {
                  setTab("gruplar");
                  setMode(0);
                }}
                className={`cursor-pointer ${
                  tab == "gruplar" ? "text-primary" : ""
                } border-l-2 hover:bg-slate-100 py-2 flex-fill text-center font-medium`}
              >
                Gruplar
              </div>
            </div>
          </div>
        ) : (
          <div className="mb-2 bg-white rounded-2 p-2 shadow-sm">
            <div className="font-medium">Öğrenci Seçimi</div>
            <div className="font-medium text-slate-400">
              Raporunu görmek için öğrenci seçiniz
            </div>
          </div>
        )}

        {mode == 1 ? (
          <>
            <div className="bg-white rounded mb-2 shadow kutu p-2">
              <div className="font-medium">Öğrenci Ekle</div>
              <form onSubmit={(e) => addOgrenci(e)}>
                <div className="row g-2">
                  <div className="col-6">
                    <div className="row g-2">
                      <div className="col-12 ">
                        <input
                          type="text"
                          required
                          className="bg-slate-100 rounded font-medium p-2 w-full"
                          placeholder="Öğrenci İsim Soyisim*"
                        />
                      </div>
                      <div className="col-12 ">
                        <input
                          type="text"
                          required
                          maxLength={11}
                          minLength={11}
                          className="bg-slate-100 rounded font-medium p-2 w-full"
                          placeholder="Öğrenci TC Kimlik No*"
                        />
                      </div>

                      <div className="col-4 ">
                        <input
                          type="number"
                          className="bg-slate-100 rounded font-medium p-2 w-full"
                          placeholder="Okul No"
                        />
                      </div>

                      <div className="col-8 ">
                        <select className="bg-slate-100 rounded font-medium p-2 w-full">
                          {gruplar &&
                            gruplar.map((val, i) => {
                              return (
                                <option value={val.grup_id}>
                                  {val.name} ({val.iskonto}% İskonto) (
                                  {val.tur == "plus" ? "Zam" : "İndirim"})
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className="col-12">
                        <input
                          onKeyDown={(e) => {
                            if (e.code == "Enter") {
                              e.preventDefault();
                            }
                          }}
                          type="text"
                          required
                          className="bg-slate-100 rounded font-medium p-2 w-full"
                          placeholder="RFID Kart Numarası*"
                        />
                      </div>

                      <div className="col-4">
                        <button
                          onClick={() => setMode(0)}
                          type="button"
                          className="py-2 hover:bg-slate-400 bg-slate-300 w-full font-bold h-full rounded"
                        >
                          Geri
                        </button>
                      </div>

                      <div className="col-8">
                        <button
                          type="submit"
                          disabled={!gruplar}
                          className="bg-primary btn-disabled py-2 w-full font-bold text-white h-full rounded"
                        >
                          Ekle
                        </button>
                      </div>

                      <div className="col-12">
                        <div className="font-medium text-slate-400  italic">
                          *Öğrenci eklendikten sonra veliye giriş bilgileri sms
                          olarak gidecektir.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="row g-2">
                      <div className="col-6">
                        <div className=" shadow-sm bg-slate-100 flex flex-column px-2 py-3 items-center justify-center">
                          <div className="shadow-sm w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center">
                            <i className="fa-user fa-solid text-3xl text-slate-600"></i>
                          </div>

                          <div className="font-medium text-lg mt-1 text-slate-600">
                            Veli 1
                          </div>
                          <div className="font-medium  w-full">İsim*</div>
                          <input
                            type="text"
                            required
                            className="font-medium px-2 py-1 bg-white shadow-sm rounded w-100"
                          />

                          <div className="font-medium  w-full mt-2">
                            Telefon*
                          </div>
                          <InputMask
                            required
                            mask="599 999 99 99"
                            maskChar=""
                            placeholder=""
                            className="font-medium px-2 py-1 bg-white shadow-sm rounded w-100"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className=" shadow-sm bg-slate-100 flex flex-column px-2 py-3 items-center justify-center">
                          <div className="shadow-sm w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center">
                            <i className="fa-user fa-solid text-3xl text-slate-600"></i>
                          </div>

                          <div className="font-medium text-lg mt-1 text-slate-600">
                            Veli 2
                          </div>
                          <div className="font-medium  w-full">İsim</div>
                          <input
                            type="text"
                            className="font-medium px-2 py-1 bg-white shadow-sm rounded w-100"
                          />

                          <div className="font-medium  w-full mt-2">
                            Telefon
                          </div>
                          <InputMask
                            mask="599 999 99 99"
                            maskChar=""
                            placeholder=""
                            className="font-medium px-2 py-1 bg-white shadow-sm rounded w-100"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mt-2">
                          <input
                            type="email"
                            className="bg-slate-100 rounded font-medium p-2 w-full"
                            placeholder="Bilgi Epostası"
                          />
                          <div className="font-medium leading-3  mt-1 text-sm text-slate-400">
                            Veliye sipariş ve bakiye yükleme bilgilerinin
                            gönderileceği mail adresi.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>{" "}
          </>
        ) : (
          <>
            {tab == "gruplar" ? (
              <div className="bg-white rounded mb-2 shadow kutu p-2">
                <div className="font-medium mt-1">Grup Ekle</div>
                <div className="row">
                  <form onSubmit={(e) => addGrup(e)}>
                    <div className="row g-2">
                      <div className="col-12 col-sm-4">
                        <input
                          type="text"
                          required
                          className="bg-slate-100 rounded font-medium p-2 w-full"
                          placeholder="Grup İsmi"
                        />
                      </div>
                      <div className="col-12 col-sm-3">
                        <input
                          type="number"
                          required
                          className="bg-slate-100 rounded font-medium p-2 w-full"
                          placeholder="Yüzdelik İskonto"
                        />
                      </div>

                      <div className="col-12 col-sm-2">
                        <select className="bg-slate-100 rounded font-medium p-2 h-full w-full">
                          <option value="">İndirim</option>
                          <option value="plus">Zam</option>
                        </select>
                      </div>

                      <div className="col-12 col-sm-3">
                        <button
                          className="bg-primary rounded w-full p-2 font-medium button"
                          type="submit"
                        >
                          Ekle
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="max-scroll ">
                  <table className="table table-striped mt-2">
                    <thead>
                      <tr>
                        <td className="font-medium">Grup İsmi</td>
                        <td className="font-medium">İskonto Oranı</td>
                        <td className="font-medium">İskonto Tipi</td>
                      </tr>
                    </thead>
                    <tbody>
                      {gruplar &&
                        gruplar.map((val, i) => {
                          return (
                            <tr key={i}>
                              <td>{val.name}</td>
                              <td>{val.iskonto}%</td>
                              <td>
                                {val.tur == "" ? (
                                  <span className="text-green-500  font-medium">
                                    <i className="fa-solid fa-arrow-down"></i>{" "}
                                    İndirim
                                  </span>
                                ) : (
                                  <span className="text-red-500 font-medium">
                                    <i className="fa-solid fa-arrow-up"></i> Zam
                                  </span>
                                )}
                              </td>
                              {val.name != "Genel" ? (
                                <td>
                                  <button className="d-none bg-green-500 py-1 px-2 rounded mr-2">
                                    <i className="text-white fa-solid fa-pen"></i>
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.persist();
                                      if (
                                        window.confirm(
                                          "Grubu silmek istediğinize emin misiniz"
                                        )
                                      ) {
                                        e.currentTarget.innerHTML =
                                          "<i class='fa-solid fa-spin fa-spinner'></i>";
                                        axios
                                          .post(
                                            `/grupSil/${localStorage.okul_id}/${val.grup_id}/`
                                          )
                                          .then((res) => {
                                            console.log(res);
                                            console.log(e);
                                            if (res.data == "ok") {
                                              getGrup();
                                            }
                                          });
                                      }
                                    }}
                                    className="bg-red-500 py-1 px-2 rounded"
                                  >
                                    <i className="text-white fa-solid fa-trash"></i>
                                  </button>
                                </td>
                              ) : (
                                <td>
                                  <button className="  bg-green-500 py-1 px-2 rounded">
                                    <i className="text-white fa-solid fa-pen"></i>
                                  </button>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      {gruplar == null ? (
                        <>
                          <tr>
                            <td>Yükleniyor</td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="bg-white rounded shadow kutu p-2">
                <div className="row g-2 ">
                  <div className="col-12 col-sm-6">
                    <input
                      onKeyDown={(e) => {
                        if (e.code == "Enter") {
                          getOgrenci(searchString);
                        }
                      }}
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                      type="text"
                      className="bg-slate-100 rounded font-medium p-2 w-full"
                      placeholder="TC,Numara, Kart No, İsim ara"
                    />
                  </div>
                  <div
                    className={`${
                      window.location.search != "?sec"
                        ? "col-sm-1 col-6"
                        : "col-sm-4 col-6"
                    }`}
                  >
                    <button
                      onClick={(e) => {
                        getOgrenci(searchString);
                      }}
                      className="h-full bg-blue-200 hover:bg-blue-300 text-blue-400 w-full font-medium rounded"
                    >
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>

                  <div className="col-sm col-12">
                    <div className="flex">
                        <button
                  onClick={(e) => {
                    setViewType("box");
                    
                  }}
                  className={`${
                    viewType == "box" ? "filter-active" : ""
                  } bg-white hover:bg-slate-200 p-2 rounded me-2 border-2 `}
                >
                  <i className="fa-solid fa-table-cells-large"></i>
                </button>
                <button
                  onClick={(e) => {
                    setViewType("list");
                   
                  }}
                  className={`${
                    viewType == "list" ? "filter-active" : ""
                  } bg-white hover:bg-slate-200 p-2 border-2 rounded me-2`}
                >
                  <i className="fa-solid fa-list"></i>
                </button>
                    </div>
                  </div>
                
                  {window.location.search != "?sec" ? (
                    <div className="col-sm-3 col-6">
                      <button
                        onClick={() => setMode(1)}
                        className="h-full bg-green-200 hover:bg-green-300 text-green-600 py-1  w-100 font-bold  rounded"
                      >
                        <i className="fa-solid fa-user-plus"></i> Ekle
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {ogrenciler2 && ogrenciler2.length == 0 ? (
                  <div className="text-center font-bold text-primary mt-3">
                    Sonuç bulunamadı
                  </div>
                ) : (
                  ""
                )}

                {viewType == "list" ? (
                  <div>
                    {ogrenciler2 ? (
                      <>
                        {window.location.search == "?sec" ? (
                          <div className="text-xl text-center font-medium text-green-500 my-2">
                            Raporu görmek için öğrenci seçiniz
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="max-scroll ">
                          <table className=" mt-2 table">
                            <thead>
                              <tr>
                                <th
                                  data-sort="asc"
                                  className="cursor-pointer"
                                  onClick={(e) => {
                                    sira(e, "isim");
                                  }}
                                >
                                  İsim <i className="fa-solid fa-sort"></i>
                                </th>
                                <th
                                  data-sort="asc"
                                  className="cursor-pointer"
                                  onClick={(e) => {
                                    sira(e, "bakiye", true);
                                  }}
                                >
                                  Bakiye <i className="fa-solid fa-sort"></i>
                                </th>
                                <th>TC</th>
                                <th data-sort="asc">Okul No </th>
                                <th>Veli Tel</th>
                                <th>Kart No</th>
                                {window.location.search == "?sec" ? (
                                  <th></th>
                                ) : (
                                  ""
                                )}
                              </tr>
                            </thead>

                            {loading ? (
                              <tbody>
                                <tr>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                  <td className="border-0">
                                    <div className="skeleton d-block"></div>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {ogrenciler2.ogrenciler.map((val, i) => {
                                  return (
                                    <tr
                                      onClick={(e) => {
                                        if (window.location.search == "?sec") {
                                          navigate(
                                            `/ogrenci-rapor/${val.ogrenci_id}`
                                          );
                                        } else {
                                          navigate(
                                            `/ogrenci/${val.ogrenci_id}`
                                          );
                                        }
                                      }}
                                      className="cursor-pointer hover:bg-slate-100"
                                      key={val.ogrenci_id}
                                    >
                                      <td className="font-medium border-0">
                                        {val.isim}
                                      </td>
                                      <td className="font-medium text-green-400 border-0">
                                        {val.bakiye}
                                      </td>
                                      <td className="font-medium  text-sm border-0">
                                        {val.tc}
                                      </td>
                                      <td className="border-0 font-medium text-slate-400 text-sm">
                                        {val.numara ? val.numara : "Veri Yok"}
                                      </td>
                                      <td className="border-0 font-medium text-slate-400 text-sm italic">
                                        {val.telefon ? val.telefon : "Veri Yok"}
                                      </td>
                                      <td className="font-medium text-slate-400 text-sm border-0">
                                        {val.rfid}
                                      </td>

                                      {window.location.search == "?sec" ? (
                                        <td className=" border-0  ">
                                          <button className="w-full bg-green-500 hover:bg-green-500 rounded font-medium lh-1 p-2">
                                            <i className="mr-1 fa-regular fa-circle-dot"></i>{" "}
                                            Seç
                                          </button>
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )}
                          </table>
                        </div>
                        <Paginate
                          className="text-end"
                          changeCallback={(e) => {
                            getOgrenci("", e);
                          }}
                          total={ogrenciler2.total}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="row g-2 mt-1">
                    {ogrenciler2 &&
                      ogrenciler2.ogrenciler.map((val, i) => {
                        return (
                          <div
                            key={i}
                            className="col-12 col-sm-6 col-md-4"
                            onClick={(e) => {
                              if (window.location.search == "?sec") {
                                navigate(`/ogrenci-rapor/${val.ogrenci_id}`);
                              } else {
                                navigate(`/ogrenci/${val.ogrenci_id}`);
                              }
                            }}
                          >
                            <div className="rounded h-full position-relative cursor-pointer hover:bg-slate-200 bg-slate-50 p-2">
                              <div className="flex justify-center">
                                {val.resim != "" ? (
                                  <img
                                    loading="lazy"
                                    src={val.resim}
                                    className="w-24 h-24 object-cover rounded-full"
                                    alt={val.isim}
                                  />
                                ) : (
                                  <div className="img-fluid rounded-full w-24 h-24 object-cover bg-slate-300 flex items-center select-none	 justify-center text-[3rem] font-bold text-white w-full">
                                    {val.isim.split("")[0].toUpperCase()}
                                  </div>
                                )}
                              </div>
                              <div className="font-bold mt-2 lh-1 text-slate-400 text-sm text-center text-primary">
                                {val.numara}
                              </div>

                              <div className="font-medium  text-center">
                                {val.isim}
                              </div>
                              <div className="font-medium lh-1 text-slate-400 text-sm text-center">
                                {val.tc}
                              </div>

                              {window.location.search == "?sec" ? (
                                <button className="mx-auto mt-2 flex items-center p-1 rounded bg-green-400 hover:bg-green-500 font-medium lh-1 p-2">
                                  <i className="mr-1 fa-regular fa-circle-dot"></i>{" "}
                                  Seç
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })}

                    {ogrenciler2 ? (
                      <Paginate
                        className="text-end"
                        changeCallback={(e) => {
                          getOgrenci("", e);
                        }}
                        total={ogrenciler2.total}
                      />
                    ) : (
                      ""
                    )}

                    {ogrenciler2 == null ? (
                      <>
                        <div className="col-4">
                          <div className="rounded position-relative cursor-pointer  bg-slate-50 p-2">
                            <div className="flex justify-center mb-1">
                              <div className="skeleton w-24 h-24-important  rounded-full "></div>
                            </div>
                            <div className="w-25 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                            <div className="w-75 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                            <div className="w-75 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="rounded position-relative cursor-pointer  bg-slate-50 p-2">
                            <div className="flex justify-center mb-1">
                              <div className="skeleton w-24 h-24-important  rounded-full "></div>
                            </div>
                            <div className="w-25 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                            <div className="w-75 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                            <div className="w-75 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="rounded position-relative cursor-pointer  bg-slate-50 p-2">
                            <div className="flex justify-center mb-1">
                              <div className="skeleton w-24 h-24-important  rounded-full "></div>
                            </div>
                            <div className="w-25 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                            <div className="w-75 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                            <div className="w-75 mx-auto d-block mb-1 text-center skeleton br-8"></div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
