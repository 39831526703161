import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { COUNT } from "../iskonto";

function Paginate({ changeCallback, total,className }) {
  const [page, setPage] = useState(0);
  const _total = Math.ceil(total / COUNT);
  useEffect(() => {
    changeCallback(page);
  }, [page]);
  return (
    <div className={`${className}`}>
      <select
        className={`p-2 rounded font-medium bg-slate-100 `}
        value={page / COUNT}
        onChange={(e) => {
         console.log((COUNT * Number(e.target.value)) / COUNT)
          setPage((COUNT * Number(e.target.value))  ); 
        }}
      >
        {[...Array(_total).keys()].map((val, i) => {
          return <option key={i} value={val}>{val + 1}</option>;
        })}
      </select>
       
      
  
    </div>
  );
}

export default Paginate;
