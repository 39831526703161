import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {  tarih } from "../../iskonto";
import axios from "axios";
import MobileHeader from "../../components/MobileHeader";

function ZList() {

  const [data, setData] = useState(null);

  const saat = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  useEffect(() => {
    axios
      .get(
        `/all_z/${
          localStorage.admin == "true"
            ? localStorage.okul_id
            : localStorage.user_id
        }/${localStorage.okul_id}/?admin=${localStorage.admin}`
      )
      .then((res) => {
        setData(res.data);
        console.log(res);
      });
  }, []);

  function getZ(e) {
    e.preventDefault();

    if (!window.confirm("Z Raporunu almak istediğinize emin misiniz")) {
      return;
    }
    e.target[1].innerHTML = "<i class='fa-spin fa-solid  fa-spinner'></i>";
    axios
      .post(`/sifrele/`, {
        data: `${
          localStorage.admin == "true"
            ? localStorage.okul_id
            : localStorage.user_id
        }@${e.target[0].value}@false`,
      })
      .then((res) => {
        e.target[1].innerHTML = '<i class="fa-solid fa-magnifying-glass "></i>';
        window
          .open(
            axios.defaults.baseURL +
              `z?admin=${localStorage.admin}&payload=` +
              res.data,
            "_blank"
          )
          .focus();

        axios
          .get(
            `/all_z/${
              localStorage.admin == "true"
                ? localStorage.okul_id
                : localStorage.user_id
            }/?admin=${localStorage.admin}`
          )
          .then((res) => {
            setData(res.data);
            console.log(res);
          });
      });
  }

  return (
    <div className="screen">
      <div className="page">
      <MobileHeader/>
        <div className="mt-2 ">
          {data ? (
            <>
              <div className="row g-2">
                <div className="col-12">
                  <div className="bg-white rounded p-2 shadow kutu">
                    <div className="font-medium text-lg text-center ">
                      Z Raporu
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div>
                    <div className="bg-white rounded p-2 flex justify-center items-center flex-column shadow kutu">
                      <div className="font-medium text-center mb-1">
                        Z Raporu Al
                      </div>

                      <form onSubmit={(e) => getZ(e)}>
                        <input
                          type="date"
                          defaultValue={new Date().toLocaleDateString("en-CA")}
                          max={new Date().toLocaleDateString("en-CA")}
                          className="bg-slate-100 rounded p-2 border-l-5"
                        />
                        <button
                          type="submit"
                          className="bg-primary px-4  py-2 ms-2 rounded "
                        >
                          <i className="fa-solid fa-magnifying-glass "></i>{" "}
                        </button>
                      </form>
                    </div>

                    {data && data.ayar[0] ? (
                      <div
                        className={`mt-2 bg-white rounded p-2  shadow kutu ${
                          localStorage.admin == "false" ? "just-admin" : ""
                        }`}
                      >
                        <div className="flex items-center justify-between mb-1">
                          <div className="font-medium ">Otomatik Z Raporu</div>

                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              title="Aç/Kapa"
                              defaultChecked={
                                data.ayar[0].z_acik == 1 ? true : false
                              }
                              onChange={(e) => {
                                axios
                                  .post(
                                    `/update/okul_ayar/to_okul/${
                                      localStorage.okul_id
                                    }/?str=z_acik = ${
                                      e.target.checked ? "1" : "0"
                                    } `
                                  )
                                  .then((res) => {});

                                document
                                  .querySelectorAll(".dthis")
                                  .forEach((el) => {
                                    if (e.target.checked) {
                                      el.classList.remove("e-disabled");
                                    } else {
                                      el.classList.add("e-disabled");
                                    }
                                  });
                              }}
                            />
                          </div>
                        </div>

                        <div
                          className={`${
                            data.ayar[0].z_acik == 0 ? "e-disabled" : ""
                          } font-medium text-slate-400 lh-1  dthis`}
                        >
                          Z Raporunun günlük olarak hangi saatte ve hangi
                          e-postaya gönderileceğini seçin.
                        </div>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            e.target[2].innerHTML =
                              "<i class='fa-solid fa-spin fa-spinner'></i>";

                            axios
                              .post(
                                `/updatePost/okul_ayar/to_okul/${localStorage.okul_id}/`,
                                {
                                  z_saat: e.target[0].value,
                                  z_mail: e.target[1].value,
                                }
                              )
                              .then((res) => {
                                e.target[2].innerHTML = "Kaydet";
                              });
                          }}
                          className={`${
                            data.ayar[0].z_acik == 0 ? "e-disabled" : ""
                          } flex mt-2 dthis`}
                        >
                          <select defaultValue={data.ayar[0].z_saat} className="bg-slate-100 rounded p-2 ">
                            {saat.map((val, i) => {
                              return (
                                <option
                                 
                                  key={i}
                                  value={val + ":00"}
                                >
                                  {val}:00
                                </option>
                              );
                            })}
                          </select>

                          <input
                            defaultValue={data.ayar[0].z_mail}
                            type="text"
                            placeholder="E-Posta"
                            className="ms-2 flex-fill bg-slate-100 rounded p-2 border-l-5"
                          />
                          <button
                            type="submit"
                            className="bg-blue-400 font-medium p-2 ms-2 rounded "
                          >
                            Kaydet
                          </button>
                        </form>
                        <div
                          className={`${
                            data.ayar[0].z_acik == 0 ? "e-disabled" : ""
                          } italic text-sm mt-2 font-medium text-gray-600 dthis`}
                        >
                          Birden fazla epostaya gönderim için virgülle ayırınız.
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-6">
                  <div className="bg-white rounded p-2 shadow kutu">
                    <div className="font-medium text-center mb-1">
                      Geçmiş Z Raporları
                    </div>

                    {data.data.map((val, i) => {
                      return (
                        <div
                          key={i}
                          onClick={(e) => {
                            e.target.style.opacity = "0.7";
                            axios
                              .post(`/sifrele/`, {
                                data: `${localStorage.okul_id}@${
                                  new Date(val.tarih).getFullYear() +
                                  "-" +
                                  (new Date(val.tarih).getUTCMonth() + 1) +
                                  "-" +
                                  new Date(val.tarih).getUTCDate()
                                }@${val.z}`,
                              })
                              .then((res) => {
                                e.target.style.opacity = "1";

                                window
                                  .open(
                                    axios.defaults.baseURL +
                                      `z?admin=${localStorage.admin}&payload=` +
                                      res.data,
                                    "_blank"
                                  )
                                  .focus();
                              });
                          }}
                          target="_blank"
                          className={`${
                            i == data.length - 1 ? "rounded-bottom" : ""
                          } ${i == 0 ? " rounded-top " : ""} ${
                            i % 2 == 0 ? " bg-slate-200 " : " bg-slate-100 "
                          } flex  p-2 justify-between cursor-pointer hover:bg-slate-300 items-center`}
                        >
                          <div className="font-medium">{tarih(val.tarih)}</div>
                          <div className="text-green-600 italic font-medium">
                            {val.toplam} <span className="fiyat-text">₺</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row g-2">
                <div className="col-12">
                  <div className="bg-white rounded p-2 shadow kutu">
                    <div className="font-medium text-lg text-center ">
                      Z Raporu
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="bg-white rounded p-2 flex justify-center items-center flex-column shadow kutu">
                    <div className="font-medium text-center mb-1">
                      Z Raporu Al
                    </div>

                    <form onSubmit={(e) => getZ(e)}>
                      <input
                        type="date"
                        defaultValue={new Date().toLocaleDateString("en-CA")}
                        max={new Date().toLocaleDateString("en-CA")}
                        className="bg-slate-100 rounded p-2 border-l-5"
                      />
                      <button
                        type="submit"
                        className="bg-primary px-4  py-2 ms-2 rounded "
                      >
                        <i className="fa-solid fa-magnifying-glass "></i>{" "}
                      </button>
                    </form>
                  </div>
                </div>

                <div className="col-6">
                  <div className="bg-white rounded p-2 shadow kutu">
                    <div className="font-medium text-center mb-1">
                      Geçmiş Z Raporları
                    </div>

                    <div className="skeleton d-block mb-2"></div>
                    <div className="skeleton d-block mb-2"></div>
                    <div className="skeleton d-block mb-2"></div>
                    <div className="skeleton d-block mb-2"></div>
                    <div className="skeleton d-block mb-2"></div>
                    <div className="skeleton d-block mb-2"></div>
                    <div className="skeleton d-block mb-2"></div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ZList;
