import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MobileHeader from "../components/MobileHeader";

function OgrenciDetay() {
  let params = useParams();
  let navigate = useNavigate();
  const [data, setData] = useState(null);
  const [gruplar, setGruplar] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    axios.get(`/getOgrenciID/${params.id}`).then((res) => {
      console.log(res);
      setData(res.data);
    });

    getGrup();
  }, []);

  function getGrup() {
    setGruplar(null);
    axios.get(`/getGruplist/${localStorage.okul_id}`).then((res) => {
      console.log(res);

      setGruplar(res.data);
    });
  }

  function edit(e) {
    e.preventDefault();
    e.target[10].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";
    e.target[10].setAttribute("disabled", "true");
    const formData = new FormData(e.currentTarget);

    const formDataObj = {};
    formData.forEach((value, key) => (formDataObj[key] = value));
    console.log(formDataObj);

    console.log(e);
    axios.post(`/editOgrenci/${params.id}`, formDataObj).then((res) => {
      console.log(res);
      if (res.data == "ok") {
        e.target[10].removeAttribute("disabled");
        e.target[10].innerHTML = "<i class='fa-solid fa-check'></i>";
      }
    });
  }

  return (
    <div className="screen">
      <div className="page">
        <MobileHeader />
        <div className="row g-2">
         
          {editMode ? (
            <div className="col-6">
              <div className="bg-white rounded shadow-sm p-2 kutu">
                <div className="font-medium">
                  <i className="fa-solid fa-user-pen"></i> Bilgileri Düzenle
                </div>
                <div className="font-medium text-slate-400 leading-3 mb-3 ">
                  <i className="fa-solid fa-user-pen text-white"></i> Öğrencinin
                  bilgilerini düzenleyin
                </div>
                {data != null ? (
                  <form onSubmit={(e) => edit(e)}>
                    <div className="row g-2">
                      <div className="col-6">
                        <div className="font-medium text-sm">İsim Soyisim</div>
                        <div>
                          <input
                            name="isim"
                            type="text"
                            defaultValue={data.isim}
                            className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                          />
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="font-medium text-sm">TC</div>
                        <div>
                          <input
                            type="text"
                            name="tc"
                            defaultValue={data.tc}
                            className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                          />
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="font-medium text-sm">Okul No</div>
                        <div>
                          <input
                            type="text"
                            name="numara"
                            defaultValue={data.numara}
                            className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                          />
                        </div>
                      </div>

                      <div className="col-8">
                        <div className="font-medium text-sm">Grup</div>
                        <div>
                          <select
                            defaultValue={data.grup}
                            name="grup"
                            className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                          >
                            {gruplar &&
                              gruplar.map((val, i) => {
                                return (
                                  <option
                                    value={val.grup_id}
                                    key={val.grup_id}
                                  >{`${val.name} (%${val.iskonto})`}</option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="mt-4 shadow-sm bg-slate-100 flex flex-column px-2 py-3 items-center justify-center">
                          <div className="shadow-sm w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center">
                            <i className="fa-user fa-solid text-3xl text-slate-600"></i>
                          </div>

                          <div className="font-medium text-lg mt-1 text-slate-600">
                            Veli 1
                          </div>
                          <div className="font-medium  w-full">İsim</div>
                          <input
                            type="text"
                            name="veli_isim"
                            defaultValue={data.veli_isim}
                            className="font-medium px-2 py-1 bg-white shadow-sm rounded w-100"
                          />

                          <div className="font-medium  w-full mt-2">
                            Telefon
                          </div>
                          <input
                            type="text"
                            name="telefon"
                            defaultValue={data.telefon}
                            className="font-medium px-2 py-1 bg-white shadow-sm rounded w-100"
                          />
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="mt-4 shadow-sm  bg-slate-100 flex flex-column px-2 py-3 items-center justify-center">
                          <div className="shadow-sm w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center">
                            <i className="fa-user fa-solid text-3xl text-slate-600"></i>
                          </div>

                          <div className="font-medium text-lg mt-1 text-slate-600">
                            Veli 2
                          </div>
                          <div className="font-medium  w-full">İsim</div>
                          <input
                            type="text"
                            name="veli2_isim"
                            defaultValue={data.veli2_isim}
                            className="font-medium px-2 py-1 bg-white shadow-sm rounded w-100"
                          />

                          <div className="font-medium  w-full mt-2">
                            Telefon
                          </div>
                          <input
                            type="text"
                            name="veli2_telefon"
                            defaultValue={data.veli2_telefon}
                            className="font-medium px-2 py-1 bg-white shadow-sm rounded w-100"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="font-medium mt-3">Bilgi Maili</div>
                        <div>
                          <input
                            type="email"
                            name="mail"
                            defaultValue={data.mail}
                            className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                          />
                        </div>
                        <div className="font-medium leading-3  mt-1 text-sm text-slate-400">
                          Veliye sipariş ve bakiye yükleme bilgilerinin
                          gönderileceği mail adresi.
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="font-medium mt-3">
                          RFID Kart Numarası
                        </div>
                        <div>
                          <input
                            type="text"
                            name="rfid"
                            defaultValue={data.rfid}
                            className="font-medium px-2 py-1 bg-slate-100 rounded w-100"
                          />
                        </div>
                      </div>

                      <div className="col-8">
                        <button
                          type="submit"
                          className="btn-disabled hover:bg-green-600 bg-green-500 text-white font-medium rounded p-1 w-100"
                        >
                          <i className="fa-solid fa-file-export"></i> Kaydet
                        </button>
                      </div>
                      <div className="col-4">
                        <button
                          type="button"
                          onClick={(e) => {
                            e.currentTarget.innerHTML =
                              "<i class='fa-solid fa-spin fa-spinner'></i>";
                            axios
                              .get(`/getOgrenciID/${params.id}`)
                              .then((res) => {
                                console.log(res);
                                setData(res.data);
                                setEditMode(false);
                              });
                          }}
                          className="btn-disabled hover:bg-slate-600 bg-slate-500 text-white font-medium rounded p-1 w-100"
                        >
                          <i className="fa-solid fa-cancel"></i> İptal
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : data ? (
            <div className="col-6">
              <div className="bg-white rounded px-4 py-2 shadow-sm">
                <div className="flex">
                  <div className="px-4 pt-1">
                    <i class="text-5xl text-blue-500 fa-solid fa-user-graduate"></i>
                  </div>
                  <div className="flex-fill">
                    <div className="font-medium text-blue-500">
                      Öğrenci Bilgileri
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="font-medium">İsim Soyisim:</div>
                      <div className="font-medium text-slate-600">
                        {data.isim}
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="font-medium">TC Kimlik No:</div>
                      <div className="font-medium text-slate-600">
                        {data.tc}
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="font-medium">Okul Numarası</div>
                      <div className="font-medium text-slate-600">
                        {data.numara}
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="font-medium">Kart Numarası</div>
                      <div className="font-medium text-slate-600">
                        {data.rfid}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="font-medium">Bilgi Maili</div>
                      <div className="font-medium text-slate-600">
                        {data.mail}
                      </div>
                    </div>
                  </div>
                </div>


                <div className="flex mt-3">
                  <div className="px-4 pt-1">
                    <i class="text-5xl text-blue-500 fa-solid fa-user"></i>
                  </div>
                  <div className="flex-fill">
                    <div className="font-medium text-blue-500">
                      Veli 1 Bilgileri
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="font-medium">İsim Soyisim:</div>
                      <div className="font-medium text-slate-600">
                        {data.veli_isim}
                      </div>
                    </div>

               
                    <div className="flex items-center justify-between">
                      <div className="font-medium">Telefon No</div>
                      <div className="font-medium text-slate-600">
                        {data.telefon}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex mt-3">
                  <div className="px-4 pt-1">
                    <i class="text-5xl text-blue-500 fa-solid fa-user"></i>
                  </div>
                  <div    className="flex-fill">
                    <div className="font-medium text-blue-500">
                      Veli 2 Bilgileri
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="font-medium">İsim Soyisim:</div>
                      <div className="font-medium text-slate-600">
                        {data.veli2_isim}
                      </div>
                    </div>

               
                    <div className="flex items-center justify-between">
                      <div className="font-medium">Telefon No</div>
                      <div className="font-medium text-slate-600">
                        {data.veli2_telefon}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex mt-3">
                  <div className="px-4 pt-1">
                    <i class="text-5xl text-blue-500 fa-solid fa-coins"></i>
                  </div>
                  <div    className="flex-fill">
                    <div className="font-medium text-blue-500">
                      Finans
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="font-medium">Bakiye</div>
                      <div className="font-medium text-slate-600">
                        {data.bakiye}
                      </div>
                    </div>

               
                    <div className="flex items-center justify-between">
                      <div className="font-medium">Günlük Limit</div>
                      <div className="font-medium text-slate-600">
                        {data.gunluk_limit}
                      </div>
                    </div>
                  </div>
                </div>

                  <div className="row g-2">
                    <div className="col-7">
                    <button
                    onClick={() => {
                      setEditMode(true);
                    }}
                    className="hover:bg-amber-300 mt-3 bg-amber-200 text-amber-600 p-1 font-medium w-full rounded"
                  >
                    <i className="fa-solid fa-pen"></i> Bilgileri Düzenle
                  </button>
                    </div>
                    <div className="col-5">
                    <button
                    onClick={(e) => {
                      if(window.confirm("Veli 1 ve Veli 2'ye giriş bilgisi gönder?")){
                        axios.post(`/girisGonder/${params.id}`).then(res=>{
                          console.log(res.data)
                          if(res.data.status.code == "451"){
                            alert("Zaten gönderildi.")
                          }else{
                            alert("Gönderildi")
                            e.target.innerHTML = "<i class='fa-solid fa-check'></i>"
                          }
                         
                        })
                      }
                    }}
                    className="hover:bg-green-300 mt-3 bg-green-200  text-green-600 p-1 font-medium w-full rounded"
                  >
                    <i className="fa-solid fa-sms"></i> Giriş Bilgisi
                  </button>
                    </div>
                  </div>
              </div>
            </div>
          ) : (
            ""
          )}

           <div className="col-6">
            <div>
              <div className="bg-white rounded shadow-sm p-2 kutu">
                {data != null ? (
                  <>
                    <div className="flex justify-center">
                      {data.resim != "" ? (
                        <img
                          loading="lazy"
                          src={
                            data.resim != ""
                              ? data.resim
                              : axios.defaults.base + "assets/img/profil.jpg"
                          }
                          className="w-36 h-36 object-cover rounded-full"
                          alt=""
                        />
                      ) : (
                        <div className="img-fluid rounded-full h-36 w-36  object-cover bg-slate-300 flex items-center select-none	 justify-center text-[8rem] font-bold text-white w-full">
                          {data.isim.split("")[0].toUpperCase()}
                        </div>
                      )}
                    </div>
                    <div className="font-medium text-center text-lg">
                      {data.isim}
                    </div>
                    <div className="font-medium text-center text-lg  fiyat-text text-green-600">
                      {data.bakiye} ₺
                    </div>
                    <iframe
                      id="ifr"
                      src={`${
                        axios.defaults.baseURL +
                        "resim/" +
                        params.id +
                        "/?type=ogrenci"
                      }`}
                      height="50"
                    ></iframe>{" "}
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="row mt-2 g-2">
                <div className="col-4">
                  <Link
                    to={`/ogrenci-rapor/${params.id}`}
                    className="d-block text-center hover:bg-green-600 bg-green-500 text-white font-medium rounded p-1 w-100"
                  >
                    <i className="fa-solid fa-chart-line"></i> Rapor
                  </Link>
                </div>

                <div className="col-4">
                  <button
                    onClick={() => {
                      setEditMode(true);
                    }}
                    className="hover:bg-amber-400 bg-amber-300 p-1 font-medium w-full rounded"
                  >
                    <i className="fa-solid fa-pen"></i> Düzenle
                  </button>
                </div>

                <div className="col-4">
                  <button
                    className="btn-disabled hover:bg-red-600 bg-red-500 text-white font-medium rounded p-1 w-100"
                    onClick={(e) => {
                      if (
                        window.confirm(
                          "Bu öğrenciyi silmek istediğiniz emin misiniz"
                        )
                      ) {
                        e.target.innerHTML =
                          "<i class='fa-solid fa-spin fa-spinner'></i>";
                        e.target.setAttribute("disabled", "true");
                        axios
                          .post(`/delete/ogrenci/ogrenci_id/${params.id}`)
                          .then((res) => {
                            console.log(res);
                            if (res.data == "ok") {
                              navigate("/ogrenciler");
                            }
                          });
                      }
                    }}
                  >
                    <i className="fa-solid fa-trash"></i> Sil
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default OgrenciDetay;
