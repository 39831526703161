import React from 'react'
import { tarih } from '../iskonto';

function Hareket({durum,data,date}) {
    const veri = data.split("|");
    
// Satış
  if(durum == "satis"){
    return (
        <div className='flex bg-slate-50 rounded p-2 mb-2'>
            <div className="text-green-400 bg-green-100 w-16 h-16 flex items-center justify-center p-3 rounded-full text-3xl "><i className="fa-solid fa-coins"></i></div>
            <div className='ms-3'>
                <div className="font-medium text-lg text-green-500">Satış Yapıldı : {veri[2]} <span className='fiyat-text'>₺</span></div>
                <div className='font-medium lh-1'>Öğrenci: <span className='text-slate-600'>{veri[1]}</span></div>
                <div className='font-medium lh-1'>Ödeme Türü: <span className='text-slate-600'>{veri[3]}</span></div>
            </div>

            <div className="text-end ms-auto font-medium">
                <div>{veri[0]}</div>
                <div className='text-sm lh-1 text-gray-600'>{tarih(date)}</div>
            </div>
        </div>
      )
  }

  if(durum == "iade"){
    return (
        <div className='flex bg-slate-50 rounded p-2 mb-2'>
            <div className="text-yellow-400 bg-yellow-100 w-16 h-16 flex items-center justify-center p-3 rounded-full text-3xl "><i className="fa-solid fa-backward"></i></div>
            <div className='ms-3'>
                <div className="font-medium text-lg text-yellow-500">İade Yapıldı : {veri[3]} adet {veri[4]} ({ veri[2] }  <span className='fiyat-text'>₺)</span></div>
                <div className='font-medium lh-1'>Öğrenci: <span className='text-slate-600'>{veri[1]}</span></div>
            </div>

            <div className="text-end ms-auto font-medium">
                <div>{veri[0]}</div>
                <div className='text-sm lh-1 text-gray-600'>{tarih(date)}</div>
            </div>
        </div>
      )
  }

  if(durum == "bakiye_yukleme"){
    return (
        <div className='flex bg-slate-50 rounded p-2 mb-2'>
            <div className="text-lime-400 bg-lime-100 w-16 h-16 flex items-center justify-center p-3 rounded-full text-3xl "><i className="fa-solid fa-money-bill-trend-up"></i></div>
            <div className='ms-3'>
                <div className="font-medium text-lg text-lime-500">Bakiye Yüklendi : {veri[2]} <span className='fiyat-text'>₺</span></div>
                <div className='font-medium lh-1'>Öğrenci: <span className='text-slate-600'>{veri[1]}</span></div>
                <div className='font-medium lh-1'>Ödeme Tipi: <span className='text-slate-600'>{veri[3]}</span></div>
            </div>

            <div className="text-end ms-auto font-medium">
                <div>{veri[0]}</div>
                <div className='text-sm lh-1 text-gray-600'>{tarih(date)}</div>
            </div>
        </div>
      )
  }

  if(durum == "urun_ekle"){
    return (
        <div className='flex bg-slate-50 rounded p-2 mb-2'>
            <div className="text-emerald-400 bg-emerald-100 w-16 h-16 flex items-center justify-center p-3 rounded-full text-3xl "><i className="fa-solid fa-plus"></i></div>
            <div className='ms-3'>
                <div className="font-medium text-lg text-emerald-500">Ürün Eklendi </div>
                <div className='font-medium lh-1'>Eklenen Ürün: <span className='text-slate-600'>{veri[1]}</span></div>

            </div>

            <div className="text-end ms-auto font-medium">
                <div>{veri[0]}</div>
                <div className='text-sm lh-1 text-gray-600'>{tarih(date)}</div>
            </div>
        </div>
      )
  }

  
  if(durum == "urun_sil"){
    return (
        <div className='flex bg-slate-50 rounded p-2 mb-2'>
            <div className="text-red-400 bg-red-100 w-16 h-16 flex items-center justify-center p-3 rounded-full text-3xl "><i className="fa-solid fa-trash"></i></div>
            <div className='ms-3'>
                <div className="font-medium text-lg text-red-500">Ürün Silindi </div>
                <div className='font-medium lh-1'>Silinen Ürün: <span className='text-slate-600'>{veri[1]}</span></div>

            </div>

            <div className="text-end ms-auto font-medium">
                <div>{veri[0]}</div>
                <div className='text-sm lh-1 text-gray-600'>{tarih(date)}</div>
            </div>
        </div>
      )
  }

    
  if(durum == "urun_duzenle"){
    return (
        <div className='flex bg-slate-50 rounded p-2 mb-2'>
            <div className="text-amber-400 bg-amber-100 w-16 h-16 flex items-center justify-center p-3 rounded-full text-3xl "><i className="fa-solid fa-pen"></i></div>
            <div className='ms-3'>
                <div className="font-medium text-lg text-amber-500">Ürün Düzenlendi </div>
                <div className='font-medium lh-1'>Düzenlenen Ürün: <span className='text-slate-600'>{veri[1]}</span></div>

            </div>

            <div className="text-end ms-auto font-medium">
                <div>{veri[0]}</div>
                <div className='text-sm lh-1 text-gray-600'>{tarih(date)}</div>
            </div>
        </div>
      )
  }

  if(durum == "stok_giris"){
    return (
        <div className='flex bg-slate-50 rounded p-2 mb-2'>
            <div className="text-lime-400 bg-lime-100 w-16 h-16 flex items-center justify-center p-3 rounded-full text-3xl "><i className="fa-solid fa-boxes-stacked"></i></div>
            <div className='ms-3'>
                <div className="font-medium text-lg text-lime-500">Stok Girişi Yapıldı </div>
                <div className='font-medium lh-1'>Belge No: <span className='text-slate-600'>{veri[1]}</span></div>

            </div>

            <div className="text-end ms-auto font-medium">
                <div>{veri[0]}</div>
                <div className='text-sm lh-1 text-gray-600'>{tarih(date)}</div>
            </div>
        </div>
      )
  }

  if(durum == "tedarikci_ekle"){
    return (
        <div className='flex bg-slate-50 rounded p-2 mb-2'>
            <div className="text-lime-400 bg-lime-100 w-16 h-16 flex items-center justify-center p-3 rounded-full text-3xl "><i className="fa-solid  fa-person-circle-plus"></i></div>
            <div className='ms-3'>
                <div className="font-medium text-lg text-lime-500">Tedarikçi Eklendi </div>
                <div className='font-medium lh-1'>Eklenen Tedarikçi: <span className='text-slate-600'>{veri[1]}</span></div>

            </div>

            <div className="text-end ms-auto font-medium">
                <div>{veri[0]}</div>
                <div className='text-sm lh-1 text-gray-600'>{tarih(date)}</div>
            </div>
        </div>
      )
  }

  if(durum == "login"){
    return (
        <div className='flex bg-slate-50 rounded p-2 mb-2'>
            <div className="text-lime-400 bg-lime-100 w-16 h-16 flex items-center justify-center p-3 rounded-full text-3xl "><i className="fa-solid   fa-right-to-bracket"></i></div>
            <div className='ms-3'>
                <div className="font-medium text-lg text-lime-500">Sisteme Giriş Yapıldı </div>

            </div>

            <div className="text-end ms-auto font-medium">
                <div>{veri[0]}</div>
                <div className='text-sm lh-1 text-gray-600'>{tarih(date)}</div>
            </div>
        </div>
      )
  }
    
}

export default Hareket
/*
>> Sipariş İade
>> Bakiye yükleme
>> Satış
>> Ürün Ekle
>> Ürün Sil
>> Ürün Düzenle
>> Stok Giriş
Tedarikçi ekle
Login
*/