import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip"
import IframeResizer from "iframe-resizer-react";
import {tarih} from "../iskonto"
import { Link, useNavigate } from "react-router-dom";
function VeliIndex() {
  const [user, setUser] = useState(null);
  const [bakiyeForm, setBakiyeForm] = useState(false);
  const [miktar, setMiktar] = useState(0)
  const [token, setToken] = useState(null)
  const [siparisler, setSiparisler] = useState(null)
  const [viewType, setViewType] = useState("box");
  const [okul, setOkul] = useState(null)
  const [yardımMode, setYardımMode] = useState(false)

  let navigate = useNavigate()

  useEffect(()=>{
   
    axios.get(`/getUser/${localStorage.veli_id}`).then((res) => {
      console.log(res.data);
      setUser(res.data);

    })
  },[viewType])
  useEffect(() => {

    axios.get(`/getUser/${localStorage.veli_id}`).then((res) => {
      if(res.data[0].first == "1"){
        navigate("/ayarlar")
      }
      setUser(res.data);
        var _ids = []
        res.data.yemekler.map(val=>_ids.push(val.urun_id))

      axios.get(`/ogrenciSiparisler/${res.data[0].ogrenci_id}`).then(r2=>{
        console.log(r2)
        setSiparisler(r2.data)
      })

      axios.get(`/hesap/${res.data[0].to_okul}`).then(r3=>{
        console.log(r3)
        setOkul(r3.data.data[0])
      })
    });

 
  }, []);

  function setKabul2(e, kabul, id) {
    e.stopPropagation();

    
    const c = e.currentTarget
    if (
      !c.classList.contains("opacity-50") 
    ) {
      c.classList.add("opacity-50");
    
      axios
        .post(`/setKabul/${id}`, {
          kabul: c.dataset.kabul == 1 ? 0 : 1,
        })
        .then((res) => {
          c.classList.remove("opacity-50");
          
          if (res.data == 0) {
            c.dataset.kabul = 0;
        
            c.classList.add("bg-red-200")
            c.classList.add("text-red-700")
            c.classList.remove("bg-green-200")
            c.classList.remove("text-green-700")
          }
          if (res.data == 1) {
            c.dataset.kabul = 1;
      
            c.classList.remove("bg-red-200")
            c.classList.remove("text-red-700")
            c.classList.add("bg-green-200")
            c.classList.add("text-green-700")
          }
        });
    }
  }

  function setKabul(e, kabul, id) {
    e.stopPropagation();

    
    const c = e.currentTarget
    if (
      !c.classList.contains("opacity-50") 
    ) {
      c.classList.add("opacity-50");
    
      axios
        .post(`/setKabul/${id}`, {
          kabul: c.dataset.kabul == 1 ? 0 : 1,
        })
        .then((res) => {
          c.classList.remove("opacity-50");
          
          if (res.data == 0) {
            c.dataset.kabul = 0;
            c.querySelector(".izin-text").innerText = "İzinsiz";
            c.querySelector("i").classList.add("fa-cancel");
            c.querySelector("i").classList.remove("fa-check");
            c.querySelector(".izin-bg").classList.add("bg-red-200")
            c.querySelector(".izin-bg").classList.add("text-red-700")
            c.querySelector(".izin-bg").classList.remove("bg-green-200")
            c.querySelector(".izin-bg").classList.remove("text-green-700")
          }
          if (res.data == 1) {
            c.dataset.kabul = 1;
            c.querySelector("i").classList.remove("fa-cancel");
            c.querySelector("i").classList.add("fa-check");
            c.querySelector(".izin-text").innerText = "İzinli";
            c.querySelector(".izin-bg").classList.remove("bg-red-200")
            c.querySelector(".izin-bg").classList.remove("text-red-700")
            c.querySelector(".izin-bg").classList.add("bg-green-200")
            c.querySelector(".izin-bg").classList.add("text-green-700")
          }
        });
    }
  }

  function add(e, _miktar){
   setMiktar(parseInt(miktar) + parseInt(_miktar))
  }

  function getFrame(e){
    e.preventDefault();
    const data = {
      amount: e.target[0].value,
      isim: e.target[1].value,
      eposta: e.target[2].value,
      tel: e.target[3].value,
      adres: e.target[4].value,
      ogrenci: localStorage.veli_id,
      to_okul : user ? user[0].to_okul : ""
    }


    e.target[5].innerHTML = "<i class='fa-spin fa-spinner fa-solid'></i>"
    e.target[5].setAttribute("disabled","")

    console.log(data)
    axios.post(`/odeme`,data).then(res=>{
      console.log(res)
      if("status" in res.data){
        if(res.data.status == "success"){
          console.log(res.data.token)
          setToken(res.data.token)
        }else{
          alert(res.data.reason)
          window.location.reload()
        }
      }
    })
  }
  return (
    <div className="screen ps-0">
          
      <div className="page">

        {okul && okul.duyuru && user[0].duyuru_okundu == "0" ? 

        <div className="fixed top-0 left-0 bg-zinc-900/50 flex items-center justify-center w-full h-full duyuru-bg">
            <div className="in py-4 w-full max-w-[520px] bg-white rounded p-2">
                <div className="row g-2">
                <div className=" col-12 col-md-4">
                  <div className="px-5 h-full flex items-center">
                    <i className="fa-solid fa-bullhorn text-blue-400 text-5xl"></i>
                  </div>
                </div>
                <div className=" col-12 col-md-8">
                  <div>
                    <div className="font-medium text-blue-500 text-xl">Okulunuzdan Duyuru</div>
                    <div className="font-medium text-slate-400">{okul.duyuru}</div>
                  
                  <button onClick={e=>{
                    document.querySelector(".duyuru-bg").remove()
                    axios.post(`/update/ogrenci/ogrenci_id/${localStorage.veli_id}/?str=duyuru_okundu = 1`).then(res=>{
                      console.log(res)
                    })
                  }} className="bg-sky-200 hover:bg-sky-300 rounded mt-2 font-medium p-2 text-sky-600 "><i className="fa-solid fa-check"></i> Tamam</button>
                  </div>
                </div>
                </div>
            </div>
        </div> : ""  }


    {token ?
    <>
    <IframeResizer
  log
  src={`https://www.paytr.com/odeme/guvenli/${token}`} 
  style={{ width: '1px', minWidth: '100%',marginBottom:20,background:"#fff"}}
/>

    </> 
: ""}

        <div className="row g-2">
          <div className="col-12">
            <div className="kutu shadow-sm rounded p-3 bg-white">
              {user && token == null ? (
                <div className="row g-2">
                 
                  <div className="col-md-4 col-12">
                    <div className="h-full w-full flex items-center justify-center">

                      {user[0].resim != "" ? 
                      <img
                        alt={user[0].isim}
                        src={user[0].resim }
                        className="w-36 h-36 object-cover rounded-full"
                      /> : 

<div  className="img-fluid rounded-full w-36 h-36 object-cover bg-slate-300 flex items-center select-none	 justify-center text-[8rem] font-bold text-white w-full">
                        {user[0].isim.split("")[0].toUpperCase()}
                      </div> }
                    </div>
                  </div>
                 
                  <div className="col-md-5 col-12 ">
                    <div className="h-full w-full items-center md:items-start flex-column flex justify-between">
                      <div>
                        <div className="font-medium text-2xl">
                          {user[0].isim}{" "}
                          <span className=" text-primary">
                            {" "}
                            - {user[0].numara}{" "}
                          </span>
                        </div>
                        <div className="font-medium text-slate-400">
                          {user[0].tc}
                        </div>
                      </div>

                      <div className="flex  mt-3">
                        <div>
                          <div className="fiyat-text text-primary lh-1 font-bold text-3xl">
                            {user[0].bakiye} ₺
                          </div>
                          <div className="font-medium text-xl ">
                            Güncel Bakiye
                          </div>

                          <div className=" mt-3">
                          <div className="font-medium text-xs text-slate-400">Günlük Harcama Limit (TL)</div>
                          <input min={0} onChange={e=>{
                            axios.post(`/update/ogrenci/ogrenci_id/${localStorage.veli_id}?str=gunluk_limit = ${e.target.value}`).then(res=>{
                              console.log(res)
                              
                            })
                          }} type="number" defaultValue={user[0].gunluk_limit} placeholder="Günlük Harcama Limit (TL)" className="rounded bg-slate-100 p-1 font-medium text-xs" />                            
                          </div>
                        

                          
                        </div>

                        <div>
                           <ReactTooltip effect="solid" />
                          <button onClick={e=>setBakiyeForm(!bakiyeForm)} data-tip="Bakiye Yükle"  className="ml-3 bg-primary font-bold rounded-full w-12 h-12 text-2xl font-bold text-white">
                            +
                          </button>
                        </div>

                      
                      </div>

                    
                   
                    </div>

                    
                  </div>

                  <div className="col-12 col-md-3">
                    {
                      yardımMode ? 
                      <div className=" ">
                        <div className="font-medium">Destek & Yardım</div>
                        <div className="text-slate-400 text-xs lh-1">Bizimle iletişime geçerek destek alabilirsiniz.</div>
                        <a href="tel:905073318733" target="_blank" className="d-block text-center mb-2 text-blue-400 border-blue-400 w-full mt-2 hover:bg-blue-100 border-2 rounded p-1 text-sm font-medium">0507 331 8733</a>
                        <a href="https://wa.me/905073318733" target="_blank" className="d-block text-center mb-2 text-green-400 border-green-400 w-full hover:bg-green-100 border-2 rounded p-1 text-sm font-medium">WhatsApp</a>
                        <a href="mailto:info@themaestro.com.tr" target="_blank" className="d-block text-center mb-2 text-slate-400 border-slate-400 w-full hover:bg-slate-100 border-2 rounded p-1 text-sm font-medium">info@themaestro.com.tr</a>
                        <a href="https://anydesk.com/tr" target="_blank" className="d-block text-center mb-2 text-red-400 border-red-400 w-full hover:bg-red -100 border-2 rounded p-1 text-sm font-medium">AnyDesk</a>
{
  
}
                      </div>
                   :

                    <div className=" "> 
                        <button onClick={e=>{
                          setBakiyeForm(!bakiyeForm)
                         //document.querySelector("#yukle").scrollIntoView()
                        }} className="mb-2 text-blue-400 border-blue-400 w-full hover:bg-blue-100 border-2 rounded p-2 font-medium"><i className="fa-solid fa-plus"></i> Bakiye Yükle</button>
                        <a href="#siparisler" className="d-block text-center mb-2 text-green-400 border-green-400 w-full hover:bg-green-100 border-2 rounded p-2 font-medium"><i className="fa-solid fa-burger"></i> Siparişler</a>
                        <Link to="/ayarlar" className="d-block text-center mb-2 text-slate-400 border-slate-400 w-full hover:bg-slate-100 border-2 rounded p-2 font-medium"><i className="fa-solid fa-gear"></i> Ayarlar</Link>
                        <button onClick={e=>setYardımMode(true)} className="mb-2 text-red-400 border-red-400 w-full hover:bg-red-100 border-2 rounded p-2 font-medium"><i className="fa-solid fa-circle-info"></i> Destek & Yardım</button>
                        <Link to="/cikis" className="mb-2 w-full d-block text-center text-red-400 border-red-400 w-full hover:bg-red-100 border-2 rounded p-2 font-medium"><i className="fa-solid fa-right-from-bracket"></i> Çıkış</Link>
                    </div>
                     }
                  </div>
                </div>
              ) : (
                token == null ?
                <div className="row">
                  <div className="col-5">
                    <div className="h-full w-full flex items-center justify-center">
                      <div className="w-36 h-36 skeleton object-cover rounded-full"></div>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="h-full w-full flex-column flex justify-between">
                      <div>
                        <div className="w-48 skeleton "></div>
                        <div className="w-12 ml-3 skeleton "></div>
                        <div className="w-24 skeleton d-block mt-1"></div>
                      </div>

                      <div>
                        <div className="w-24 skeleton d-block mt-1"></div>
                      </div>
                    </div>
                  </div>
                </div> : <div className="font-medium  text-green-500 text-center"><i className="fa-solid fa-shield-halved"></i> Yazılımımız hiç bir şekilde kart bilgilerinizi tutmamaktadır. Güvenle alışveriş yapabilirsiniz.</div>
              )}
            </div>


            {user && !token && bakiyeForm ? 

            <div className="kutu shadow-sm rounded in p-3 bg-white mt-2" id="yukle">
                <form onSubmit={e=>getFrame(e)}>
                    <div className="font-bold text-lg">Bakiye Yükle</div>
                    <div className="row g-2 mb-3">
                      <div className="col-4 col-sm-3 col-lg">
                        <div onClick={e=>add(e,20)} className="fiyat-text hover:bg-red-500/75 cursor-pointer p-2 rounded color-red-500 font-bold text lg bg-red-500/50  text-center border-2 border-red-500">
                          20 ₺
                        </div>
                      </div>

                       <div className="col-4 col-sm-3 col-lg">
                        <div onClick={e=>add(e,30)} className="fiyat-text hover:bg-red-500/75 cursor-pointer p-2 rounded color-red-500 font-bold text lg bg-red-500/50  text-center border-2 border-red-500">
                          30 ₺
                        </div>
                      </div>

                       <div className="col-4 col-sm-3 col-lg">
                        <div onClick={e=>add(e,50)} className="fiyat-text hover:bg-red-500/75 cursor-pointer p-2 rounded color-red-500 font-bold text lg bg-red-500/50  text-center border-2 border-red-500">
                          50 ₺
                        </div>
                      </div>

                      <div className="col-6 col-sm-3 col-lg ">
                        <div onClick={e=>add(e,100)} className="fiyat-text hover:bg-red-500/75 cursor-pointer p-2 rounded color-red-500 font-bold text lg bg-red-500/50  text-center border-2 border-red-500">
                          100 ₺
                        </div>
                      </div>

                      
                      <div className="col-6 col-sm-3 col-lg">
                        <div onClick={e=>add(e,200)} className="fiyat-text hover:bg-red-500/75 cursor-pointer p-2 rounded color-red-500 font-bold text lg bg-red-500/50  text-center border-2 border-red-500">
                          200 ₺
                        </div>
                      </div>
                    </div>
                <div className="row g-2">
                  <div className="col-12 col-sm-6">
                  <label className="lh-1 font-medium text-primary">Yüklenecek Bakiye (TL)</label>
                    <input value={miktar !=0 ? miktar : ""} onChange={e=>e.target.value == "" ? setMiktar(0) : setMiktar(e.target.value)} placeholder="Yüklenecek Bakiye" type="number" min={0} required className="text-black rounded placeholder-black font-medium p-2 w-full bg-red-500/50" />
                  </div>

                  <div className="col-12 col-sm-6">
                    <label className="lh-1 font-medium">İsim Soyisim</label>
                    <input defaultValue={user[0].isim} placeholder="İsim Soyisim" type="text"  required className="bg-slate-100 rounded font-medium p-2 w-full" />
                  </div>

                  <div className="col-12 col-sm-6">
                    <label className="lh-1 font-medium">E-Posta</label>
                    <input placeholder="E-Posta" type="email" defaultValue={user[0].mail}  required className="bg-slate-100 rounded font-medium p-2 w-full" />
                  </div>

                  <div className="col-12 col-sm-6">
                    <label className="lh-1 font-medium">Telefon Numarası</label>
                    <input placeholder="Telefon Numarası" type="tel" defaultValue={user[0].telefon}  required className="bg-slate-100 rounded font-medium p-2 w-full" />
                  </div>

                  <div className="col-12 col-sm-6">
                    <label className="lh-1 font-medium">Adres</label>
                    <input placeholder="Adres" type="text"  required className="bg-slate-100 rounded font-medium p-2 w-full" />
                  </div>

                  <div className="col-12 col-sm-6">
                    <div>
              <label >&nbsp;</label>
                    <button disabled={miktar == 0 ? true : false} className="button p-2 w-full h-full d-block bg-primary fiyat-text">Ödeme Yap {miktar != 0 ? `(${miktar} ₺)` : ""}</button>

                    </div>
                  </div>
                </div>
               
                </form>
                
            </div>

            : ""
          }
          </div>
        </div>

        {!token ? 

        <div className="row mt-2 g-2">
          
          <div className="col-12">
              <div className="flex  items-center justify-between">
                <div className="font-medium p-2 bg-amber-200 rounded">Ürünler</div>
              
              <div className="flex ms-auto">
                <div title="Görünümü Değiştir" onClick={e=>setViewType("box")} className={`${viewType == "box" ? "bg-green-50 border-green-400 text-green-400 " : "bg-slate-50 border-slate-50"} border-2 p-2 cursor-pointer font-medium rounded ms-2`}><i className="fa-solid fa-box"></i></div>
                <div title="Görünümü Değiştir" onClick={e=>setViewType("list")} className={`${viewType == "list" ? "bg-green-50 border-green-400 text-green-400 " : "bg-slate-50 border-slate-50"} border-2 p-2 cursor-pointer font-medium rounded ms-2`}><i className="fa-solid fa-list"></i></div>
              </div>
              </div>
          </div>


          <div className="col-12">
            <div className="row g-2">

              {
                user && viewType == "list" ?
               <div className="in bg-white p-2 rounded">
                 <table className="table">
                  <thead>
                    <tr>
                      <th>İsim</th>
                      <th>Fiyat</th>
                      <th>Kalori</th>
                      <th>Gluten</th>
                    </tr>
                    </thead>
                    <tbody>
                      {
                        user.yemekler.map(val=>{
                          return(
                            <tr
                            className={val.kabul == 1 ? "izin-bg bg-green-200 text-green-700 cursor-pointer" : "cursor-pointer bg-red-200 text-red-700 izin-bg"}
                            key={val.ou_id} onClick={(e) => setKabul2(e, val.kabul, val.ou_id)}
                            data-kabul={val.kabul} >
                              <td className="border-0 font-medium">{val.urun_isim}</td>
                              <td className="border-0  font-medium">{val.urun_fiyat} <span className="fiyat-text">₺</span> </td>
                              <td className="border-0">{val.kalori}</td>
                              <td className="border-0">{val.gluten == "false" ? "Glutensiz" : "Glutenli"}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                </table>  
               </div>
             : "" }

          
          {
            user && viewType == "box" && user.yemekler.map((val,i)=>{
              return(
                <div key={i}  className="in  col-12 col-sm-6 col-lg-3">
                <div 
                  onClick={(e) => setKabul(e, val.kabul, val.ou_id)}
                data-kabul={val.kabul} className="cursor-pointer  bg-white rounded h-full shadow border">
                    <div  className={val.kabul == 1 ? "izin-bg bg-green-200 text-green-700 font-medium text-lg p-2" : "izin-bg bg-red-200 text-red-700 font-medium text-lg p-2"}> {val.kabul == 1 ? <i className="fa-solid fa-check"></i> :  <i className="fa-solid fa-cancel"></i>} <span className="izin-text">{val.kabul == 1 ? "İzinli" : "İzinsiz"}</span></div>
                    <img alt={val.urun_isim} loading="lazy" className="w-full object-contain rounded h-36" src={val.urun_resim != ""? val.urun_resim  :  axios.defaults.base+"assets/img/yemek.jpg"}  />
                    <div className="p-2">
                      {
                        val.gluten == "false" ? 
                      
                      <div className="font-medium lh-1 text-sm text-amber-600">Glutensiz</div>
                     
                     :<div className="mt-1"></div>}
                      <div className="font-medium text-lg ">{val.urun_isim}</div>

                      <div className="flex items-center justify-between">
                      <div className="font-medium text-end text-green-600 lh-1 text-lg fiyat-text">{val.urun_fiyat} ₺</div>
                    <div className="font-medium text-sm">{val.kalori} kcal</div>
                      </div>
                    </div>
                </div>
              </div>
              )
            })
          }
  </div>
          </div>
          
        </div> : ""}

        {!token ?
        
        <div className="bg-white kutu mt-2 rounded shadow p-2" id="siparisler">
            <div className="lh-1 font-medium text-lg text-center">Öğrencinizin Sipariş Listesi</div>
            <div className="lh-1 font-medium text-sm text-slate-500 text-center">Son 15 sipariş listeleniyor</div>
            <table className=" table mt-2">
              <thead>
                <tr className="text-primary">
                  <th>Ürün</th>
                  <th>Fiyat</th>
                  <th>Tarih</th>
                </tr>
              </thead>
              <tbody>
              {siparisler  && siparisler.map((val,i)=>{
                  return(
                    <tr key={i}>
                      <td className="font-medium">{val.urun}</td>
                      <td className="fiyat-text text-green-600 font-medium">{val.fiyat} ₺</td>
                      <td className="font-medium  text-slate-600">
                       
                          {tarih(val.tarih)}
                       
                        <i className="ms-1 fa-solid fa-clock text-slate-600 text-sm"></i> </td>
              
                    </tr>
                  )
                })}

              </tbody>
            </table>

            {siparisler && siparisler.length == 0 ? <div className="bg-amber-200 p-2 rounded font-medium text-center border-2 border-amber-400">Öğrenciniz hiç sipariş vermemiş</div> : ""}

            
    
        
        </div> : ""}

        <div className="flex justify-center pt-3">
              <a
                href={"https://themaestro.com.tr/"}
                target="_blank"
                className="d-block  mx-auto "
              >
                <img src="/logo-black.svg" width={220} alt="" />
                <div className="text-center text-zinc-900">Powered by</div>
              </a>
            </div>
      </div>


    </div>
  );
}

export default VeliIndex;
