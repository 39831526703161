import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import MobileHeader from "../components/MobileHeader";

function StokGir() {
  let navigate = useNavigate();
  let params = useParams();

  const [tedarikci, setTedarikci] = useState(null);
  const [urunler, setUrunler] = useState(null);
  const [genelIskonto, setgenelIskonto] = useState("0");

  const [belgeNo, setBelgeNo] = useState(
    `${new Date().getFullYear()}${
      new Date().getMonth() + 1
    }${new Date().getDate()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getMilliseconds()}`
  );
  const [tarih, setTarih] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`
  );
  const [aciklama, setAciklama] = useState("");

  const [kalemler, setKalemler] = useState([
    {
      urun_id: null,
      kdv: 0,
      miktar: 1,
      birim_fiyat: 0,
      tutar: 0,
      iskonto: "0",
      kdv_birim_fiyat: 0,
      kdv_tutar: 0,
    },
  ]);

  useEffect(() => {
    console.log(kalemler);
  }, [kalemler]);

  function save(e) {

    if(kalemler.filter((el) => el.urun_id != null).length == 0){
      alert("Hiç ürün girmediniz.")
      return
    }

    if(!window.confirm("Belgeyi kaydedip tamamlamak istediğinize emin misiniz")){
        return
    }

    e.target.innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>"
    var data = {
      belge: kalemler.filter((el) => el.urun_id != null),
      to_tedarikci: params.id,
      to_okul: localStorage.okul_id,
      belge_no: belgeNo,
      aciklama: aciklama,
      tarih: tarih,
      toplam: mainIskonto(genelIskonto),
      genel_iskonto: genelIskonto,
      user_id: localStorage.user_id
    };

    axios.post("/stok_save/", data).then((res) => {
      console.log(res);
      if(res.data.ok == true){
        navigate(`/stok-detay/${res.data.id}`)
      }
    });
  }

  useEffect(() => {
    axios.get(`/tedarikci/${params.id}`).then((res) => {
      setTedarikci(res.data);
    });
    axios
      .get(`/urunler/${localStorage.okul_id}/?order=urun_isim ASC`)
      .then((res) => {
        setUrunler(res.data);
        const _a = [];
        res.data.forEach((val) => {
          _a.push({
            value: val.urun_id,
            label: `${val.urun_isim} ${val.barkod ? " - " + val.barkod : ""}`,
          });
        });
        setUrunler(_a);
      });
  }, []);

  function topİskonto(i) {
    const iskontolar = kalemler[i].iskonto.toString().split("+");
    if (iskontolar.length == 1 && iskontolar[0] == "") {
      iskontolar[0] = 0;
    }
    var b = (
      kalemler[i].birim_fiyat *
      (1 + kalemler[i].kdv / 100) *
      kalemler[i].miktar
    ).toFixed(2);

    iskontolar.forEach((val, i) => {
      b = b - (b / 100) * Number(val);
    });

    return b.toFixed(2);
  }

  function mainIskonto(e) {
    const iskontolar = genelIskonto.toString().split("+");
    if (iskontolar.length == 1 && iskontolar[0] == "") {
      iskontolar[0] = 0;
    }
    var b = kalemler
      .reduce((a, v) => (a = a + parseFloat(v.tutar)), 0)
      .toFixed(2);

    iskontolar.forEach((val, i) => {
      b = b - (b / 100) * Number(val);
    });

    return b.toFixed(2);
  }

  return (
    <div className="screen">
      <div className="page-big">
      <MobileHeader/>
        <div className="bg-white p-2 rounded kutu shadow-sm">
          {tedarikci ? (
            <div className="flex justify-between items-center ">
              <div className="font-bold text-lg lh-1">{tedarikci[0].isim}</div>

              <div className="flex">
                <button
                  onClick={(e) => navigate("/tedarikciler?stok")}
                  type="submit"
                  className="border-2 py-1 text-sm  px-3 hover:bg-slate-100 font-medium rounded text-slate-400"
                >
                  Tedarikçi Değiş
                </button>

                <button
                  onClick={(e) => save(e)}
                  type="submit"
                  className="border-2 border-blue-500 ms-2 py-1 text-sm  px-3 hover:bg-blue-500 font-medium rounded bg-blue-400"
                >
                  <i className="class"></i> Kaydet
                </button>
              </div>
            </div>
          ) : (
            <div className="row g-2 flex-fill">
              <div className="col-12">
                <div className="skeleton w-full py-3"></div>
              </div>
              <div className="col-6">
                <div className="skeleton w-full py-3"></div>
              </div>
              <div className="col-6">
                <div className="skeleton w-full py-3"></div>
              </div>
              <div className="col-6">
                <div className="skeleton w-full py-3"></div>
              </div>
              <div className="col-6">
                <div className="skeleton w-full py-3"></div>
              </div>
            </div>
          )}
        </div>

        <div className="bg-white p-2 rounded kutu shadow-sm mt-3">
          <div className="tablo">
            <div className="row g-2">
              <div className="col-3">
                <div className=" font-bold lh-1">Ürün</div>
              </div>
              <div className="col">
                <div className="font-bold lh-1">KDV %</div>
              </div>
              <div className="col">
                <div className="font-bold lh-1">Miktar</div>
              </div>
              <div className="col">
                <div className="font-bold lh-1">B. Fiyat</div>
              </div>
              <div className="col">
                <div className="font-bold lh-1">İskonto</div>
              </div>
              <div className="col">
                <div className="font-bold lh-1 text-center">Tutar</div>
              </div>

              <div className="col">
                <div className="font-bold lh-1 text-center ">KDV B.F.</div>
              </div>
              <div className="col">
                <div className="font-bold lh-1 text-end ">Top. Tutar</div>
              </div>
            </div>

            <div className="border-b my-1"></div>
          </div>
          <div className="kalemler">
            {kalemler.map((val, i) => {
              return (
                <div key={i} className="mb-2 in  row g-2">
                  <div className="col-3">
                    <Select
                      onChange={(val) => {
                        const _k = [...kalemler];
                        _k[i].urun_id = val == null ? null : val.value;

                        if(i == (kalemler.length - 1)){
                          setKalemler([
                            ..._k,
                            {
                              urun_id: null,
                              kdv: 0,
                              miktar: 1,
                              birim_fiyat: 0,
                              tutar: 0,
                              iskonto: 0,
                              kdv_birim_fiyat: 0,
                              kdv_tutar: 0,
                            },
                          ]);
                        }else{
                          setKalemler(_k)
                        }

                        
                      }}
                      isClearable={true}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: "#f1f5f9",
                          borderColor: "transparent",
                          fontWeight: "500",
                        }),
                      }}
                      options={urunler}
                    />
                  </div>
                  {kalemler[i].urun_id != null ? (
                    <>
                      <div className="col ">
                        <div>
                          <input
                            type="number"
                            min={0}
                            value={val.kdv}
                            placeholder="KDV"
                            className="w-full font-medium rounded bg-slate-50 p-2"
                            onChange={(e) => {
                              const _k = [...kalemler];
                              _k[i].kdv = Number(e.target.value);
                              _k[i].kdv_birim_fiyat = parseFloat(
                                (
                                  kalemler[i].birim_fiyat *
                                  (1 + kalemler[i].kdv / 100)
                                ).toFixed(2)
                              );

                              _k[i].kdv_tutar = parseFloat(
                                ((
                                  kalemler[i].birim_fiyat *
                                  (1 + kalemler[i].kdv / 100)
                                ).toFixed(2) -
                                  kalemler[i].birim_fiyat) *
                                  kalemler[i].miktar
                              ).toFixed(2);

                              _k[i].tutar = topİskonto(i);

                              setKalemler(_k);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col">
                        <div>
                          <input
                            type="number"
                            min={1}
                            placeholder="Miktar"
                            value={val.miktar}
                            onChange={(e) => {
                              const _k = [...kalemler];
                              _k[i].miktar = Number(e.target.value);

                              _k[i].kdv_birim_fiyat = parseFloat(
                                (
                                  kalemler[i].birim_fiyat *
                                  (1 + kalemler[i].kdv / 100)
                                ).toFixed(2)
                              );

                              _k[i].kdv_tutar = parseFloat(
                                ((
                                  kalemler[i].birim_fiyat *
                                  (1 + kalemler[i].kdv / 100)
                                ).toFixed(2) -
                                  kalemler[i].birim_fiyat) *
                                  kalemler[i].miktar
                              ).toFixed(2);

                              _k[i].tutar = topİskonto(i);

                              setKalemler(_k);
                            }}
                            className="w-full font-medium rounded bg-slate-50 p-2"
                          />
                        </div>
                      </div>

                      <div className="col">
                        <div>
                          <input
                            type="number"
                            min={0}
                            placeholder="B. Fiyat"
                            value={kalemler[i].birim_fiyat}
                            onChange={(e) => {
                              const _k = [...kalemler];
                              _k[i].birim_fiyat = Number(e.target.value);

                              _k[i].kdv_birim_fiyat = parseFloat(
                                (
                                  kalemler[i].birim_fiyat *
                                  (1 + kalemler[i].kdv / 100)
                                ).toFixed(2)
                              );

                              _k[i].kdv_tutar = parseFloat(
                                ((
                                  kalemler[i].birim_fiyat *
                                  (1 + kalemler[i].kdv / 100)
                                ).toFixed(2) -
                                  kalemler[i].birim_fiyat) *
                                  kalemler[i].miktar
                              ).toFixed(2);

                              _k[i].tutar = topİskonto(i);
                              setKalemler(_k);
                            }}
                            className="w-full font-medium rounded bg-slate-50 p-2"
                          />
                        </div>
                      </div>

                      <div className="col">
                        <div>
                          <input
                            type="text"
                            placeholder="İskonto %"
                            className="w-full font-medium rounded bg-slate-50 p-2"
                            value={kalemler[i].iskonto}
                            onChange={(e) => {
                              const _k = [...kalemler];
                              _k[i].iskonto = e.target.value;

                              _k[i].tutar = topİskonto(i);

                              setKalemler(_k);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col">
                        <div>
                          <div className="w-full font-medium  text-center py-2 text-green-500">
                            {kalemler[i].miktar * kalemler[i].birim_fiyat}
                          </div>
                        </div>
                      </div>

                      <div className="col">
                        <div>
                          <div className="w-full font-medium  text-center py-2 text-green-500">
                            {(
                              kalemler[i].birim_fiyat *
                              (1 + kalemler[i].kdv / 100)
                            ).toFixed(2)}
                          </div>
                        </div>
                      </div>

                      <div className="col">
                        <div>
                          <div className="w-full font-medium  text-end py-2 text-green-500">
                            {topİskonto(i)}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col">
                      <div className="font-medium p-2 text-center bg-red-300 rounded border-2 border-red-500">
                        Ürün Seçiniz
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div>
            <button
              onClick={(e) => {
                setKalemler([
                  ...kalemler,
                  {
                    urun_id: null,
                    kdv: 0,
                    miktar: 1,
                    birim_fiyat: 0,
                    tutar: 0,
                    iskonto: 0,
                    kdv_birim_fiyat: 0,
                    kdv_tutar: 0,
                  },
                ]);
              }}
              type="submit"
              className="border-2  p-2 hover:bg-slate-100 font-medium rounded text-slate-400"
            >
              <i className="fa-solid fa-plus"></i> Satır Ekle
            </button>
          </div>
        </div>

        <div className="flex sticky bottom-[20px]  mt-3">
          <div className="ms-auto bg-gray-300 p-2 rounded kutu border-2 border-gray-500 w-80 shadow-lg ">
            <div className="p-2 pb-0 font-medium flex items-center justify-between">
              <div>Tarih</div>
              <div>
                <input
                  type="date"
                  value={tarih}
                  onChange={(e) => setTarih(e.target.value)}
                  className="px-2 w-44 rounded border-2"
                />
              </div>
            </div>

            <div className="p-2 pb-0 font-medium flex items-center justify-between">
              <div className="lh-1">Belge No</div>
              <div>
                <input
                  type="text"
                  value={belgeNo}
                  onChange={(e) => setBelgeNo(e.target.value)}
                  className="px-2 w-44  rounded border-2"
                />
              </div>
            </div>

            <div className="p-2 pb-0 font-medium flex items-center justify-between">
              <div className="lh-1">Açıklama</div>
              <div>
                <input
                  type="text"
                  value={aciklama}
                  onChange={(e) => setAciklama(e.target.value)}
                  className="px-2 w-44  rounded border-2"
                />
              </div>
            </div>
          </div>

          <div className="ms-3 bg-gray-300 p-2 rounded kutu border-2 border-gray-500 w-64 shadow-lg ">
            <div className="p-2 pb-0 font-medium flex items-center justify-between">
              <div>Genel İskonto %</div>
              <div>
                <input
                  type="text"
                  value={genelIskonto}
                  onChange={(e) => setgenelIskonto(e.target.value)}
                  className="px-2 w-16 rounded border-2"
                />
              </div>
            </div>

            <div className="p-2 font-medium flex items-center justify-between">
              <div>KDV Tutar</div>
              <div>
                {" "}
                {kalemler
                  .reduce((a, v) => (a = a + parseFloat(v.kdv_tutar)), 0)
                  .toFixed(2)}
              </div>
            </div>

            <div className="rounded bg-slate-50 p-2 font-medium flex items-center justify-between">
              <div>Toplam Tutar</div>
              <div>{mainIskonto(genelIskonto)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StokGir;
