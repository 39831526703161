import React from 'react'
import { Link } from 'react-router-dom'

function MainShow({to,icon,text,desc}) {
  return (
    <div className="col-6">
    <Link
      to={to}
      className="in px-2 h-full py-4 d-block hover:bg-slate-200 shadow-sm rounded bg-slate-50 kutu"
    >
      <div className="flex flex-column items-center justify-center">
        <div>
          <i className={`fa-solid ${icon} me-2 text-primary text-4xl cursor-pointer `}></i>
        </div>
        <div className="text-center mt-2 px-3">
          <div className="font-medium text-xl text-primary">
           {text}
          </div>
          <div className="font-medium text-slate-400 lh-1  text-lg">
            {desc}
          </div>
        </div>
      </div>
    </Link>
  </div>
  )
}

export default MainShow