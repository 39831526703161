import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {tarih } from "../../iskonto";
import axios from "axios";
import MobileHeader from "../../components/MobileHeader";


function GunlukRapor() {

  const start = useRef();

  const [data, setData] = useState(null);

  function getZ(e) {
    if (!window.confirm("Z Raporunu almak istediğinize emin misiniz")) {
      return;
    }
    e.target.innerHTML = "<i class='fa-spin fa-solid  fa-spinner'></i>";
    axios
      .post(`/sifrele/`, {
        data: `${localStorage.okul_id}@${start.current.value}@false`,
      })
      .then((res) => {
        e.target.innerHTML = "Z Raporu";
        window
          .open(axios.defaults.baseURL + "z?payload=" + res.data, "_blank")
          .focus();
      });
  }

  useEffect(() => {
    const date = new Date().toLocaleDateString("en-CA");
    console.log(date)
    axios
      .get(`/getRaporDate/${localStorage.admin == "true" ? localStorage.okul_id : localStorage.user_id}/?tarih=${date}&admin=${localStorage.admin}`)
      .then((res) => {
        setData(res.data);
        console.log(res);
      });
  }, []);

  function tarihSearch(e) {
    e.preventDefault();

    if (start.current.value == "") {
      alert("Tarih seçiniz");
      return;
    }

    setData(null);
    axios
      .get(
        `/getRaporDate/${localStorage.admin == "true" ? localStorage.okul_id : localStorage.user_id}/?tarih=${start.current.value}&admin=${localStorage.admin}`
      )
      .then((res) => {
        console.log(res);
        setData(res.data);
      });
  }
  return (
    <div className="screen">
      <div className="page">
      <MobileHeader/>
        <div className="mb-2 shadow-sm  flex items-center justify-between bg-white rounded-2 px-2">
          <div className="font-medium">{tarih(new Date(), false)}</div>

          <div className="flex">
            <Link
              to="/z-raporlari"
              className="hidden rounded cursor-pointer hover:text-zinc-900 flex items-center lh-1 bg-blue-400 hover:bg-blue-500 px-3 my-1 me-2 font-medium"
            >
              Z Listesi
            </Link>
            <button
              onClick={(e) => getZ(e)}
              className="hidden rounded cursor-pointer bg-blue-400 hover:bg-blue-500 px-3 my-1 me-2 font-medium"
            >
              Z Raporu
            </button>

            <form onSubmit={(e) => tarihSearch(e)}>
              <input
                ref={start}
                type="date"
                defaultValue={new Date().toLocaleDateString("en-CA")}
                max={new Date().toLocaleDateString("en-CA")}
                className="bg-slate-100 p-2 border-l-5"
              />
              <button
                type="submit"
                className="bg-primary text-xs p-2 ms-2 rounded-full "
              >
                <i className="fa-solid fa-magnifying-glass "></i>{" "}
              </button>
            </form>
          </div>
        </div>

        <div className="mt-2 bg-white rounded p-2 shadow kutu">
          {data ? (
            <>
              <div className="font-medium text-lg text-center">Rapor</div>

              <div className="row g-3 mt-2">
                <div className="col-3">
                  <div className="flex flex-column items-center">
                    <div className="text-2xl font-medium ">
                      {data.toplam ? data.toplam : 0} <span className="fiyat-text">₺</span>
                    </div>
                    <div className="font-medium text-gray-500">Toplam</div>
                  </div>
                </div>

                <div className="col-3">
                  <div className="border-x-2 flex flex-column items-center">
                    <div className="text-2xl font-medium ">
                      {data.nakit ? data.nakit : 0} <span className="fiyat-text">₺</span>
                    </div>
                    <div className="font-medium text-gray-500">Nakit</div>
                  </div>
                </div>

                <div className="col-3">
                  <div className="border-r-2 flex flex-column items-center">
                    <div className="text-2xl font-medium ">
                      {data.kart ? data.kart : 0} <span className="fiyat-text">₺</span>
                    </div>
                    <div className="font-medium text-gray-500">K.Kartı</div>
                  </div>
                </div>

                <div className="col-3">
                  <div className=" flex flex-column items-center">
                    <div className="text-2xl font-medium ">
                      {data.bakiye ? data.bakiye : 0} <span className="fiyat-text">₺</span>
                    </div>
                    <div className="font-medium text-gray-500">Bakiye</div>
                  </div>
                </div>
              </div>

              <div className="max-scroll mt-4 shadow-table rounded-lg">
                {data.siparisler && data.siparisler.length != 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <td className="font-bold">Ürün</td>
                        <td className="font-bold">Fiyat</td>
                        <td className="font-bold">Adet</td>
                        <td className="font-bold">Personel</td>
                        <td className="font-bold">Ödeme</td>
                        <td className="font-bold">Tarih</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.siparisler.map((val, i) => {
                        return (
                          <tr key={i} className="hover:bg-slate-100">
                            <td className="font-medium border-0">{val.urun}</td>
                            <td className="fiyat-text text-green-500 font-medium border-0">
                              {val.fiyat} ₺
                            </td>
                            <td className="border-0 font-medium">{val.adet}</td>
                            <td className="border-0 text-sm">{val.per}</td>
                            <td className="border-0 text-sm">{val.odeme}</td>

                            <td className="border-0 text-sm">
                              {tarih(val.tarih)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  ""
                )}

               
              </div>
            </>
          ) : (
            <>
            <div className="font-medium text-lg text-center">Rapor</div>

            <div className="row g-3 mt-2">
              <div className="col-3">
                <div className="flex flex-column items-center">
                  <div className="text-2xl font-medium ">
                  <div className="skeleton mx-auto d-block px-5 h-1-important"></div>
                  </div>
                  <div className="mt-2 skeleton mx-auto d-block px-5 h-1-important"></div>

                </div>
              </div>

              <div className="col-3">
                <div className="border-x-2 flex flex-column items-center">
                  <div className="text-2xl font-medium ">
                  <div className="skeleton mx-auto d-block px-5 h-1-important"></div>
                  </div>
                  <div className="mt-2 skeleton mx-auto d-block px-5 h-1-important"></div>
                </div>
              </div>

              <div className="col-3">
                <div className="border-r-2 flex flex-column items-center">
                  <div className="text-2xl font-medium ">
                  <div className="skeleton mx-auto d-block px-5 h-1-important"></div>
                  </div>
                  <div className="mt-2 skeleton mx-auto d-block px-5 h-1-important"></div>
                </div>
              </div>

              <div className="col-3">
                <div className=" flex flex-column items-center">
                  <div className="text-2xl font-medium ">
                  <div className="skeleton mx-auto d-block px-5 h-1-important"></div>
                  </div>
                  <div className="mt-2 skeleton mx-auto d-block px-5 h-1-important"></div>
                </div>
              </div>
            </div>

            <div className="max-scroll mt-4 shadow-table rounded-lg">
            
                <table className="table">
                  <thead>
                    <tr>
                      <td className="font-bold"> <div className="skeleton mx-auto d-block px-5 h-1-important"></div></td>
                      <td className="font-bold"> <div className="skeleton mx-auto d-block px-5 h-1-important"></div></td>
                      <td className="font-bold"> <div className="skeleton mx-auto d-block px-5 h-1-important"></div></td>
                      <td className="font-bold"> <div className="skeleton mx-auto d-block px-5 h-1-important"></div></td>
                      <td className="font-bold"> <div className="skeleton mx-auto d-block px-5 h-1-important"></div></td>
                      <td className="font-bold"> <div className="skeleton mx-auto d-block px-5 h-1-important"></div></td>
                    </tr>
                  </thead>
                  <tbody>
                    
                        <tr  className="hover:bg-slate-100">
                          <td className="font-medium border-0"> <div className="skeleton mx-auto d-block px-5 h-1-important"></div></td>
                          <td className="fiyat-text text-green-500 font-medium border-0">
                          <div className="skeleton mx-auto d-block px-5 h-1-important"></div>
                          </td>
                          <td className="border-0 font-medium"> <div className="skeleton mx-auto d-block px-5 h-1-important"></div></td>
                          <td className="border-0 text-sm"> <div className="skeleton mx-auto d-block px-5 h-1-important"></div></td>
                          <td className="border-0 text-sm"> <div className="skeleton mx-auto d-block px-5 h-1-important"></div></td>

                          <td className="border-0 text-sm">
                          <div className="skeleton mx-auto d-block px-5 h-1-important"></div>
                          </td>
                        </tr>
                   
                  </tbody>
                </table>
            
            </div>
          </>
          )}
        </div>
      </div>
    </div>
  );
}

export default GunlukRapor;
