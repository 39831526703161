import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import MobileHeader from "../../components/MobileHeader";

function UrunRapor() {

  const start = useRef();
  const bitis = useRef();

  const [urunler, setUrunler] = useState(null);

  useEffect(() => {
    axios.get(`/urunRapor/${localStorage.admin == "true" ? localStorage.okul_id : localStorage.user_id}/?admin=${localStorage.admin}`).then((res) => {
      setUrunler(res.data);
    });
  }, []);

  function tarihSearch(e) {
    e.preventDefault();

    if (start.current.value == "") {
      alert("Başlangıç tarihi giriniz");
      return;
    }

    if (bitis.current.value == "") {
      alert("Bitiş tarihi giriniz");
      return;
    }

    e.target[2].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";
    setUrunler(null);

    axios
      .get(
        `/urunRapor/${localStorage.admin == "true" ? localStorage.okul_id : localStorage.user_id}/?start=${start.current.value}&bitis=${bitis.current.value}&admin=${localStorage.admin}`
      )
      .then((res) => {
        setUrunler(res.data);
        e.target[2].innerHTML = '<i class="fa-solid fa-magnifying-glass"></i>';
      });
  }

  return (
    <div className="screen">
      <div className="page">
      <MobileHeader/>
        <>
          <div className="row g-2 mt-2">
          
              <div className="col-12">
                <div className=" bg-white rounded p-2 shadow kutu">
                  <div className="row g-2 mb-3">
                    <div className="col-3">
                      <div className="font-medium text-lg">
                        {" "}
                        En Çok Satılan Ürünler
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="flex px-2 	 bg-slate-100 rounded items-center">
                        <i className="fa-solid fa-magnifying-glass"></i>
                        <input
                          id="ara"
                          onChange={(e) => {
                            var input, filter, table, tr, td, i, txtValue;
                            input = e.target;
                            filter = input.value.toUpperCase();
                            table = document.querySelector(".table tbody");
                            tr = table.querySelectorAll("tr");

                            console.log(tr);

                            for (i = 0; i < tr.length; i++) {
                              td = tr[i];
                              if (td) {
                                txtValue = td.textContent || td.innerText;
                                if (
                                  txtValue.toUpperCase().indexOf(filter) > -1
                                ) {
                                  tr[i].style.display = "";
                                } else {
                                  tr[i].style.display = "none";
                                }
                              }
                            }
                          }}
                          type="text"
                          className="noout bg-slate-100 font-medium p-2 w-full"
                          placeholder="Ürün, Kategori, Barkod Ara"
                        />
                      </div>
                    </div>

                    <div className="col-5">
                      <form onSubmit={(e) => tarihSearch(e)} className="flex">
                        <input
                          ref={start}
                          type="date"
                          className="bg-slate-100 p-2 border-l-5 rounded me-2"
                        />
                        <input
                          ref={bitis}
                          type="date"
                          className="bg-slate-100 p-2 rounded "
                        />
                        <button
                          type="submit"
                          className="bg-primary text-xs p-2 rounded flex-fill ms-2 "
                        >
                          <i className="fa-solid fa-magnifying-glass "></i>{" "}
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="max-scroll">
                    {urunler ? 
                    <table className="table w-full">
                      <thead>
                        <tr>
                          <td className="font-bold">Ürün</td>
                          <td className="font-bold">Barkod</td>
                          <td className="font-bold">Adet</td>
                          <td className="font-bold text-end">Toplam</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {urunler.map((val, i) => {
                          return (
                            <tr key={i} className={` hover:bg-slate-100  `}>
                              <td className=" font-medium border-0">
                                {val.urun_isim}
                              </td>
                              <td className={`text-sm font-medium border-0`}>
                                {val.barkod ? val.barkod : "-"}
                              </td>
                              <td className="border-0 font-medium text-slate-500">
                                {val.toplam}
                              </td>
                              <td className="text-end border-0 text-green-500 font-medium">
                                {val.miktar}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                   
                    </table> : 
                      <table className="table w-full">
                      <thead>
                        <tr>
                          <td className="font-bold"> <div className="skeleton d-block"></div></td>
                          <td className="font-bold"> <div className="skeleton d-block"></div></td>
                          <td className="font-bold"> <div className="skeleton d-block"></div></td>
                          <td className="font-bold "> <div className="skeleton d-block"></div></td>
                        </tr>
                      </thead>
                      <tbody>
                     
                     {[...Array(7).keys()].map((val,i)=>{
                        return(
<tr key={i} className={` hover:bg-slate-100  `}>
                              <td className=" font-medium border-0">
                                <div className="skeleton d-block"></div>
                              </td>
                              <td className={`text-sm font-medium border-0`}>
                              <div className="skeleton d-block"></div>

                              </td>
                              <td className="border-0 font-medium text-slate-500">
                              <div className="skeleton d-block"></div>

                              </td>
                              <td className="text-end border-0 text-green-500 font-medium">
                              <div className="skeleton d-block"></div>

                              </td>
                            </tr>
                         
                        )
                     })}
                            
                      </tbody>
                    </table> 
                    }
                  </div>
                </div>
              </div>
          
          </div>
        </>
      </div>
    </div>
  );
}

export default UrunRapor;
