import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MobileHeader from "../components/MobileHeader";

function Hesabim() {
  const [data, setData] = useState(null);
  const [user, setUser] = useState(null);
  const [mode, setMode] = useState(false)

  useEffect(() => {
    axios.get(`/hesap/${localStorage.okul_id}`).then((res) => {
      console.log(res);
      setData(res.data);
    });

    axios.get(`/meUser/${localStorage.user_id}`).then((res) => {
      setUser(res.data);
      console.log(res.data);
    });
  }, []);

  return (
    <div className="screen">
      <div className="page-min">
      <MobileHeader/>
        <div className="row g-2">
          <div className="col-lg-6 col-12">
            {mode == false ?
            <div className="bg-white p-2 rounded shadow-sm">
           

              {data ? 
              <div>
                <img src={axios.defaults.base+"assets/img/logo/"+data.data[0].logo} className="img-fluid object-cover w-48 my-2 mx-auto" alt="" />
              </div> : "" }

              <div className="text-2xl text-center font-medium lh-1">
                {" "}
                {data ? (
                  data.data[0].okul_ismi
                ) : (
                  <div className="skeleton px-5"></div>
                )}
              </div>


              <div className="mt-3">
                <div className="flex items-center justify-between">
                  <div className="font-medium">İsim</div>
                  <div className="font-medium text-slate-500">
                    {data ? (
                      data.data[0].isim
                    ) : (
                      <div className="skeleton px-5"></div>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="font-medium">Telefon</div>
                  <div className="font-medium text-slate-500">
                    {data ? (
                      data.data[0].telefon
                    ) : (
                      <div className="skeleton px-5"></div>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="font-medium">E-Mail</div>
                  <div className="font-medium text-slate-500">
                    {data ? (
                      data.data[0].mail
                    ) : (
                      <div className="skeleton px-5"></div>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="font-medium">Adres</div>
                  <div className="font-medium text-slate-500">
                    {data ? (
                      data.data[0].adres
                    ) : (
                      <div className="skeleton px-5"></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            :
             data ? 
            <div className="bg-white p-2 mt-2 rounded shadow-sm">
              <form onSubmit={e=>{
                e.preventDefault();
                e.target[6].innerHTML = "<i class='fa-spin fa-solid fa-spinner'></i>"
                axios.post(`/updatePost/okul/okul_id/${localStorage.okul_id}`,{
                    okul_ismi : e.target[0].value,
                    isim : e.target[1].value,
                    telefon : e.target[2].value.trim(),
                    mail : e.target[3].value.trim(),
                    adres : e.target[4].value,
                }).then(res=>{
                  window.location.reload();
                })
              }}>
                <div className="row g-2">
                  <div className="col-12">
                    <div className="font-medium">İşletme İsmi</div>
                    <input defaultValue={data.data[0].okul_ismi} placeholder="İşletme İsmi" className="rounded bg-slate-100 w-full px-2 py-1" type="text" />
                  </div>

                  <div className="col-12">
                    <div className="font-medium">Yetkili Kişi İsmi</div>
                    <input defaultValue={data.data[0].isim} placeholder="Yetkili Kişi İsmi" className="rounded bg-slate-100 w-full px-2 py-1" type="text" />
                  </div>

                  <div className="col-12">
                    <div className="font-medium">Yetkili Telefon No</div>
                    <input defaultValue={data.data[0].telefon} placeholder="Yetkili Telefon No" className="rounded bg-slate-100 w-full px-2 py-1" type="text" />
                  </div>

                  <div className="col-12">
                    <div className="font-medium">Yetkili Mail Adresi</div>
                    <input defaultValue={data.data[0].mail} placeholder="Yetkili Mail Adresii" className="rounded bg-slate-100 w-full px-2 py-1" type="text" />
                  </div>

                  <div className="col-12">
                    <div className="font-medium">İşletme Adresi</div>
                    <input defaultValue={data.data[0].adres} placeholder="İşletme Adresi" className="rounded bg-slate-100 w-full px-2 py-1" type="text" />
                  </div>


<div className="col-6">
  <button type="button" onClick={e=>setMode(!mode)} className="hover:bg-slate-200 w-full bg-slate-100 font-medium p-2 rounded">İptal</button>
</div>


<div className="col-6">
  <button type="submit"  className="w-full bg-green-400 hover:bg-slate-500 font-medium p-2 rounded">Kaydet</button>
</div>
                </div>
              </form>
            </div> : "" 

          }

            <div className="bg-white p-2 mt-2 rounded shadow-sm">


{!mode ?
            <button onClick={e=>setMode(true)} className="mb-2 btn-disabled border-slate-600 text-slate-600 hover:bg-slate-500/10  cursor-pointer p-2 w-full out-btn rounded">
                Bilgileri Değiştir
              </button>
: ""  }
              <button className="d-none btn-disabled border-green-600 text-green-600 hover:bg-green-500/10  cursor-pointer p-2 w-full out-btn rounded">
                Şifre Değiştir
              </button>
              <Link
                onClick={(e) => {
                  if (
                    !window.confirm("Çıkış Yapmak istediğinize emin misiniz")
                  ) {
                    e.preventDefault();
                  }
                }}
                to="/cikis"
                className="d-block w-full mt-2 text-center btn-disabled border-red-600 text-red-600 hover:text-red-600 hover:bg-red-500/10  cursor-pointer p-2 w-full out-btn rounded"
              >
                Çıkış Yap
              </Link>
            </div>
          </div>

          <div className="col-lg-6 col-12">
            {data ? (
              <div className="flex-column justify-center items-center flex bg-white rounded shadow-sm p-2">
                <div className="font-medium text-4xl text-blue-500 lh-1 text-center">
                  {data.interval}
                  <span className="text-lg text-slate-400 lh-1">gün</span>
                </div>
                <div className="font-medium">kaldı</div>

                <hr />
                <div className="row w-full mt-3">
                  <div className="col-6">
                    <div className="flex-column justify-center items-center1">
                      <div className="font-medium text-xl  text-center  lh-1">
                        {data.data[0].kayit}
                      </div>
                      <div className="font-medium text-slate-400 text-sm text-center">
                        Kayıt
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="flex-column justify-center items-center ">
                      <div className="font-medium text-xl  lh-1 text-center">
                        {data.data[0].bitis}
                      </div>
                      <div className="font-medium text-slate-400 text-sm text-center">
                        Bitiş
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hesabim;
