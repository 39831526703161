import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { tarih } from "../iskonto";
import MobileHeader from "../components/MobileHeader";

function Stok() {
  let navigate = useNavigate();

  const start = useRef();
  const bitis = useRef();

  const [tab, setTab] = useState("stok");
  const [stok, setStok] = useState(null);
  const [urunler, setUrunler] = useState(null);
  const [order, setOrder] = useState("urun_isim ASC");
  const [dusukUrunler, setDusukUrunler] = useState(null);

  const [ayarSwitch, setAyarSwitch] = useState(false);

  useEffect(() => {
    axios
      .get(`/urunler/${localStorage.okul_id}/?order=${order}`)
      .then((res) => {
        setUrunler(res.data);
      });

    axios.get(`/getDusukStokUrunler/${localStorage.okul_id}`).then((res) => {
      setDusukUrunler(res.data);
    });

    axios.get(`/get_stok_belge/${localStorage.okul_id}`).then((res) => {
      setStok(res.data);
    });
  }, []);

  function tarihSearch(e) {
    e.preventDefault();

    if (start.current.value == "") {
      alert("Başlangıç tarihi giriniz");
      return;
    }

    if (bitis.current.value == "") {
      alert("Bitiş tarihi giriniz");
      return;
    }
    e.target[2].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";
    setStok(null);

    axios
      .get(
        `/get_stok_belge/${localStorage.okul_id}/?start=${start.current.value}&bitis=${bitis.current.value}`
      )
      .then((res) => {
        setStok(res.data);
        e.target[2].innerHTML = '<i class="fa-solid fa-magnifying-glass"></i>';
      });
  }

  return (
    <div className="screen">
      <div className="page">
      <MobileHeader/>
        <div className="mb-2">
          <div className="shadow-sm  flex items-center bg-white rounded-2 px-2">
            <div
              onClick={(e) => setTab("stok")}
              className={`cursor-pointer ${
                tab == "stok" ? "text-primary" : ""
              } hover:text-black  hover:bg-slate-100 py-2 flex-fill text-center font-medium`}
            >
              Stok
            </div>
            <div
              onClick={(e) => {
                setTab("giris");
              }}
              className={`cursor-pointer ${
                tab == "giris" ? "text-primary" : ""
              } border-x-2 hover:bg-slate-100 py-2 flex-fill text-center font-medium`}
            >
              Stok Girişleri
            </div>
          </div>
        </div>

        {tab == "giris" ? (
          <>
            <div className="mt-2 bg-white rounded p-2 shadow kutu">
              <div className="row g-2 mb-2">
                <div className="col-2">
                  <Link
                    to={"/tedarikciler?stok"}
                    className="d-block p-2 text-center hover:text-gray-700 hover:bg-blue-300 font-medium rounded bg-blue-200 text-blue-600"
                  >
                    <i className="fa-solid fa-plus"></i> Stok Gir
                  </Link>
                </div>
                <div className="col-4"> 
                  <div className="flex px-2 	 bg-slate-100 rounded items-center">
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      onChange={(e) => {
                        var input, filter, table, tr, td, i, txtValue;
                        input = e.target;
                        filter = input.value.toUpperCase();
                        table = document.querySelector(".table tbody");
                        tr = table.querySelectorAll("tr");

                        console.log(tr);

                        for (i = 0; i < tr.length; i++) {
                          td = tr[i];
                          if (td) {
                            txtValue = td.textContent || td.innerText;
                            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                              tr[i].style.display = "";
                            } else {
                              tr[i].style.display = "none";
                            }
                          }
                        }
                      }}
                      type="text"
                      className="noout bg-transparent font-medium p-2 w-full"
                      placeholder="Ara"
                    />
                  </div>
                </div>

                <div className="col-6">
                  <form onSubmit={(e) => tarihSearch(e)} className="flex">
                    <input
                      ref={start}
                      type="date"
                      className="bg-slate-100 p-2 border-l-5 rounded me-2"
                    />
                    <input
                      ref={bitis}
                      type="date"
                      className="bg-slate-100 p-2 rounded "
                    />
                    <button
                      type="submit"
                      className="bg-primary text-xs p-2 rounded flex-fill ms-2 "
                    >
                      <i className="fa-solid fa-magnifying-glass "></i>{" "}
                    </button>
                  </form>
                </div>
              </div>

              <div className="max-scroll">
                {stok ? (
                  <>
                    <table className="table w-full">
                      <thead>
                        <tr>
                          <td className="font-bold">Tedarikçi</td>
                          <td className="font-bold">Belge No</td>
                          <td className="font-bold">Tutar</td>
                          <td className="font-bold">Açıklama</td>
                          <td className="font-bold text-end">Tarih</td>
                        </tr>
                      </thead>
                      <tbody>
                        {stok
                          ? stok.map((val, i) => {
                              return (
                                <tr
                                  key={i}
                                  className="cursor-pointer hover:bg-slate-100"
                                  onClick={(e) =>
                                    navigate(`/stok-detay/${val.sg_id}`)
                                  }
                                >
                                  <td className="border-0 font-medium">
                                    {val.isim}
                                  </td>
                                  <td className="border-0 italic text-slate-400 font-medium">
                                    {val.belge_no}
                                  </td>
                                  <td className="border-0 font-medium text-green-500">
                                    {val.toplam}
                                  </td>
                                  <td className="border-0 text-slate-400">
                                    {val.aciklama
                                      ? val.aciklama
                                      : "Açıklama Girilmemiş"}
                                  </td>
                                  <td className="border-0 text-end">
                                    {tarih(val.giris_tarihi, false)}
                                  </td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>

                    {stok && stok.length == 0 ? (
                      <div className="p-2 text-center bg-amber-500/50 font-medium rounded">
                        Belirtilen tarih aralığına göre veri bulunamadı.
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <td className="font-bold">Tedarikçi</td>
                        <td className="font-bold">Belge No</td>
                        <td className="font-bold">Tutar</td>
                        <td className="font-bold">Açıklama</td>
                        <td className="font-bold text-end">Tarih</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="cursor-pointer hover:bg-slate-100">
                        <td className="border-0 font-medium">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 italic text-slate-400 font-medium">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 font-medium text-green-500">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 text-slate-400">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 text-end">
                          <div className="skeleton d-block"></div>
                        </td>
                      </tr>

                      <tr className="cursor-pointer hover:bg-slate-100">
                        <td className="border-0 font-medium">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 italic text-slate-400 font-medium">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 font-medium text-green-500">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 text-slate-400">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 text-end">
                          <div className="skeleton d-block"></div>
                        </td>
                      </tr>

                      <tr className="cursor-pointer hover:bg-slate-100">
                        <td className="border-0 font-medium">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 italic text-slate-400 font-medium">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 font-medium text-green-500">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 text-slate-400">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 text-end">
                          <div className="skeleton d-block"></div>
                        </td>
                      </tr>

                      <tr className="cursor-pointer hover:bg-slate-100">
                        <td className="border-0 font-medium">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 italic text-slate-400 font-medium">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 font-medium text-green-500">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 text-slate-400">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 text-end">
                          <div className="skeleton d-block"></div>
                        </td>
                      </tr>

                      <tr className="cursor-pointer hover:bg-slate-100">
                        <td className="border-0 font-medium">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 italic text-slate-400 font-medium">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 font-medium text-green-500">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 text-slate-400">
                          <div className="skeleton d-block"></div>
                        </td>
                        <td className="border-0 text-end">
                          <div className="skeleton d-block"></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {tab == "stok" ? (
          <>
            <div className="row g-2 mt-2">
              {urunler && dusukUrunler ? (
                <div className="col-lg-8 col-12">
                  <div className=" bg-white rounded p-2 shadow kutu">
                    <div className="max-scroll">
                      <table className="table w-full">
                        <thead>
                          <tr>
                            <td className="font-bold">Ürün</td>
                            <td className="font-bold">Stok</td>
                            <td className="font-bold">Barkod</td>
                            <td className="font-bold text-end">Kategori</td>
                          </tr>
                        </thead>
                        <tbody>
                          {urunler.map((val, i) => {
                            return (
                              <tr key={i} className={` hover:bg-slate-100  `}>
                                <td className=" font-medium border-0">
                                  {val.urun_isim}
                                </td>
                                <td
                                  className={`${
                                    Number(val.stok) <= dusukUrunler.rakam
                                      ? "text-red-400"
                                      : "text-green-400"
                                  }  font-medium border-0`}
                                >
                                  {val.stok}
                                </td>
                                <td className="border-0 font-medium text-slate-500">
                                  {val.barkod}
                                </td>
                                <td className="text-end border-0  font-medium">
                                  {val.kategori}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-lg-8 col-12">
                  <div className=" bg-white rounded p-2 shadow kutu">
                    <div className="max-scroll">
                      <table className="table w-full">
                        <thead>
                          <tr>
                            <td className="font-bold">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-bold">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-bold">
                              <div className="skeleton d-block"></div>
                            </td>
                            <td className="font-bold text-end">
                              <div className="skeleton d-block"></div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {[...Array(5).keys()].map((val, i) => {
                            return (
                              <tr key={i} className={` hover:bg-slate-100  `}>
                                <td className=" font-medium border-0">
                                  <div className="skeleton d-block"></div>
                                </td>
                                <td
                                  className={`
                                    font-medium border-0`}
                                >
                                  <div className="skeleton d-block"></div>
                                </td>
                                <td className="border-0 font-medium text-slate-500">
                                  <div className="skeleton d-block"></div>
                                </td>
                                <td className="text-end border-0  font-medium">
                                  <div className="skeleton d-block"></div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {dusukUrunler ? (
                <div className="col-lg-4 col-12">
                  <div className=" bg-white rounded shadow kutu">
                    <div className="p-2 rounded-t bg-red-400/25 flex mb-2 items-center justify-between">
                      <div className="font-medium text-red-500">
                        <i class="fa-solid fa-circle-exclamation"></i> Düşük
                        Stoklar
                      </div>
                      <i
                        onClick={(e) => setAyarSwitch(!ayarSwitch)}
                        className="fa-solid  fa-gear text-slate-500 hover:text-gray-700 cursor-pointer"
                      ></i>
                    </div>

                    <div className="p-2">
                      {!ayarSwitch ? (
                        <div
                          onClick={(e) => setAyarSwitch(!ayarSwitch)}
                          className="cursor-pointer lh-1 text-slate-400 text-sm font-medium mb-2"
                        >
                          Stok adedi {dusukUrunler.rakam} altındaki ürünler
                          gösteriliyor.
                        </div>
                      ) : (
                        ""
                      )}
                      {ayarSwitch ? (
                        <div className="rounded bg-slate-100 p-2 mb-2 in">
                          <div className="font-medium text-sm">
                            Düşük Stok Adedi
                          </div>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              e.target[1].innerHTML =
                                "<i class='fa-solid fa-spin fa-spinner '></i>";
                              axios
                                .post(
                                  `/update/okul_ayar/to_okul/${localStorage.okul_id}/?str=dusuk_stok = "${e.target[0].value}" `
                                )
                                .then((res) => {
                                  window.location.reload();
                                });
                            }}
                          >
                            <div className="row g-2">
                              <div className="col-6">
                                <input
                                  min={1}
                                  defaultValue={dusukUrunler.rakam}
                                  type="number"
                                  className="rounded d-block w-full py-1 px-2"
                                />
                              </div>
                              <div className="col-6">
                                <button className="py-1 px-2 rounded font-medium bg-blue-400 w-full text-white hover:bg-blue-500">
                                  Kaydet
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      ) : (
                        ""
                      )}
                      {dusukUrunler.urunler.length == 0 ? (
                        <div className="p-2 text-center bg-amber-500/50 font-medium rounded">
                          Düşük Stoklu Ürün Yok
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="max-scroll">
                        {dusukUrunler.urunler.map((val, i) => {
                          return (
                            <div
                              title={val.barkod}
                              key={i}
                              className="font-medium flex items-center  justify-between"
                            >
                              <div className="lh-1">{val.urun_isim}</div>
                              <div className="text-red-500">{val.stok}</div>
                            </div>
                          );
                        })}
                      </div>

                      <Link
                        to={"/tedarikciler?stok"}
                        className="d-block p-2 mt-2 text-center hover:bg-blue-300 hover:bg-blue-500 font-medium rounded bg-blue-200 text-blue-600"
                      >
                        <i className="fa-solid fa-plus"></i> Stok Gir
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-4">
                  <div className=" bg-white rounded shadow kutu">
                    <div className="p-2 rounded-t bg-red-400/25 flex mb-2 items-center justify-between">
                      <div className="font-medium text-red-500">
                        <div className="skeleton px-5"></div>
                      </div>
                      <div className="skeleton px-3"></div>
                    </div>

                    <div className="p-2">
                      <div className="max-scroll">
                        {[...Array(5).keys()].map((val, i) => {
                          return (
                            <div
                              key={i}
                              className="font-medium flex items-center  justify-between"
                            >
                              <div className="lh-1">
                                <div className="skeleton px-5"></div>
                              </div>
                              <div className="text-red-500">
                                <div className="skeleton px-5"></div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Stok;
