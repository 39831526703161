import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MobileHeader from "../components/MobileHeader";

function BakiyeYukle() {
  const [ogrenci, setOgrenci] = useState(null);
  let params = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    axios.get(`/getOgrenciID/${params.id}`).then((res) => {
      console.log(res);
      setOgrenci(res.data);
    });
  }, []);

  function yukle(e) {
    e.preventDefault();
    if (!window.confirm("Emin misiniz?")) {
      return;
    }
    e.target[2].innerHTML = "<i class='fa-solid fa-spin fa-spinner'></i>";
    axios
      .post(`/perBakiyeYukle`, {
        per: localStorage.user_id,
        bolge: localStorage.bolge_id,
        odeme: e.target[1].value,
        ogrenci: params.id,
        miktar: e.target[0].value,
        okul: localStorage.okul_id,
      })
      .then((res) => {
        alert("Başarılı");
        navigate("/");
      });
  }

  return (
    <div className="screen">
      <div className="page-min">
      <MobileHeader/>
        {ogrenci != null ? (
          <div className="row g-2">
            <div className="col-6">
              <div className="bg-white p-2 rounded shadow-sm">
                <>
                  <div className="text-center font-medium mb-2">
                    Bakiye Yükle
                  </div>
                  <div className="flex justify-center">
                    <img
                      loading="lazy"
                      src={
                        ogrenci.resim != ""
                          ? ogrenci.resim
                          : axios.defaults.base + "assets/img/profil.jpg"
                      }
                      className="w-36 h-36 object-cover rounded-full"
                      alt=""
                    />
                  </div>
                  <div className="font-medium text-center text-lg">
                    {ogrenci.isim}
                  </div>
                  <div className="font-medium text-center text-slate-400">
                    {ogrenci.tc}
                  </div>
                  <div className="font-medium text-center text-lg  fiyat-text text-green-600">
                    {ogrenci.bakiye} ₺
                  </div>{" "}
                </>
              </div>

              <div className="hidden bg-white p-2 mt-2 rounded shadow-sm">
                <button className="btn-disabled border-green-600 text-green-600 hover:bg-green-500/10  cursor-pointer p-2 w-full out-btn rounded">
                  Öğrenci Değiştir
                </button>
              </div>
            </div>

            <div className="col-6">
              <div className="bg-white p-2 rounded shadow-sm">
                <form onSubmit={(e) => yukle(e)}>
                  <div className="row g-2">
                    <div className="col-12">
                      <div className="font-medium text-sm">
                        Yüklenecek Bakiye
                      </div>

                      <input
                        min={1}
                        required
                        placeholder="Yüklenecek Bakiye"
                        type="number"
                        className="w-full font-medium rounded p-2 bg-slate-100"
                      />
                    </div>

                    <div className="col-12">
                      <div className="font-medium text-sm">Ödeme Tipi</div>
                      <select className="w-full font-medium rounded p-2 bg-slate-100">
                        <option selected value="Nakit">
                          Nakit
                        </option>
                        <option value="K. Kartı">K. Kartı</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <div className="text-sm lh-1 font-medium text-slate-400">
                        *Bakiye yüklendiğine dair veliye onay e-postası
                        gidecektir.
                      </div>
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">
                      <button className="font-medium p-2 w-full rounded bg-green-500">
                        Yükle
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className="row g-2">
          <div className="col-6">
            <div className="bg-white p-2 rounded shadow-sm">
              <>
                
                <div className="flex justify-center">
                <div className="skeleton d-block h-36 w-36 d-block mb-2 rounded-full"></div>
                </div>
                <div className="font-medium text-center text-lg">
                <div className="skeleton d-block mb-2"></div>
                </div>
                <div className="font-medium text-center text-slate-400">
                <div className="skeleton d-block mb-2"></div>
                </div>
                <div className="font-medium text-center text-lg  fiyat-text text-green-600">
                <div className="skeleton d-block mb-2"></div>
                </div>{" "}
              </>
            </div>

            <div className="hidden bg-white p-2 mt-2 rounded shadow-sm">
            <div className="skeleton d-block"></div>
            </div>
          </div>

          <div className="col-6">
            <div className="bg-white p-2 rounded shadow-sm">
              <form onSubmit={(e) => yukle(e)}>
                <div className="row g-2">
                  <div className="col-12">
                    
                    <div className="skeleton d-block"></div>
                  </div>

                  <div className="col-12">
                    <div className="skeleton d-block"></div>
                  </div>
                  <div className="col-12">
                    <div className="text-sm lh-1 font-medium text-slate-400">
                    <div className="skeleton d-block"></div>
                    </div>
                  </div>
                  <div className="col-6"></div>
                  <div className="col-6">
                   <div className="skeleton d-block"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}

export default BakiyeYukle;
