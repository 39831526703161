import React from "react";
import { Link } from "react-router-dom";
import MobileHeader from "../../components/MobileHeader";



function RaporIndex() {
  const stil = "d-block p-2 bg-zinc-50 hover:text-blue-500 hover:bg-slate-200 rounded font-medium text-center"
  return (
    <div className="screen">
      <div className="page">
      <MobileHeader/>
        <div className="mt-2 ">
          <div className="row g-2">
            <div className="col-md-4 col-12">
              <Link
                className={stil}
                to={"/gunluk-rapor"}
              >
                Günlük Rapor
              </Link>
            </div>

              <div className="col-md-4 col-12">
              <Link
                 className={stil}
                to={"/aylik-rapor"}
              >
                Aylık Satış Raporu
              </Link>
            </div>

            <div className="col-md-4 col-12">
              <Link
                 className={stil}
                to={"/z-raporlari"}
              >
                Z Raporu
              </Link>
            </div>

            <div className="col-md-4 col-12">
              <Link
                 className={stil}
                to={"/siparisler"}
              >
                Siparişler
              </Link>
            </div>

            <div className="col-md-4 col-12">
              <Link
                className={stil}
                to={"/ogrenciler?sec"}
              >
                Öğrenci Bazlı Rapor
              </Link>
            </div>

            
            <div className="col-md-4 col-12">
              <Link
                 className={stil}
                to={"/urun-rapor"}
              >
                Ürün Bazlı Rapor
              </Link>
            </div>

                 
            <div className="col-md-4 col-12">
              <Link
                className={stil}
                to={"/bakiye-rapor"}
              >
                Yüklenen Bakiyeler
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RaporIndex;

 