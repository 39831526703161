import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {


  if (localStorage.okul_id) {
    return (
      <div className="py-4 ps-3 hidden lg:block position-fixed h-full top-0 left-0">
        <div className="flex flex-col items-center w-64 h-full overflow-hidden text-gray-700 bg-white rounded">
          <a className="hidden items-center w-full px-3 mt-3" href="#">
            <svg
              className="w-8 h-8 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
            </svg>
            <span className="ml-2 text-sm font-bold">Kantin</span>
          </a>

          <div className="w-full px-2">
            <div className="flex flex-col items-center w-full  border-gray-300">
              <Link
                className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-gray-300"
                to="/"
              >
                <i
                  data-tip="Ürünler"
                  className="fa-regular  fa-address-card text-gray-500 h-text-primary text-2xl cursor-pointer "
                ></i>

                <span className="ml-2 text-sm font-medium">Satış </span>
              </Link>
              <Link
                className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-gray-300"
                to="/urunler"
              >
                <i
                  data-tip="Ürünler"
                  className="fa-solid fa-utensils  text-gray-500 h-text-primary text-2xl cursor-pointer "
                ></i>

                <span className="ml-2 text-sm font-medium">Ürünler</span>
              </Link>
              <div className="flex flex-col items-center w-full mt-2">
              <Link
                className="flex items-center w-full h-12 px-3 hover:text-zinc-900  rounded hover:bg-gray-300"
                to="/ogrenciler"
              >
                <i
                  data-tip="Öğrenciler & Gruplar"
                  className="fa-solid fa-user text-gray-500    h-text-primary text-2xl cursor-pointer "
                ></i>

                <span className="ml-2 text-sm font-medium">Öğrenciler</span>
              </Link>
            </div>
              <Link
                className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-gray-300"
                to="/raporlar"
              >
                <i className="fa-solid fa-receipt  text-gray-500 h-text-primary text-2xl cursor-pointer "></i>

                <span className="ml-2 text-sm font-medium">Rapor</span>
              </Link>
              <Link
                className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-gray-300"
                to="/stok?stok"
              >
                <i
                  data-tip="Stok & Satılan"
                  className="fa-solid fa-box  text-gray-500 h-text-primary text-2xl cursor-pointer "
                ></i>

                <span className="ml-2 text-sm font-medium">Stok</span>
              </Link>
              
              <Link
                className="flex items-center w-full h-12 px-3  rounded hover:bg-gray-300"
                to="/tedarikciler"
              >
                <i className="fa-solid fa-truck-field text-gray-500 h-text-primary text-2xl cursor-pointer "></i>

                <span className="ml-2 text-sm font-medium">Tedarikçiler</span>
              </Link>

               <Link
                className="flex items-center w-full h-12 px-3  rounded hover:bg-gray-300"
                to="/duyuru"
              >
                <i className="fa-solid fa-bullhorn text-gray-500 h-text-primary text-2xl cursor-pointer "></i>

                <span className="ml-2 text-sm font-medium">Duyuru</span>
              </Link>
            </div>
      

            <div className="flex flex-col items-center w-full mt-2">
              <Link
                className="flex items-center w-full h-12 px-3 hover:text-zinc-900  rounded hover:bg-gray-300"
                to="/hareketler"
              >
                <i
  
                  className="fa-solid fa-clock-rotate-left text-gray-500    h-text-primary text-2xl cursor-pointer "
                ></i>

                <span className="ml-2 text-sm font-medium">Son Hareketler</span>
              </Link>
            </div>

{localStorage.admin == "true" ? 
            <div className="flex flex-col items-center w-full mt-2">
              <Link
                className="flex items-center w-full h-12 px-3 hover:text-zinc-900  rounded hover:bg-gray-300"
                to="/kullanicilar"
              >
                <i
                  data-tip="Öğrenciler & Gruplar"
                  className="fa-solid fa-user-gear text-gray-500    h-text-primary text-2xl cursor-pointer "
                ></i>

                <span className="ml-2 text-sm font-medium">
                  Yetkililer & Bölgeler
                </span>
              </Link>
            </div> : "" }
          </div>


          <Link
                onClick={(e) => {
                  if (
                    !window.confirm("Çıkış Yapmak istediğinize emin misiniz")
                  ) {
                    e.preventDefault();
                  }
                }}
                to="/cikis"
                className="mt-auto d-block mx-2 w-[90%] mb-2 mt-2 text-center btn-disabled border-red-600 text-red-600 hover:text-red-600 hover:bg-red-500/10  cursor-pointer p-2 w-full out-btn rounded"
              >
                <i className="fa-solid fa-right-from-bracket"></i> Çıkış Yap
              </Link>
          <Link
            className="hidden items-center justify-center w-full h-16  bg-gray-200 hover:bg-gray-300"
            to="/hesabim"
          >
            <i className="fa-solid ms-2 fa-user text-gray-500 h-text-primary  cursor-pointer "></i>
            <span className="ml-2 text-sm font-medium">Ayarlar</span>
          </Link>
        
        </div>
      </div>
    );
  }
}

export default Sidebar;
