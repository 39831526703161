import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import MainShow from "../components/MainShow";
import MobileHeader from "../components/MobileHeader";
import { iskonto } from "../iskonto";
function Index() {
  const [placeholder, setPlaceholder] = useState("Buraya Tıklayınız");
  const [kategoriler, setKategoriler] = useState(null);
  const [ogrenci, setOgrenci] = useState(null);
  const [sepet, setSepet] = useState([]);
  const [satisb, setSatisB] = useState(0);
  const [buyable, setBuyable] = useState(true);
  const [carpan, setCarpan2] = useState(1);
  const [fiyatlar, setFiyatlar] = useState(null);
  const [siparisler, setSiparisler] = useState(null);
  const [iadeMode, setIadeMode] = useState(false);

  const box = useRef();
  const araRef = useRef();
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.code == "Space") {
        focus();
      }
    });

    axios.get(`/getKategoriler/${localStorage.okul_id}`).then((res) => {
      setKategoriler(res.data);
    });
  }, []);

  function focus(e) {
    document.getElementById("search-input").focus();
  }
  useEffect(() => {
    //sepetiBosalt();
  }, [ogrenci]);

  function get(e) {
    e.preventDefault();
    setSatisB(0);
    setSepet([]);

    if (e.target[0].value != "") {
      setOgrenci([]);
      axios
        .get(
          `/getOgrenci/${e.target[0].value.split(" ").join("")}/${
            localStorage.okul_id
          }`
        )
        .then((res) => {
          console.log(res);
          if (res.data.length != 0) {
            setOgrenci(res.data);
            document.querySelector("#urun_ara").focus();

            axios
              .get(`/ogrenciSiparisler/${res.data[0].ogrenci_id}`)
              .then((resx) => {
                setSiparisler(resx.data);
                console.log("S");
                console.log(resx.data);
              });
          } else {
            setOgrenci(null);
          }

          e.target[0].value = "";
        })
        .catch((errr) => {
          setOgrenci(null);
          e.target[0].value = "";
        });
    }
  }

  useEffect(() => {
    if (ogrenci == null || ogrenci.length == 0) {
      return;
    }
    var toplam = iskonto(
      sepet.reduce((a, b) => +a + +b.fiyat * b.adet, 0),
      ogrenci[0].iskonto,
      ogrenci[0].tur
    );

    if (toplam > ogrenci.harcama) {
      console.log("harcama");
      setBuyable(false);
    } else {
      setBuyable(true);
    }
  }, [sepet]);

  function setCarpan(e, val) {
    document.querySelectorAll(".carpan").forEach((el) => {
      el.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
    setCarpan2(val);
  }

  function sepeteEkle(e, objs) {
    const _e = e.currentTarget;
    e.currentTarget.style.opacity = "0.5";
    axios.post(`/sepeteEkle/${localStorage.user_id}`, objs).then((res) => {
      _e.style.opacity = "1";
      setSepet(res.data);
    });

    console.log(sepet);
  }

  function sepetiBosalt() {
    setSepet([]);
  } 

  
  function manuelSatis(e, type) {
    e.target.setAttribute("disabled", "true");
    e.target.innerHTML = "<i class='fa-spin fa-spinner fa-solid'></i>";

    var toplam = iskonto(
      sepet.reduce((a, b) => +a + +b.fiyat * b.adet, 0),
      ogrenci[0].iskonto,
      ogrenci[0].tur
    );

    console.log(toplam);
    axios
      .post("/manuelSatis", {
        okul: localStorage.okul_id,
        ogrenci: ogrenci[0].ogrenci_id,
        toplam: toplam,
        sepet: sepet,
        type: type,
        bolge_id: localStorage.bolge_id,
        user_id: localStorage.user_id,
      })
      .then((res) => {
        if (res.data == "ok") {
          sepetiBosalt();
          setOgrenci(null);
          setSatisB(1);
          focus();
        }
      });
  }

  function satis(e) {
    e.target.setAttribute("disabled", "true");
    e.target.innerHTML = "<i class='fa-spin fa-spinner fa-solid'></i>";

    var toplam = iskonto(
      sepet.reduce((a, b) => +a + +b.fiyat * b.adet, 0),
      ogrenci[0].iskonto,
      ogrenci[0].tur
    );

    console.log(toplam);
    axios
      .post("/satis", {
        okul: localStorage.okul_id,
        ogrenci: ogrenci[0].ogrenci_id,
        toplam: toplam,
        sepet: sepet,
        type: "Bakiye",
        bolge_id: localStorage.bolge_id,
        user_id: localStorage.user_id,
      })
      .then((res) => {
        console.log(res);
        e.target.innerHTML = "Bakiye";
        if (res.data == "bakiye_yetersiz") {
          alert("Yetersiz bakiye");
          e.target.removeAttribute("disabled");
        }
        if (res.data == "ok") {
          sepetiBosalt();
          setOgrenci(null);
          setSatisB(1);
          focus();
        }
      });
  }

  return (
    <div className="screen">
      <div className="page-big">
      <MobileHeader/>

        <form onSubmit={(e) => get(e)} className="search hidden lg:block  p-1 rounded">
          <div
            ref={box}
            className="p-2 search-border  flex justify-between items-center"
          >
            <i className="fa-solid fa-magnifying-glass text-white text-2xl"></i>
            <input
              tabIndex={0}
              type="password"
              id="search-input"
              placeholder={placeholder}
              onFocus={(e) => {
                setPlaceholder("Kartı Okutunuz");
                box.current.classList.add("parlat");
                box.current.style.borderColor = "#22c55e";
              }}
              onBlur={(e) => {
                setPlaceholder("Buraya tıklayınız veya boşluk tuşuna basınız");
                box.current.classList.remove("parlat");
                box.current.style.borderColor = "white";
              }}
              onKeyDown={(e) => {
                if (e.code == "Space") {
                  e.preventDefault();
                }
              }}
              className="text-lg flex-fill text-center bg-transparent"
            />
            <div></div>
          </div>
        </form>

        {satisb == 1 ? (
          <div className="flex-column bg-green-400 rounded-lg mt-4 py-5 flex justify-center items-center">
            <i className="fa-solid fa-check text-8xl text-green-800"></i>
            <div className="text-green-800 font-bold text-4xl">
              Satış Başarılı
            </div>
          </div>
        ) : (
          <></>
        )}

        {ogrenci == null ? (
          <div className="mt-2 row g-2">
            <MainShow
              to={"/urunler"}
              icon="fa-utensils"
              text="Ürünler"
              desc=" Ürünlerinizi ekleyin veya yönetin."
            />

            <MainShow
              to={"/ogrenciler"}
              icon="fa-user"
              text="Öğrenciler"
              desc="Öğrencileri veya grupları yönetin"
            />

            <MainShow
              to={"/stok"}
              icon="fa-box"
              text="Stok Takibi"
              desc="Stok girişi yapın veya stok hareketlerini inceleyin"
            />

            <MainShow
              to={"/raporlar"}
              icon="fa-receipt"
              text="Rapor"
              desc="Raporlama"
            />
          </div>
        ) : (
          <div className="row mt-3 g-3">
            {}
            <div className="col-7">
              <div>
                <div className="bg-white rounded p-2 shadow kutu">
                  <div className="row">
                    <div className="col-4">
                      {ogrenci.length == 0 ? (
                        <div className="h-full-important w-full skeleton"></div>
                      ) : ogrenci[0].resim != "" ? (
                        <img
                          className="img-fluid rounded-lg h-48 object-cover w-full"
                          src={ogrenci[0].resim}
                          alt=""
                        />
                      ) : (
                        <div className="img-fluid rounded-lg h-48 object-cover bg-slate-300 flex items-center select-none	 justify-center text-[8rem] font-bold text-white w-full">
                          {ogrenci[0].isim.split("")[0].toUpperCase()}
                        </div>
                      )}
                    </div>

                    <div className="col-8">
                      {ogrenci.length == 0 ? (
                        <div className="h-full p-4 flex flex-column justify-between">
                          <div className="skeleton"></div>
                          <div className="skeleton w-25 mt-5"></div>
                        </div>
                      ) : (
                        <>
                          <div className="h-full p-4 flex flex-column justify-between">
                            <div>
                              <div className="lh-1 font-medium text-slate-400">
                                {ogrenci[0].grup_ismi}
                              </div>
                              <div className="font-bold text-3xl text-primary lh-1">
                                {ogrenci[0].isim}
                                <span className="text-zinc-700">
                                  {" "}
                                  - {ogrenci[0].numara}
                                </span>
                              </div>
                            </div>

                            <div>
                              <div className="text-primary fiyat-text lh-1 font-bold text-3xl">
                                {ogrenci[0].bakiye} ₺
                              </div>
                              <div className="font-medium text-xl ">
                                Güncel Bakiye
                              </div>
                              <div className="font-medium lh-1 ">
                                Kalan Günlük Harcama Limiti:{" "}
                                <span className="text-primary fiyat-text">
                                  {ogrenci.harcama} ₺
                                </span>
                              </div>
                            </div>

                           
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {ogrenci.length != 0 ? (
                  <div className="row g-2 mt-1">
                    <div className="col-4">
                      <Link
                        to={`/bakiye-yukle/${ogrenci[0].ogrenci_id}`}
                        className="hover:text-zinc-900 text-center hover:bg-sky-400 rounded  bg-sky-300 font-medium p-2 w-full d-block"
                      >
                        Bakiye Yükle
                      </Link>
                    </div>

                    <div className="col-4">
                      <button
                        onClick={(e) => setIadeMode(!iadeMode)}
                        className="hover:bg-sky-400 rounded  bg-sky-300 font-medium p-2 w-full d-block"
                      >
                        İade
                      </button>
                    </div>

                    <div className="col-4">
                      <Link
                        to={`/ogrenci-rapor/${ogrenci[0].ogrenci_id}`}
                        className="text-center hover:text-zinc-900 hover:bg-sky-400 rounded  bg-sky-300 font-medium p-2 w-full d-block"
                      >
                        Rapor
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {ogrenci.length != 0 && iadeMode ? (
                  <div className="in mt-2 rounded bg-white shadow p-2">
                    <div className="text-center font-medium">
                      {ogrenci[0].isim} son 15 sipariş
                    </div>
                    <div className="text-sm font-medium text-slate-400 lh-1 text-center">
                      İade yapılacak siparişi seçiniz
                    </div>

                    {siparisler ? (
                      <div className="mini-scroll">
                        {" "}
                        <table className="table w-full">
                          <thead>
                            <tr>
                              <th>Ürün</th>
                              <th>Adet</th>
                              <th>Fiyat</th>
                              <th>Ödeme</th>
                            </tr>
                          </thead>
                          <tbody>
                            {siparisler.map((val) => {
                              return (
                                <tr
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      window.confirm(
                                        "Bu ürün stoğunuza tekrar eklenip, ücreti satın alan kişinin bakiyesine geri yüklenecektir. İade etmek istediğinize emin misiniz?"
                                      )
                                    ) {
                                      console.dir(e.currentTarget);
                                      const c = e.currentTarget;
                                      c.style.opacity = "0.5";
                                      axios
                                        .post(`/iade/${val.siparis_id}`, {
                                          user_id: localStorage.user_id,
                                          ogrenci: ogrenci
                                            ? ogrenci[0].ogrenci_id
                                            : "",
                                        })
                                        .then((res) => {
                                          console.log(res);
                                          if (res.data == "ok") {
                                            alert("İade işlemi başarılı");
                                            c.remove();
                                          }
                                        });
                                    }
                                  }}
                                  className="hover:bg-slate-200 cursor-pointer"
                                  key={val.siparis_id}
                                >
                                  <td className="font-medium">{val.urun}</td>
                                  <td className="text-slate-400 font-medium">
                                    {val.adet}
                                  </td>
                                  <td className="font-medium text-green-400">
                                    {val.fiyat}
                                  </td>
                                  <td className="text-sm text-slate-400">
                                    {val.odeme}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                <div className="mt-2 bg-white rounded p-2 shadow kutu">
                  <div className="flex flex-wrap mb-2">
                    <div
                      onClick={(e) => {
                        var filter, table, tr, td, i, txtValue;
                        filter = "".toUpperCase();
                        table = document.querySelector(".u-list");
                        tr = table.querySelectorAll(".u-list-name");

                        for (i = 0; i < tr.length; i++) {
                          td = tr[i];

                          if (td) {
                            txtValue = td.textContent || td.innerText;
                            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                              tr[i].parentElement.parentElement.style.display =
                                "";
                            } else {
                              tr[i].parentElement.parentElement.style.display =
                                "none";
                            }
                          }
                        }
                      }}
                      className="mb-1 bg-cyan-500/50 font-medium p-2 mr-1  cursor-pointer hover:bg-cyan-500 rounded"
                    >
                      Tümü
                    </div>
                    {kategoriler &&
                      kategoriler.map((val, i) => {
                        return (
                          <div
                            onClick={(e) => {
                              var filter, table, tr, td, i, txtValue;
                              filter = val.kategori.toUpperCase();
                              table = document.querySelector(".u-list");
                              tr = table.querySelectorAll(".u-list-name");

                              for (i = 0; i < tr.length; i++) {
                                td = tr[i];

                                if (td) {
                                  txtValue = td.textContent || td.innerText;
                                  if (
                                    txtValue.toUpperCase().indexOf(filter) > -1
                                  ) {
                                    tr[
                                      i
                                    ].parentElement.parentElement.style.display =
                                      "";
                                  } else {
                                    tr[
                                      i
                                    ].parentElement.parentElement.style.display =
                                      "none";
                                  }
                                }
                              }
                            }}
                            className="mb-1 bg-cyan-500/50 font-medium p-2 mr-1  cursor-pointer hover:bg-cyan-500 rounded"
                            key={i}
                          >
                            {val.kategori}
                          </div>
                        );
                      })}
                  </div>

                  <div className="position-relative bg-slate-200 rounded flex items-center p-1 mb-2">
                    <i className="fa-solid fa-magnifying-glass text-sm "></i>
                    <input
                      type="text"
                      ref={araRef}
                      id="urun_ara"
                      onKeyUp={(e) => {
                        if (
                          e.key == "Enter" &&
                          e.target.value &&
                          !isNaN(e.target.value)
                        ) {
                          const urunler = document.querySelectorAll(
                            ".urun-item[data-hide=false]"
                          );
                          if (urunler.length == 0) {
                            return;
                          }
                          urunler[0].click();
                          e.target.value = "";
                          document
                            .querySelectorAll(".u-list-name")
                            .forEach((el) => {
                              el.parentElement.parentElement.style.display = "";
                              el.parentElement.setAttribute(
                                "data-hide",
                                "false"
                              );
                            });
                        }
                      }}
                      onChange={(e) => {
                        // Declare variables
                        var input, filter, table, tr, tr2, td, i, txtValue;
                        input = e.target;
                        filter = input.value.toUpperCase();
                        table = document.querySelector(".u-list");
                        tr = table.querySelectorAll(".u-list-name");
                        tr2 = table.querySelectorAll(".kategori");

                        // Loop through all table rows, and hide those who don't match the search query
                        for (i = 0; i < tr.length; i++) {
                          td = tr[i];

                          if (td) {
                            txtValue = td.textContent || td.innerText;
                            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                              tr[i].parentElement.parentElement.style.display =
                                "";

                              tr[i].parentElement.setAttribute(
                                "data-hide",
                                "false"
                              );
                            } else {
                              tr[i].parentElement.parentElement.style.display =
                                "none";

                              tr[i].parentElement.setAttribute(
                                "data-hide",
                                "true"
                              );
                            }
                          }
                        }
                      }}
                      placeholder="Ürün ismi, Kategori, Barkod Ara"
                      className="pl-3 font-medium flex-fill bg-transparent text-sm outline-none"
                    />
                  </div>
                  <div className="row g-2 u-list">
                    {ogrenci.yemekler &&
                      ogrenci.yemekler
                        .filter((el) => el.kabul == 1)
                        .map((val) => {
                          return (
                            <div className="col-3" key={val.urun_id}>
                              <div
                                onClick={(e) => {
                                 
                                  setSepet([...sepet,{
                                   
                                    to_okul: localStorage.user_id,
                                    isim: val.urun_isim,
                                    adet: 1 * carpan,
                                    fiyat: val.urun_fiyat,
                                    kategori: val.kategori,
                                    urun_id: val.urun_id,
                               
                                }]);
                                }
                                }
                                data-hide="false"
                                className="urun-item text-center h-full user-select-none hover:bg-slate-200 cursor-pointer bg-slate-100 rounded flex-column py-4 flex items-center justify-center"
                              >
                                <div className="u-list-name font-medium text-lg lh-1">
                                  {val.urun_isim}
                                  <span className="d-none">
                                    {" "}
                                    {val.kategori} {val.barkod}
                                  </span>
                                </div>
                                <div className="font-bold fiyat-text text-green-500 ">
                                  {val.urun_fiyat} ₺
                                </div>
                              </div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>

            {ogrenci ? (
              <div className="col-5">
                <div>
                  <div className="mb-2 bg-white rounded kutu shadow p-2">
                    <div className="flex flex-wrap ">
                      <div className="carpan " onClick={(e) => setCarpan(e, 1)}>
                        {" "}
                        <i className="fa-solid fa-cancel"></i>{" "}
                      </div>
                      <div className="carpan" onClick={(e) => setCarpan(e, 1)}>
                        1
                      </div>
                      <div className="carpan" onClick={(e) => setCarpan(e, 2)}>
                        2
                      </div>
                      <div className="carpan" onClick={(e) => setCarpan(e, 3)}>
                        3
                      </div>
                      <div className="carpan" onClick={(e) => setCarpan(e, 4)}>
                        4
                      </div>
                      <div className="carpan" onClick={(e) => setCarpan(e, 5)}>
                        5
                      </div>
                      <div className="carpan" onClick={(e) => setCarpan(e, 6)}>
                        6
                      </div>
                      <div className="carpan" onClick={(e) => setCarpan(e, 7)}>
                        7
                      </div>
                      <div className="carpan" onClick={(e) => setCarpan(e, 8)}>
                        8
                      </div>
                      <div className="carpan" onClick={(e) => setCarpan(e, 9)}>
                        9
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded kutu shadow pt-3 pb-3">
                    <div className="mxh">
                      {sepet.map((val, i) => {
                        return (
                          <div key={i} className={`sepet-item in  row   g-2 p-2`}>
                            <div className="col">
                              <div
                                className="font-medium cursor-pointer"
                                title="Seçim yap"
                              >
                                {val.isim}
                              </div>
                            </div>
                            <div className="col">
                              <div className="flex w-full justify-center">
                                <button
                                  onClick={(e) => {
                                    var a = [...sepet];
                                    a[i].adet = Number(a[i].adet) - 1
                                    console.log(a)
                                    if(a[i].adet <= 0){
                                      
                                       
                                        setSepet( sepet.filter((n,inx)=> inx != i) ) 
                                    }else{
                                     
                                      setSepet(a) 
                                    }
                                  
                               //     e.target.setAttribute("disabled", "true");
                                  }}
                                  className="rounded-l btn-disabled text-2xl lh-1 py-1 cursor-pointer hover:bg-gray-300 px-3 bg-slate-200 font-bold"
                                >
                                  -
                                </button>
                                <div className="px-2 py-1 bg-slate-100 qty font-medium">
                                  {val.adet}
                                </div>
                                <button
                                  onClick={(e) => {
                                    const a = [...sepet];
                                    a[i].adet = Number(a[i].adet) + 1
                                    setSepet(a) 
                                //    e.target.setAttribute("disabled", "true");
                                   
                                  }}
                                  className="rounded-r btn-disabled text-2xl lh-1 py-1 px-3 bg-slate-200 font-bold cursor-pointer hover:bg-gray-300"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div className="col cursor-pointer ">
                              <div
                                onClick={(e) => {
                                  const _e = e;
                                  _e.target.parentElement.parentElement.style.opacity =
                                    "0.6";
                                  axios
                                    .get(`/urun/${val.urun_id}`)
                                    .then((res) => {
                                      console.log(res.data);
                                      setFiyatlar(res.data);
                                      document
                                        .querySelectorAll(".fiyat-sec")
                                        .forEach((el) => {
                                          el.classList.add("d-none");
                                        });
                                      console.log(_e);
                                      _e.target.parentElement.parentElement.style.opacity =
                                        "1";
                                      _e.target.parentElement.parentElement
                                        .querySelector(".fiyat-sec")
                                        .classList.remove("d-none");
                                    });
                                }}
                                className="text-green-500 font-bold text-end fiyat-text fiyat"
                              >
                                {val.fiyat} ₺
                              </div>
                            </div>
                            {
                              fiyatlar ? 
                           
                            <div className="col-12  d-none in fiyat-sec">
                              <div>
                                <div className="text-center font-medium text-primary">
                                  Fiyat Seç
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <div>
                                      <div className="font-medium text-sm text-center">
                                        Fiyat 1
                                      </div>
                                      <div
                                        onClick={(e) => {
                                         
                                          const s = [...sepet];
                                          s[i].fiyat = fiyatlar[0].urun_fiyat
                                          setSepet(s);
                                         
                                        }}
                                        className={`${fiyatlar[0].urun_fiyat == sepet[i].fiyat ? "bg-green-200 text-green-600 border-green-600" : "text-green-500 hover:bg-slate-100 active:bg-slate-200"}  lh-1 text-sm fiyat-text text-center border-2 font-bold cursor-pointer rounded p-2`}                                      >
                                        {fiyatlar && fiyatlar[0].urun_fiyat} ₺
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col">
                                    <div>
                                      <div className="font-medium text-sm text-center">
                                        Fiyat 2
                                      </div>
                                      <div
                                      
                                          onClick={(e) => {
                                         
                                            const s = [...sepet];
                                            s[i].fiyat = fiyatlar[0].fiyat2
                                            setSepet(s);
                                           
                                          }}
                                      
                                          className={`${fiyatlar[0].fiyat2 == sepet[i].fiyat ? "bg-green-200 text-green-600 border-green-600" : "text-green-500 hover:bg-slate-100 active:bg-slate-200"}  lh-1 text-sm fiyat-text text-center border-2 font-bold cursor-pointer rounded p-2`}                                      >
                                        {fiyatlar && fiyatlar[0].fiyat2} ₺
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col">
                                    <div>
                                      <div className="font-medium text-sm text-center">
                                        Fiyat 3
                                      </div>
                                      <div
                                             onClick={(e) => {
                                         
                                              const s = [...sepet];
                                              s[i].fiyat = fiyatlar[0].fiyat3
                                              setSepet(s);
                                             
                                            }}
                                        
                                        className={`${fiyatlar[0].fiyat3 == sepet[i].fiyat ? "bg-green-200 text-green-600 border-green-600" : "text-green-500 hover:bg-slate-100 active:bg-slate-200"}  lh-1 text-sm fiyat-text text-center border-2 font-bold cursor-pointer rounded p-2`}
                                      >
                                        {fiyatlar && fiyatlar[0].fiyat3} ₺
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> : ""  }
                          </div>
                        ); 
                      })}
                    </div>
                    <div className="mxh p-2 font-medium text-lg text-center">
                      {sepet.length == 0 ? <>Sepet boş</> : ""}
                    </div>

                    {sepet.length != 0 && ogrenci != null ? (
                      <div className="px-2 mt-2">
                        {ogrenci[0].iskonto != 0 ? (
                          <div
                            className="mt-2 text-end
                           font-medium"
                          >
                            %{ogrenci[0].iskonto}{" "}
                            {ogrenci[0].tur == "plus" ? "Zam" : "İskonto"}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="flex justify-between items-center ">
                          <div className="font-bold text-primary">Toplam</div>
                          <div className="font-bold fiyat-text text-red-500 text-lg">
                            {iskonto(
                              sepet.reduce((a, b) => +a + +b.fiyat * b.adet, 0),
                              ogrenci[0].iskonto,
                              ogrenci[0].tur
                            )}{" "}
                            ₺
                          </div>
                        </div>

                        <div className="row g-2 mt-2 ">
                          <div className="col-6">
                            <button
                              onClick={(e) => setSepet([])}
                              className="border-red-500 text-red-500 hover:bg-red-500/10 w-full  cursor-pointer p-2  out-btn rounded"
                            >
                              Sepeti Boşalt
                            </button>
                          </div>
                          <div className="col-6">
                            <button
                              disabled={!buyable}
                              onClick={(e) => satis(e)}
                              className="btn-disabled border-green-600 text-green-600 hover:bg-green-500/10  cursor-pointer p-2 w-full out-btn rounded"
                            >
                              {buyable ? (
                                "Bakiye"
                              ) : (
                                <span className="text-red-500">LIMIT</span>
                              )}{" "}
                            </button>
                          </div>

                          <div className="col-6">
                            <button
                              disabled={!buyable}
                              onClick={(e) => manuelSatis(e, "Nakit")}
                              className="btn-disabled border-slate-600 text-slate-600 hover:bg-slate-500/10  cursor-pointer p-2 w-full out-btn rounded"
                            >
                              {buyable ? (
                                "Nakit"
                              ) : (
                                <span className="text-red-500">LIMIT</span>
                              )}
                            </button>
                          </div>

                          <div className="col-6">
                            <button
                              disabled={!buyable}
                              onClick={(e) => manuelSatis(e, "K. Kartı")}
                              className="btn-disabled border-slate-600 text-slate-600 hover:bg-slate-500/10  cursor-pointer p-2 w-full out-btn rounded"
                            >
                              {buyable ? (
                                "K.Kart"
                              ) : (
                                <span className="text-red-500">LIMIT</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Index;
