import React from 'react'

export default function KVK() {
  return (
    <div className='screen p-0'>
        <div className="page-min ">
            <div className="bg-white p-2 rounded">
                <div className="font-medium text-2xl text-center">KVKK Sözleşmesi</div>
                <div className='text-slate-600 font-medium mt-2 px-2'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi deleniti vitae possimus consequatur libero suscipit, mollitia laudantium iusto sunt ratione repellendus labore facere? Illum maiores quasi tempora officiis, facilis iure. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit architecto suscipit eum ullam neque incidunt. Quis tempore reiciendis perferendis debitis? Ducimus eligendi cupiditate dolor unde numquam provident accusamus magnam saepe?<br/><br/>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ducimus omnis tempora officia, natus mollitia ab iusto itaque sapiente quam amet impedit possimus facere. Saepe facere asperiores voluptatibus accusantium illum. Natus.
                </div>
            </div>
        </div>
    </div>
  )
}
