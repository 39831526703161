import React, { useState } from "react";
import { Link } from "react-router-dom";

function MobileHeader() {
  const [acik, setAcik] = useState(false);
  return (
    <div  onClick={(e) => {
      setAcik(!acik)
    }} className="flex lg:hidden mb-2 bg-white items-center  p-2 rounded ">
      
      <div className="font-medium ms-3">Kantin Yazılımı</div>

      <i className="ms-auto fa-solid fa-bars"></i>

{acik ? 
      <div className="mobile-side shadow h-full max-h-screen  position-fixed  top-0 left-0">
        <div className="flex flex-col items-center w-64 h-full overflow-hidden text-gray-700 bg-white rounded-r">
         


        <button
            onClick={(e) => {
              e.preventDefault()
              setAcik(false)
            }}
            to="/cikis"
            className="d-block mx-2 w-[90%] mb-2 mt-2 text-center btn-disabled border-red-600 text-red-600 hover:text-red-600 hover:bg-red-500/10  cursor-pointer p-2 w-full out-btn rounded"
          >
            <i className="fa-solid fa-chevron-left"></i> Menüyü Kapat
          </button>
          <div className="os w-full px-2">
            <div className="flex flex-col items-center w-full  border-gray-300">
              <Link
                className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-gray-300"
                to="/"
              >
                <i
                  data-tip="Ürünler"
                  className="fa-regular  fa-address-card text-gray-500 h-text-primary text-2xl cursor-pointer "
                ></i>

                <span className="ml-2 text-sm font-medium">Satış </span>
              </Link>
              <Link
                className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-gray-300"
                to="/urunler"
              >
                <i
                  data-tip="Ürünler"
                  className="fa-solid fa-utensils  text-gray-500 h-text-primary text-2xl cursor-pointer "
                ></i>

                <span className="ml-2 text-sm font-medium">Ürünler</span>
              </Link>
              <Link
                className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-gray-300"
                to="/raporlar"
              >
                <i className="fa-solid fa-receipt  text-gray-500 h-text-primary text-2xl cursor-pointer "></i>

                <span className="ml-2 text-sm font-medium">Rapor</span>
              </Link>
              <Link
                className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-gray-300"
                to="/stok?stok"
              >
                <i
                  data-tip="Stok & Satılan"
                  className="fa-solid fa-box  text-gray-500 h-text-primary text-2xl cursor-pointer "
                ></i>

                <span className="ml-2 text-sm font-medium">Stok</span>
              </Link>

              <Link
                className="flex items-center w-full h-12 px-3  rounded hover:bg-gray-300"
                to="/tedarikciler"
              >
                <i className="fa-solid fa-truck-field text-gray-500 h-text-primary text-2xl cursor-pointer "></i>

                <span className="ml-2 text-sm font-medium">Tedarikçiler</span>
              </Link>
            </div>
            <div className="flex flex-col items-center w-full mt-2">
              <Link
                className="flex items-center w-full h-12 px-3 hover:text-zinc-900  rounded hover:bg-gray-300"
                to="/ogrenciler"
              >
                <i
                  data-tip="Öğrenciler & Gruplar"
                  className="fa-solid fa-user text-gray-500    h-text-primary text-2xl cursor-pointer "
                ></i>

                <span className="ml-2 text-sm font-medium">Öğrenciler</span>
              </Link>
            </div>

            <div className="flex flex-col items-center w-full mt-2">
              <Link
                className="flex items-center w-full h-12 px-3 hover:text-zinc-900  rounded hover:bg-gray-300"
                to="/hareketler"
              >
                <i className="fa-solid fa-clock-rotate-left text-gray-500    h-text-primary text-2xl cursor-pointer "></i>

                <span className="ml-2 text-sm font-medium">Son Hareketler</span>
              </Link>
            </div>

            {localStorage.admin == "true" ? (
              <div className="flex flex-col items-center w-full mt-2">
                <Link
                  className="flex items-center w-full h-12 px-3 hover:text-zinc-900  rounded hover:bg-gray-300"
                  to="/kullanicilar"
                >
                  <i
                    data-tip="Öğrenciler & Gruplar"
                    className="fa-solid fa-location-dot text-gray-500    h-text-primary text-2xl cursor-pointer "
                  ></i>

                  <span className="ml-2 text-sm font-medium">
                   Yetkililer & Bölgeler
                  </span>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>


         
          <Link
            onClick={(e) => {
              if (!window.confirm("Çıkış Yapmak istediğinize emin misiniz")) {
                e.preventDefault();
              }
            }}
            to="/cikis"
            className="d-block mx-2 w-[90%] mb-2 mt-auto text-center btn-disabled border-red-600 text-red-600 hover:text-red-600 hover:bg-red-500/10  cursor-pointer p-2 w-full out-btn rounded"
          >
            Çıkış Yap
          </Link>
        </div>
      </div> : "" }
    </div>
  );
}

export default MobileHeader;
